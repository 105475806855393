import axios from "axios";

import {
  GET_NOTES,
  GET_NOTES_SET_LOADING,
  SAVE_NOTES,
  GET_FILES,
  GET_FILES_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { authTokenConfig, switchUserTokenConfig } from "../utilActions";

// get notes
export const getNotes = (procedureID, userID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_NOTES_SET_LOADING,
    });

    const response = await axios.get(
      `/fk/api/${procedureID}/${userID}/get_notes`,
      authTokenConfig(getState)
    );

    dispatch({
      type: GET_NOTES,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

// add notes
export const addNotes =
  (procedureID, userID, submitedFormData) => async (dispatch, getState) => {
    dispatch({ type: GET_NOTES_SET_LOADING });
    try {
      const response = await axios.post(
        `/fk/api/${procedureID}/${userID}/get_notes`,
        submitedFormData,
        authTokenConfig(getState)
      );

      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });

      // return data
      dispatch({
        type: SAVE_NOTES,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log(err.response);
      }
    }
  };

// add notes
export const delNotes =
  (procedureID, userID, delNoteID) => async (dispatch, getState) => {
    dispatch({ type: GET_NOTES_SET_LOADING });

    try {
      const response = await axios.delete(
        `/fk/api/${procedureID}/${userID}/del_note/${delNoteID}`,
        authTokenConfig(getState)
      );

      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: `Notatka o ID: ${delNoteID} została usunięta`,
          status: response.status,
        },
      });

      dispatch(getNotes(procedureID, userID));
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };
// ##############################################
//                  FILES
// ##############################################

export const getFiles = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FILES_SET_LOADING,
    });

    const response = await axios.get(
      `/fk/api/${procedureID}/get_files_history`,
      switchUserTokenConfig(getState)
    );

    dispatch({
      type: GET_FILES,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

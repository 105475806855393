import {
  SWITCH_USER_SUCCESS,
  SWITCH_USER_FAIL,
  SWITCH_USER_LOADED,
  SWITCH_USER_LOGOUT_SUCCESS,
} from "../actions/types";

const initialState = {
  switch_user_token: localStorage.getItem("switch_user_token"),
  isSwitchedUser: false,
  loading: null,
  switchedUser: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_USER_SUCCESS:
      localStorage.setItem(
        "switch_user_token",
        action.payload.switch_user_token
      );
      return {
        ...state,
        switch_user_token: action.payload.switch_user_token,
        isSwitchedUser: true,
        loading: false,
      };
    case SWITCH_USER_LOADED:
      return {
        ...state,
        isSwitchedUser: true,
        loading: false,
        switchedUser: action.payload,
      };
    case SWITCH_USER_FAIL:
    case SWITCH_USER_LOGOUT_SUCCESS:
      localStorage.removeItem("switch_user_token");
      return {
        ...state,
        switch_user_token: null,
        isSwitchedUser: null,
        loading: false,
        switchedUser: null,
      };
    default:
      return state;
  }
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import CostCalculationForm from "./CostCalculationForm";

const CostCalculationMainPage = () => {
  return (
    <Switch>
      <Route
        exact
        path="/wkk/:procedureID/cost_calculation_form"
        component={CostCalculationForm}
      />
    </Switch>
  );
};

export default CostCalculationMainPage;

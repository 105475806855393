import { CONFIRM_SD_DATA, CONFIRM_SD_DATA_SET_LOADING } from "actions/types.js";

const initialState = {
  confirmSpecyficDataStatus: null,
  confirmSpecyficDataloading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_SD_DATA:
      return {
        ...state,
        confirmSpecyficDataStatus: action.payload,
        confirmSpecyficDataloading: false,
      };
    case CONFIRM_SD_DATA_SET_LOADING:
      return {
        ...state,
        specyficDataloading: true,
      };
    default:
      return state;
  }
};

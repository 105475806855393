import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import "bootstrap/dist/css/bootstrap.min.css";

import store from "./store.js";
import { loadUser } from "./actions/auth";
import { loadSwichedUser } from "./actions/switchUserActions";

import { BaseLayout } from "./containers/BaseLayout.js";
import { OpkMainPage } from "./containers/Opk/OpkMainPage.js";

import { RegistrationPage } from "./containers/Registration/RegistrationPage.js";
import Alerts from "./components/Alert/Alerts.js";
import PrivateRoute from "./components/common/PrivateRoute.js";
import LandingPage from "./containers/Main/LandingPage.js";
import SpecificDataPage from "./containers/SpecificData/SpecificDataMainPage.js";
import DashboardMainPage from "./containers/Dashboard/DashboardMainPage.js";
import InstructionsMainPage from "./containers/Instructions/InstructionsMainPage.js";
import AnalyticsMainPage from "containers/Analytics/AnalyticsMainPage.js";
import CostCalculationMainPage from "containers/CostCalculation/CostCalculationMainPage.js";

// Alert Options
const alertOptions = {
  type: "info",
  timeout: 3000,
  position: "top right",
  transition: "fade",
  offset: "90px",
};

const localStorageAuthToken = localStorage.getItem("auth_token");
const localStorageSwitchUserToken = localStorage.getItem("switch_user_token");

const App = () => {
  useEffect(() => {
    if (localStorageAuthToken) {
      store.dispatch(loadUser());
      if (localStorageSwitchUserToken) {
        store.dispatch(loadSwichedUser());
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router>
          <BaseLayout>
            <Alerts />
            <Switch>
              <Route exect path="/registration*" component={RegistrationPage} />
              <Route
                exect
                path="/instrukcja*"
                component={InstructionsMainPage}
              />
              <PrivateRoute
                exect
                path="/analytics*"
                component={AnalyticsMainPage}
              />
              <PrivateRoute
                exect
                path="/wkk*"
                component={CostCalculationMainPage}
              />
              <PrivateRoute
                exect
                path="/panel*"
                component={DashboardMainPage}
              />
              <PrivateRoute exect path="/fk*" component={OpkMainPage} />
              <PrivateRoute
                exect
                path="/daneszcz*"
                component={SpecificDataPage}
              />

              <PrivateRoute exect path="/" component={LandingPage} />
            </Switch>
          </BaseLayout>
        </Router>
      </AlertProvider>
    </Provider>
  );
};

export default App;

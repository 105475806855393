import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";
import { Modal } from "react-bootstrap";

import PropTypes from "prop-types";
import { getProceedings } from "../../actions/landingPageActions";
import { Loading } from "../../components/Loading/loading";
import logo from "../../assets/img/aotm_50px.png";

const LandingPageContent = ({ proceedings, isSwitchedUser, loading }) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const render_veryfication = (verification) => {
    const options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (verification.czy_otwarte === true) {
      return (
        <>
          <h6 className={"text-aotmit mb-0"}>Przesłane</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small mb-0"}>
            {verification.zatwierdzil}
          </p>
        </>
      );
    } else if (verification.czy_otwarte === false) {
      return (
        <>
          <h6 className={"text-success mb-0"}>zatwierdzone</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small mb-0"}>
            {verification.zatwierdzil}
          </p>
        </>
      );
    } else if (verification.czy_otwarte === null) {
      return (
        <>
          <h6 className={"text-warning mb-0"}>Odblokowane</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small mb-0"}>
            {verification.zatwierdzil}
          </p>
        </>
      );
    }
  };

  if (loading === true) {
    return (
      <>
        <Loading variant={"info"}>
          <span className="m-2">
            Informacja o postępowaniach jest pobierana z serwera...
          </span>
        </Loading>
      </>
    );
  } else if (proceedings.length === 0) {
    return (
      <>`Nie ma w tej chwili postępowań, w których jesteś uczestnikiem.`</>
    );
  } else {
    return (
      <>
        <ListGroup variant="flush">
          <ListGroup.Item className="list-item" variant="light" disabled>
            <span className="participation-item">Postępowanie nr:</span>
            <span className="datatype-item">Typ danych:</span>
            <span className="year-item">Rok:</span>
            {isSwitchedUser && (
              <span className="verification-item">Status weryfikacji:</span>
            )}
          </ListGroup.Item>

          {proceedings.map((procceding) => {
            return (
              <ListGroup.Item
                className="list-item font-small py-2"
                key={procceding.postepowanie.id}
                action
                variant="light"
                onClick={() => {
                  if (isSwitchedUser) {
                    history.push(`${procceding.postepowanie.typ_danych}/${procceding.postepowanie.id}/`)
                  } else {
                    procceding.verifications?.czy_otwarte === false ? 
                    setOpenModal(true) : 
                    history.push(`${procceding.postepowanie.typ_danych}/${procceding.postepowanie.id}/`)
                  }
                }}
              >
                <span className="participation-item">
                  {procceding.postepowanie.postepowanie_nr}
                </span>
                <span className="datatype-item">
                  {procceding.postepowanie.typ_danych}
                </span>
                <span className="year-item">
                  {procceding.postepowanie.postepowanie_rok}
                </span>
                {isSwitchedUser && (
                  <span className="verification-item">
                    {procceding.verifications &&
                      render_veryfication(procceding.verifications)}
                  </span>
                )}
              </ListGroup.Item>
            );
          })}
        </ListGroup>

        <Modal
          show={openModal}
          onHide={() => {setOpenModal(false)}}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <Modal.Header closeButton>
            <div className="d-flex align-items-center">
              <img
                src={logo}
                width="50"
                height="50"
                className="d-inline-block align-top"
                alt="AOTMiT logo"
              />
              <h4 className="mt-1 ml-3">Szanowni Państwo,</h4>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center my-3">
              <p>Dane do tego postępowania zostały już zatwierdzone.</p>
              <p>Nie mają Państwo uprawnień do przeglądania tej strony, możliwość edycji została wyłączona.</p>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }
};

const LandingPage = ({
  proceedings,
  isSwitchedUser,
  loading,
  getProceedings,
}) => {
  useEffect(() => {
    getProceedings();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="head-container">
        <h3>Witamy w aplikacji Dane-kosztowe</h3>
        <p>
          Poniżej znajduje się lista postępowań do których otrzymali Państwo
          dostęp.
        </p>
      </div>

      <main className="main-container">
        <LandingPageContent
          proceedings={proceedings}
          isSwitchedUser={isSwitchedUser}
          loading={loading}
        />
      </main>
    </>
  );
};

LandingPage.propTypes = {
  proceedings: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getProceedings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  proceedings: state.landingPageReducer.proceedings,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  loading: state.landingPageReducer.loading,
});

export default connect(mapStateToProps, { getProceedings })(LandingPage);

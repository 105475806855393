import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { getOpkFormData, saveOpkFormData } from '../../../actions/OpkFormsActions/opkFormActions'


import OpkColumnForm from '../OpkForms/OpkColumnForm';
import { Loading } from '../../../components/Loading/loading';

import { OpkFormFields } from '../OpkForms/FormSettings'


const OpkForm = ({ opk_object, loading, getOpkFormData, saveOpkFormData, match }) => {
  const { procedureID, opkID } = match.params;

  useEffect(() => {
    getOpkFormData(procedureID, opkID)
    // eslint-disable-next-line
  }, [procedureID, opkID])

  const saveData = async (submitedFormData) => {
    saveOpkFormData(procedureID, opkID, submitedFormData)
  };

  return (
    <>
      {loading ?
        (<Loading variant={"danger"} >
          <span className="m-2">
            Dane są zapisywane na serwerze...
                </span>
        </Loading>) :
        (<OpkColumnForm
          opk_object={opk_object}
          formFields={OpkFormFields}
          dataSubmit={saveData} />
        )}
    </>
  )
}

OpkForm.propTypes = {
  opk_object: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getOpkFormData: PropTypes.func.isRequired,
  saveOpkFormData: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  opk_object: state.opkFormReducer.opkData.opk_object,
  loading: state.opkFormReducer.loading
})

export default connect(mapStateToProps, { getOpkFormData, saveOpkFormData })(OpkForm)
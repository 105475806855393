import axios from "axios";

import {
  GET_ANALYTICS_DATA,
  SAVE_ANALYTICS_DATA,
  ANALYTICS_DATA_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { configTokenSwitcher } from "../utilActions";

//  GET analytics data
export const getAnalyticsData = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: ANALYTICS_DATA_SET_LOADING });

    const response = await axios.get(
      `/analytics/api/${procedureID}/analytic_api_view/`,
      configTokenSwitcher(getState)
    );
    dispatch({
      type: GET_ANALYTICS_DATA,
      payload: response.data,
    });
  } catch (err) {
    if (err.response) {
      console.log("Analytics", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ err });
    }
  }
};
// ADD analytics OPKS
export const saveAnalyticsFormData =
  (procedureID, submitedFormData) => async (dispatch, getState) => {
    dispatch({ type: ANALYTICS_DATA_SET_LOADING });
    try {
      const response = await axios.post(
        `/analytics/api/${procedureID}/analytic_api_view/`,
        submitedFormData,
        configTokenSwitcher(getState)
      );
      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });

      // return data
      dispatch({
        type: SAVE_ANALYTICS_DATA,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        console.log("Analytics", err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ err });
      }
    }
  };

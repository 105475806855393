import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getTab2FormData,
  saveTab2FormData,
} from "../../../actions/OpkFormsActions/tab2FormActions";

import { Loading } from "../../../components/Loading/loading";
import { Tab2FormFields } from "../OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";

const Tab2Form = ({
  tab2_objects,
  loading,
  getTab2FormData,
  saveTab2FormData,
  match,
}) => {
  const { procedureID, opkID } = match.params;

  useEffect(() => {
    getTab2FormData(procedureID, opkID);
    // eslint-disable-next-line
  }, [procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    saveTab2FormData(procedureID, opkID, submitedFormData);
  };

  return (
    <>
      {loading ? (
        <Loading variant={"danger"}>
          <span className="m-2">Dane są zapisywane na serwerze...</span>
        </Loading>
      ) : (
        <GeneratedForm
          opkData={tab2_objects}
          formFields={Tab2FormFields}
          dataSubmit={saveData}
        />
      )}
    </>
  );
};

Tab2Form.propTypes = {
  tab2_objects: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getTab2FormData: PropTypes.func.isRequired,
  saveTab2FormData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tab2_objects: state.tab2FormReducer.Tab2Data.tab2_objects,
  loading: state.tab2FormReducer.loading,
});

export default connect(mapStateToProps, { getTab2FormData, saveTab2FormData })(
  Tab2Form
);

import axios from 'axios';
import { saveAs } from 'file-saver';

import { GET_SUCCESS, GET_ERRORS } from '../types';

import { configFileTokenSwitcher } from '../utilActions';

// get personalized excel
export const getexcelstatistics = (procedureID) => async (
  dispatch,
  getState
) => {
  try {
    let switchedUser_usename = '';
    if (getState().switchUserReducer.switchedUser) {
      switchedUser_usename = getState().switchUserReducer.switchedUser.username;
    }

    const response = await axios.get(
      `/fk/api/${procedureID}/get_excel_statistics`,
      configFileTokenSwitcher(getState)
    );
    let blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `${switchedUser_usename}_FK.xlsx`);

    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: 'Plik FK został poprawnie pobrany.',
        status: response.status,
      },
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getAnalyticsFormData,
  saveAnalyticsFormData,
} from "../../../actions/OpkFormsActions/AnalyticsFormActions";

import { Loading } from "../../../components/Loading/loading";
import { AnalyticsFormFields } from "../OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";

const AnalyticsForm = ({
  analytics_objects,
  loading,
  getAnalyticsFormData,
  saveAnalyticsFormData,
  match,
}) => {
  const { procedureID, opkID } = match.params;

  useEffect(() => {
    getAnalyticsFormData(procedureID, opkID);
    // eslint-disable-next-line
  }, [procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    saveAnalyticsFormData(procedureID, opkID, submitedFormData);
  };

  return (
    <>
      {loading ? (
        <Loading variant={"danger"}>
          <span className="m-2">Dane są zapisywane na serwerze...</span>
        </Loading>
      ) : (
        <GeneratedForm
          opkData={analytics_objects}
          formFields={AnalyticsFormFields}
          dataSubmit={saveData}
        />
      )}
    </>
  );
};

AnalyticsForm.propTypes = {
  analytics_objects: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getAnalyticsFormData: PropTypes.func.isRequired,
  saveAnalyticsFormData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  analytics_objects: state.analyticsFormReducer.AnalyticsData.analytics_objects,
  loading: state.analyticsFormReducer.loading,
});

export default connect(mapStateToProps, {
  getAnalyticsFormData,
  saveAnalyticsFormData,
})(AnalyticsForm);

import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// React Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import {
  getNotes,
  addNotes,
  delNotes,
  getFiles,
} from "../../../actions/StaffUserViewsActions/NotesAndFilesActions";

const NotesAndFilesComponent = ({
  procedureID,
  isSwitchedUser,
  switchedUser,
  getNotes,
  notes,
  notes_loading,
  addNotes,
  delNotes,
  getFiles,
  files,
  files_loading,
}) => {
  const [formData, setFormData] = useState("");

  useEffect(() => {
    if (isSwitchedUser) {
      getNotes(procedureID, switchedUser.id.toString());
      getFiles(procedureID);
    }
    // eslint-disable-next-line
  }, []);

  const handleFieldChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const dataToSubmit = {
      tresc: formData,
    };
    setFormData("");

    addNotes(procedureID, switchedUser.id.toString(), dataToSubmit);
  };

  const handleDelete = (event) => {
    const delNoteID = event.target.id.split("-")[1];
    delNotes(procedureID, switchedUser.id.toString(), parseInt(delNoteID));
  };

  return (
    <>
      <div className={"notes-box"}>
        <Row>
          <Col>
            <Accordion className={"notes-box-content"}>
              <Accordion.Toggle as={"div"} eventKey="0">
                Notatki
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    {notes_loading
                      ? "loading..."
                      : notes.map((note, idx) => {
                          return (
                            <Fragment key={idx}>
                              <div className="box-messages">
                                <div className="message-user">{`${note.from_user}`}</div>
                                <div className="message-content">
                                  {`${note.tresc} `}
                                </div>
                                <Button
                                  variant="outline-danger"
                                  size="sm"
                                  className="message-delete"
                                  id={`del-${note.id}`}
                                  onClick={(e) => handleDelete(e)}
                                >
                                  &times;
                                </Button>
                              </div>
                            </Fragment>
                          );
                        })}
                  </>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className={"my-2"}
                    value={formData}
                    onChange={(e) => handleFieldChange(e)}
                  />
                  <Button
                    type="submit"
                    className={"btn-bg-white"}
                    variant="outline-success"
                    size="sm"
                  >
                    Zapisz notatkę
                  </Button>
                </Form>
              </Accordion.Collapse>
            </Accordion>
          </Col>
          <Col>
            <Accordion className={"notes-box-content"}>
              <Accordion.Toggle as={"div"} eventKey="1">
                Pliki
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                {/* <> */}
                <ListGroup variant="flush" className={"swd-info"}>
                  {files_loading
                    ? "Loading Files..."
                    : files.map((file, file_id) => {
                        const fileName = file.plik.split("/").pop();
                        const fileDateAll = file.data.split("T");
                        const fileDate = fileDateAll[0];
                        const fileTime = fileDateAll[1].split(".")[0];
                        return (
                          <a key={file_id} href={file.plik}>
                            <ListGroup.Item>
                              {`${fileName} dodany ${fileDate} o ${fileTime}`}
                            </ListGroup.Item>
                          </a>
                        );
                      })}
                </ListGroup>
              </Accordion.Collapse>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  );
};

NotesAndFilesComponent.propTypes = {
  getNotes: PropTypes.func.isRequired,
  addNotes: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  switchedUser: state.switchUserReducer.switchedUser,
  notes: state.notesReducer.notes,
  notes_loading: state.notesReducer.loading,
  files: state.filesReducer.files,
  files_loading: state.filesReducer.loading,
});

export default connect(mapStateToProps, {
  getNotes,
  addNotes,
  delNotes,
  getFiles,
})(NotesAndFilesComponent);

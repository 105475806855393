import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import { Button } from "reactstrap";

// actions
import { opkCheckData } from "../../../actions/OpkFormsActions/opkCheckDataActions";
import { confirmDataSwd } from "../../../actions/OpkFormsActions/confirmDataActions";
import { getpersonalizedexcel } from "../../../actions/OpkFormsActions/getPersonalizedExcelActions";
import { SmalModal } from "../../../components/Modal/SmalModal";

const SideBarButtons = ({
  procedureID,
  opkCheckData,
  confirmOpkDataStatus,
  confirmDataSwd,
  getpersonalizedexcel,
  switchedUserInfo,
}) => {
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (confirmOpkDataStatus === 200) {
      const redirect = setTimeout(() => {
        history.push(`/fk/${procedureID}`);
      }, 3000);

      return () => clearTimeout(redirect);
    }
    // eslint-disable-next-line
  }, [confirmOpkDataStatus]);

  const handleModalShow = () => setShowModal((prevState) => !prevState);

  const handleAccept = () => {
    handleModalShow();
    confirmDataSwd(procedureID);
  };

  return (
    <>
      <div className={"dropdownMenu"}>
        <Link to={`/fk/user_profile`}>
          <Button
            size="sm"
            outline
            block
            type="button"
            className={"btn-aotmit"}
          >
            Dane ogólne
          </Button>
        </Link>

        <Button
          size="sm"
          outline
          block
          type="button"
          className={"btn-aotmit"}
          onClick={() => opkCheckData(procedureID)}
        >
          Sprawdź dane
        </Button>

        <Link to={`/fk/${procedureID}/upload_xlsx`}>
          <Button
            size="sm"
            outline
            block
            type="button"
            className={"btn-aotmit"}
          >
            Import Excel
          </Button>
        </Link>

        <Button
          size="sm"
          outline
          block
          type="button"
          className={"btn-aotmit"}
          onClick={() => getpersonalizedexcel(procedureID)}
        >
          Pobierz wzór FK
        </Button>

        <Button
          disabled={
            switchedUserInfo.verifications &&
            switchedUserInfo.verifications.czy_otwarte === true &&
            true
          }
          size="sm"
          outline
          color="success"
          block
          type="button"
          className={"mt-2"}
          onClick={() => handleModalShow()}
        >
          Prześlij do AOTMiT
        </Button>
      </div>
      <SmalModal
        show={showModal}
        handleClose={handleModalShow}
        modalBodyText={
          <p className={"text-center"}>
            Czy na pewno chcesz przesłać dane do AOTMiT?
          </p>
        }
        handleAccept={handleAccept}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  switchedUserInfo: state.switchedUserInfoReducer.switchedUserInfo,
  confirmOpkDataStatus: state.opkConfirmDataReducer.confirmOpkDataStatus,
});

export default connect(mapStateToProps, {
  opkCheckData,
  confirmDataSwd,
  getpersonalizedexcel,
})(SideBarButtons);

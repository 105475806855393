import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import logo from '../../assets/img/aotm.png';

const SmalModal = ({
  size = 'sm',
  show,
  handleClose,
  modalTitle,
  modalBodyText,
  handleAccept,
}) => {
  return (
    <>
      <Modal
        show={show}
        size={size}
        // animated={false}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <img src={logo} className='modalLogo' alt='AOTMiT logo' />
        </Modal.Header>
        <Modal.Body>{modalBodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleClose}>
            Anuluj
          </Button>
          <Button variant='success' onClick={handleAccept}>
            Zatwierdź
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

SmalModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export { SmalModal };

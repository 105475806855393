import axios from "axios";

import {
  SWITCH_USER_SUCCESS,
  SWITCH_USER_FAIL,
  SWITCH_USER_LOADED,
  SWITCH_USER_LOGOUT_SUCCESS,
  GET_ERRORS,
} from "../actions/types";

import { setLoading, switchUserTokenConfig } from "./utilActions";

// SwitchUser
export const switchUser = (formData) => async (dispatch) => {
  dispatch(setLoading());

  try {
    const response = await axios.post("/switch_user/", formData);
    dispatch({
      type: SWITCH_USER_SUCCESS,
      payload: response.data,
    });

    dispatch(loadSwichedUser());
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });

    dispatch({
      type: SWITCH_USER_FAIL,
    });
  }
};

// // LOAD SWICHED USER
export const loadSwichedUser = () => async (dispatch, getState) => {
  if (getState().switchUserReducer.switch_user_token) {
    dispatch(setLoading());

    try {
      const response = await axios.get(
        `/auth/users/me/`,
        switchUserTokenConfig(getState)
      );

      dispatch({
        type: SWITCH_USER_LOADED,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.statusText === "Unauthorized") {
        console.log("switchuserLoad: err ", err.response.data);

        if (err.response.data.detail === "Niepoprawny token.") {
          dispatch({
            type: SWITCH_USER_LOGOUT_SUCCESS,
          });
        }
        
      } else if (err.response) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        console.log(errors, err.response);
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ switchUserAuth: err });
      }
    }
  }
};

// LOGOUT USER
export const logoutSwichedUser = () => async (dispatch, getState) => {
  try {
    await axios.post(
      "/auth/token/logout/",
      null,
      switchUserTokenConfig(getState)
    );

    dispatch({
      type: SWITCH_USER_LOGOUT_SUCCESS,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

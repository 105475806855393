import React from "react";
import "./BaseLayout.scss";

import Header from "../components/Header/Header.js";
import { Footer } from "../components/Footer/footer.js";

const BaseLayout = (props) => {
  return (
    <div className="custom-layout">
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export { BaseLayout };

import axios from "axios";

import {
  LOGIN_SUCCESS,
  USER_LOADED,
  LOGIN_FAIL,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  SWITCH_USER_LOGOUT_SUCCESS,
  // GET_SUCCESS,
  GET_ERRORS,
} from "../actions/types";

import {
  setLoading,
  authTokenConfig,
  configTokenSwitcher,
} from "./utilActions";

//  const apiURL = process.env.REACT_APP_API_URL;

// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

// LoginUser
export const loginUser = (formData) => async (dispatch) => {
  dispatch(setLoading());

  const config = {
    method: "post",
    url: `/auth/token/login/`,
    data: formData,
  };

  try {
    const response = await axios(config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    dispatch(loadUser());
  } catch (err) {
    console.log({ auth_err: err, test: err.response.data });
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });

    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: SWITCH_USER_LOGOUT_SUCCESS,
    });
  }
};

// LOAD USER
export const loadUser = () => async (dispatch, getState) => {
  dispatch(setLoading());
  try {
    const response = await axios.get(
      `/auth/users/me/`,
      authTokenConfig(getState)
    );

    dispatch({
      type: USER_LOADED,
      payload: response.data,
    });
  } catch (err) {
    if (err.response) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });

      dispatch({
        type: AUTH_ERROR,
      });
    } else {
      console.log("authErr:", err);
    }
  }
};

// LOGOUT USER
export const logoutUser = () => async (dispatch, getState) => {
  try {
    await axios.post(
      "/auth/token/logout/",
      null,
      configTokenSwitcher(getState)
    );

    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

import axios from "axios";

import {
  GET_LIST_FOR_VERIFICATION,
  LIST_FOR_VERIFICATION_SET_LOADING,
  GET_ERRORS
} from '../types'

import { configTokenSwitcher } from '../utilActions'

//  GET OPK AKCEPTACJA OPKS
export const getListForVerification = () => async (dispatch, getState) => {
  try {

    dispatch({ type: LIST_FOR_VERIFICATION_SET_LOADING })

    const response = await axios.get(`/fk/api/lista_do_weryfikacji`, configTokenSwitcher(getState));
    dispatch({
      type: GET_LIST_FOR_VERIFICATION,
      payload: response.data
    })
  } catch (err) {
    console.log(err.response, err.response.data)

    const errors = {
      msg: err.response.data,
      status: err.response.status
    }
    dispatch({
      type: GET_ERRORS,
      payload: errors
    })
  }
}

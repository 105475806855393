// import React, { useEffect, useRef } from 'react'
// import moduleName from 'prop-types'
import { connect } from 'react-redux'
import { withAlert } from 'react-alert'

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

// const Alerts = ({ alert, error }) => {

//   const prevAlert = usePrevious(error)

//   useEffect(() => {
//     if (error !== prevAlert) {
//       alert.show('dziła')
//     }
//   }, [])
//   return (
//     null
//   )
// }

// const mapStateToProps = (state) => ({
//   error: state.errorsReducer,
// })

// export default connect(mapStateToProps)(withAlert()(Alerts))

import React, { Component } from 'react'

export class Alerts extends Component {

  componentDidUpdate(prevProps) {
    const { alertState, alert } = this.props
    if (alertState !== prevProps.error) {
      if (alertState.msg.detail) {
        alert.show(`${alertState.msg.detail}`, {
          type: `${alertState.alertType}`
        })
      } else if (alertState.msg.non_field_errors) {
        alert.show(`${alertState.msg.non_field_errors}`, {
          type: `${alertState.alertType}`
        })
      } else {
        alert.show(`${alertState.msg}`, {
          type: `${alertState.alertType}`
        })
      }
    }
  }
  render() {
    return (
      <>
      </>
    )
  }
}


const mapStateToProps = (state) => ({
  alertState: state.alertsReducer,
})

export default connect(mapStateToProps)(withAlert()(Alerts))


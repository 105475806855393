import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link } from "react-router-dom";

import { getFAQ } from "actions/PanelInstructionsActions/ProceedingFAQActions";
// reactstrap components
import {
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import SlagSelectedQuestions from "./SlagSelectedQuestions";

const AllQuestions = ({ loading, faq }) => {
  return (
    <>
      {!loading &&
        faq.map((faq_element, faq_idx) => {
          return (
            <Card key={faq_idx}>
              <CardHeader>{faq_element.pytanie}</CardHeader>
              <CardBody>{faq_element.odpowiedz}</CardBody>
            </Card>
          );
        })}
    </>
  );
};

const ProceedingFAQ = ({ faq, categories, loading, getFAQ }) => {
  useEffect(() => {
    getFAQ();
    // eslint-disable-next-line
  }, []);

  // console.log(faq);
  return (
    <>
      <div className="head-xl-container">
        {!loading && (
          <Card>
            <CardBody className={"m-1 p-2"}>
              <Row>
                {!loading &&
                  categories
                    .filter((el) => el.count > 0)
                    .map((category, category_idx) => {
                      return (
                        <Col sm={12} md={4} lg={2} key={category_idx}>
                          <Link
                            to={`/instrukcja/FAQ/${category.slug}`}
                            className={`nav-link p-0`}
                          >
                            <Button
                              block
                              className={"mt-1"}
                              outline
                              type="button"
                            >
                              <Row>
                                <Col sm={9}>
                                  <h6>{category.nazwa}</h6>
                                </Col>
                                <Col sm={3}>
                                  <Badge color="info" className={"p-1"}>
                                    {category.count}
                                  </Badge>{" "}
                                </Col>
                              </Row>
                            </Button>
                          </Link>
                        </Col>
                      );
                    })}
              </Row>
            </CardBody>
          </Card>
        )}
      </div>

      <main className="main-xl-container">
        <Switch>
          <Route
            path={`/instrukcja/FAQ/:slug`}
            render={(props) => (
              <SlagSelectedQuestions loading={loading} faq={faq} {...props} />
            )}
          />
          <Route
            exact
            path="/instrukcja/FAQ"
            render={(props) => (
              <AllQuestions loading={loading} faq={faq} {...props} />
            )}
          />
        </Switch>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  faq: state.faqReducer.faq,
  categories: state.faqReducer.categories,
  loading: state.faqReducer.loading,
});

export default connect(mapStateToProps, { getFAQ })(ProceedingFAQ);

import { GET_TAB1_FORM_DATA, SAVE_TAB1_FORM_DATA, TAB1_FORM_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  Tab1Data: [],
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB1_FORM_DATA:
      return {
        ...state,
        Tab1Data: action.payload,
        loading: false
      };
    case SAVE_TAB1_FORM_DATA:
      return {
        ...state,
        Tab1Data: action.payload,
        loading: false
      };
    case TAB1_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
import { GET_FAQ, GET_FAQ_SET_LOADING } from "actions/types.js";

const initialState = {
  faq: [],
  categories: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ:
      return {
        ...state,
        faq: action.payload.faq,
        categories: action.payload.categories,
        loading: false,
      };
    case GET_FAQ_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

import axios from "axios";

import {
  GET_OPKS_ACCEPTANCE,
  ADD_OPKS_ACCEPTANCE,
  CONFIRM_OPKS,
  OPKS_ACCEPTANCE_SET_LOADING,
  GET_SUCCESS,
  // GET_INFO, 
  GET_ERRORS,
} from '../types'

import { authTokenConfig } from '../utilActions'

//  GET OPK AKCEPTACJA OPKS
export const getOpks = (procedureID, userID) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING })

    const response = await axios.get(`/fk/api/${procedureID}/${userID}/akceptacja_opk`, authTokenConfig(getState));

    dispatch({
      type: GET_OPKS_ACCEPTANCE,
      payload: response.data
    })
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status
    }
    dispatch({
      type: GET_ERRORS,
      payload: errors
    })
  }
}
// ADD OPK AKCEPTACJA OPKS
export const addOpks = (procedureID, userID, submitedFormData) => async (dispatch, getState) => {

  dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING })
  try {
    const response = await axios.post(`/fk/api/${procedureID}/${userID}/akceptacja_opk`, submitedFormData, authTokenConfig(getState));
    // set alert success
    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: 'Dane zostały poprawnie zapisane',
        status: response.status,
      }
    })

    // return data
    dispatch({
      type: ADD_OPKS_ACCEPTANCE,
      payload: response.data
    })

  } catch (err) {
    console.log(err.response,
      err.response.data)
    dispatch({
      type: GET_ERRORS,
      payload: err.response
    })
  }
}

//  CONFIRM OPKS
export const confirmOpk = (procedureID, userID) => async (dispatch, getState) => {
  try {

    dispatch({ type: OPKS_ACCEPTANCE_SET_LOADING })
    const response = await axios.get(`/fk/api/${procedureID}/${userID}/confirm_opks`, authTokenConfig(getState));
    dispatch({
      type: CONFIRM_OPKS,
      payload: response.status
    })

    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: 'Numery kont OPK zostały zatwierdzone',
        status: response.status,
      }
    })
    // dispatch(push('/fk/lista_do_weryfikacji'))

  } catch (err) {
    console.log(err.response, err.response.data)

    const errors = {
      msg: err.response.data,
      status: err.response.status
    }
    dispatch({
      type: GET_ERRORS,
      payload: errors
    })
  }
}


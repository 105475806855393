import React from "react";

export const OpkNewListFormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "nr_konta",
        fieldLabel: "Numer konta OPK",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "nazwa_opk",
        fieldLabel: "Nazwa OPK",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "kod_resortowy",
        fieldLabel: "Kod resortowy",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 0,
        fieldId: "rok",
        fieldLabel: "Rok",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "opk_niemedyczny",
        fieldLabel: "Uproszczone dane FK",
        fieldType: "checkbox",
        dafault: false,
      },
      {
        fieldcolSize: 0,
        fieldId: "del",
        fieldLabel: "Usuń",
        fieldType: "checkbox",
        dafault: false,
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "bledy",
        fieldLabel: "Błędy",
        fieldType: "alert",
        dafault: "",
      },
    ],
  },
];

export const OpkFormFields = [
  {
    // fieldId: "id",
    fieldLabel: (
      <>
        CZĘŚĆ PODSTAWOWA I - PRZYCHODY (dla zdefiniowanych kategorii należy
        przyporządkować właściwe przychody):
      </>
    ),
    fieldType: "header",
  },
  {
    fieldId: "przychody",
    fieldLabel: <>1. PRZYCHODY RAZEM w okresie sprawozdawczym (suma pozycji 2, 3, 4 i 5)</>,
    fieldType: "title",
  },
  {
    fieldId: "przychody_nfz",
    fieldLabel: (
      <>
        2. Przychody z tytułu kontraktu z NFZ (w części wynikającej z kontraktu
        dotyczące danego okresu)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "przychody_nadw",
    fieldLabel: (
      <>
        3. Przychody z tytułu nadwykonań zrealizowanych w danym roku
        (niezależnie od tego czy zostały zapłacone)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "opk_num1",
    fieldLabel: (
      <>
        4. Przychody z tytułu leków refundowanych odrębnie (np. chemioterapia i
        programy lekowe)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "przychody_inne",
    fieldLabel: (
      <>
        5. Przychody z innych tytułów (np. dotacje, refundacja wzrostu
        wynagrodzeń pielęgniarek, refundacja wynagrodzeń rezydentów itd.)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    // fieldId: "id",
    fieldLabel: (
      <>
        CZĘŚĆ PODSTAWOWA II - KOSZTY (dla zdefiniowanych kategorii kosztowych
        [poz. A-K] należy przyporządkować właściwe koszty z danych FK
        umieszczonych poniżej w części szczegółowej):
      </>
    ),
    fieldType: "header",
  },
  {
    fieldId: "koszt_calk",
    fieldLabel: (
      <>
        A. KOSZTY CAŁKOWITE<sup>1</sup>, w tym: (koszty całkowite są równe sumie
        pozycji B, C, D, E, F, G, H, I, J, K)
      </>
    ),
    fieldType: "title",
  },
  {
    fieldId: "koszt_lek_wm",
    fieldLabel: (
      <>B. Koszty leków i wyrobów medycznych (suma pozycji b1 do b3)</>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "opk_num2",
    fieldLabel: (
      <>
        b1. w tym: leków refundowanych odrębnie (np. chemioterapia, programy
        lekowe)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_lek_wm_przyp",
    fieldLabel: <>b2. w tym: pozostałych przypisywanych na pacjenta</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_lek_wm_nieprzyp",
    fieldLabel: (
      <>
        b3. w tym: pozostałych nieprzypisywanych do pacjenta (np. stanowiących
        koszt osobodnia lub infrastruktury bloku)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_amort",
    fieldLabel: (
      <>
        C. Koszty amortyzacji<sup>2</sup> (suma pozycji c1 do c5)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "koszt_amort_bud",
    fieldLabel: (
      <>
        c1. w tym: budynków, lokali, prawa do lokali i obiektów inżynierii
        lądowej i wodnej
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_amort_urz",
    fieldLabel: <>c2. w tym: urządzeń technicznych i maszyn (grupa 3-6 KŚT)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_amort_sr_transp",
    fieldLabel: <>c3. w tym: środków transportu (grupa 7 KŚT)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_amort_inne_sr_trw",
    fieldLabel: <>c4. w tym: innych środków trwałych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_amort_niematerial",
    fieldLabel: <>c5. w tym: wartości niematerialnych i prawnych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_procedur",
    fieldLabel: (
      <>
        D. Koszty procedur<sup>3</sup>
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "koszt_zarzad",
    fieldLabel: (
      <>E. Koszty zarządu4, w tym wynagrodzenia personelu administracyjnego</>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "koszt_up_all",
    fieldLabel: (
      <>
        F. Koszty wynagrodzeń personelu wraz z pochodnymi wynikające z tytułu
        umów o pracę<sup>5</sup> (suma pozycji f1 do f15)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "koszt_up_lek_nor_ord",
    fieldLabel: (
      <>
        f1. w tym: lekarzy niebędących rezydentami za pracę w normalnej
        ordynacji<sup>6</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_up_lek_dyz",
    fieldLabel: (
      <>
        f2. w tym: lekarzy niebędących rezydentami za dyżury w miejscu
        <sup>7</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_up_lek_got",
    fieldLabel: (
      <>
        f3. w tym: lekarzy niebędących rezydentami za dyżury w gotowości
        <sup>8</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_up_lek_proc",
    fieldLabel: (
      <>
        f4. w tym: lekarzy niebędących rezydentami za realizację procedur
        medycznych<sup>9</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_up_piel",
    fieldLabel: <>f5. w tym: pielęgniarek i położnych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num1_up",
    fieldLabel: <>f6. w tym: perfuzjonistów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num2_up",
    fieldLabel: (
      <>f7. w tym: psychologów, psychoterapeutów, terapeutów uzależnień</>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num3_up",
    fieldLabel: <>f8. w tym: dietetyków</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num4_up",
    fieldLabel: <>f9. w tym: logopedów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num5_up",
    fieldLabel: (
      <>
        f10. w tym: fizjoterapeutów, rehabilitantów, techników rehabilitacji,
        masażystów, mgr rehabilitacji
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num6_up",
    fieldLabel: <>f11. w tym: fizyków medycznych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num7_up",
    fieldLabel: <>f12. w tym: techników (radiologii, elektroradiologii itd.)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num8_up",
    fieldLabel: <>f13. w tym: terapeutów zajęciowych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num9_up",
    fieldLabel: (
      <>
        f14. w tym: pozostałego personelu medycznego<sup>10</sup> (ratowników
        medycznych itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num10_up",
    fieldLabel: (
      <>
        f15. w tym: pozostałego personelu niemedycznego<sup>11</sup> (salowych,
        rejestratorek, sekretarek medycznych, opiekunów medycznych, sanitariuszy
        itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_ucp_all",
    fieldLabel: (
      <>
        G. Koszty wynagrodzeń personelu wynikające z umów cywilno-prawnych,
        kontraktów itp.<sup>12</sup> (suma pozycji g1 do g15)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "koszt_ucp_lek_nor_ord",
    fieldLabel: (
      <>
        g1. w tym: lekarzy niebędących rezydentami za pracę w normalnej
        ordynacji<sup>6</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_ucp_lek_dyz",
    fieldLabel: (
      <>
        g2. w tym: lekarzy niebędących rezydentami za dyżury w miejscu
        <sup>7</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_ucp_lek_got",
    fieldLabel: (
      <>
        g3. w tym: lekarzy niebędących rezydentami za dyżury w gotowości
        <sup>8</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_ucp_lek_proc",
    fieldLabel: (
      <>
        g4. w tym: lekarzy niebędących rezydentami za realizację procedur
        medycznych<sup>9</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "koszt_ucp_piel",
    fieldLabel: <>g5. w tym: pielęgniarek i położnych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num1_ucp",
    fieldLabel: <>g6. w tym: perfuzjonistów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num2_ucp",
    fieldLabel: (
      <>g7. w tym: psychologów, psychoterapeutów, terapeutów uzależnień</>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num3_ucp",
    fieldLabel: <>g8. w tym: dietetyków</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num4_ucp",
    fieldLabel: <>g9. w tym: logopedów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num5_ucp",
    fieldLabel: (
      <>
        g10. w tym: fizjoterapeutów, rehabilitantów, techników rehabilitacji,
        masażystów, mgr rehabilitacji
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num6_ucp",
    fieldLabel: <>g11. w tym: fizyków medycznych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num7_ucp",
    fieldLabel: <>g12. w tym: techników (radiologii, elektroradiologii itd.)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num8_ucp",
    fieldLabel: <>g13. w tym: terapeutów zajęciowych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num9_ucp",
    fieldLabel: (
      <>
        g14. w tym: pozostałego personelu medycznego<sup>10</sup> (ratowników
        medycznych itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "opk_num10_ucp",
    fieldLabel: (
      <>
        g15. w tym: pozostałego personelu niemedycznego<sup>11</sup> (salowych,
        rejestratorek, sekretarek medycznych, opiekunów medycznych, sanitariuszy
        itd.)
      </>
    ),
    fieldType: "simple-title",
  },

  // Sekcja H
  {
    fieldId: "wynagr_rez",
    fieldLabel: <>H. Wynagrodzenia rezydentów (suma pozycji h1 do h2)</>,
    fieldType: "sub-title",
  },
  {
    fieldId: "wynagr_rez_ref",
    fieldLabel: <>h1. refundowane</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "wynagr_rez_sr_wlasne",
    fieldLabel: <>h2. ze środków własnych podmiotu leczniczego</>,
    fieldType: "simple-title",
  },
  // Sekcja I
  {
    fieldId: "transp_med",
    fieldLabel: <>I. Transport medyczny</>,
    fieldType: "sub-title",
  },
  // Sekcja J
  {
    fieldId: "wyzywienie_pac",
    fieldLabel: (
      <>
        J. Wyżywienie pacjentów<sup>13</sup>
      </>
    ),
    fieldType: "sub-title",
  },
  // Sekcja K
  {
    fieldId: "koszt_pozost",
    fieldLabel: (
      <>
        K. Pozostałe kosty <sup>14</sup>
      </>
    ),
    fieldType: "sub-title",
  },
  // Uwagi
  {
    fieldId: "uwagi",
    fieldLabel: <>Uwagi</>,
    fieldType: "comments",
  },
];

export const Tab1FormFields = [
  {
    fieldId: "liczba_lozek",
    fieldLabel: <>A. Liczba łóżek / miejsc w oddziałach dziennych</>,
    fieldType: "sub-title",
  },
  {
    fieldId: "liczba_osd",
    fieldLabel: <>B1. Liczba zrealizowanych osobodni (suma za okres sprawozdawczy)</>,
    fieldType: "sub-title",
  },
  {
    fieldId: "tab1_num1",
    fieldLabel: <>B2. Liczba zrealizowanych świadczeń (suma za okres sprawozdawczy; dotyczy OPKów jak poradnie POZ, poradnie specjalistyczne, zakłady, ambulatoria, bloki, pracownie)</>,
    fieldType: "sub-title",
  },
  {
    fieldId: "miesiace_funk_opk",
    fieldLabel: <>C. Liczba miesięcy funkcjonowania OPK w okresie sprawozdawczym</>,
    fieldType: "sub-title",
  },
  {
    fieldId: "liczba_sal",
    fieldLabel: (
      <>D. Liczba sal operacyjnych/zabiegowych/gabinetów pracowni/gabinetów poradni funkcjonujących w ramach OPK
lub liczba łóżek porodowych/foteli stomatologicznych/stanowsk dializacyjnych, na których realizowane są świadczenia
lub liczba akceleratorów/PET/innych kosztochłonnych aparatów w pracowniach</>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "liczba_zesp",
    fieldLabel: (
      <>
        d1. Liczba zespołów operacyjnych w gotowości w godzinach nocnych i w dni
        wolne od pracy
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "rzecz_il_godz",
    fieldLabel: (
      <>
        d2. Rzeczywista łączna liczba godzin pracy wszystkich sal operacyjnych/zabiegowych/gabinetów pracowni/gabinetów poradni funkcjonujących w ramach OPK lub rzeczywista łączna liczba godzin pracy wszystkich łóżek porodowych/foteli stomatologicznych/stanowisk dializacyjnych, na których realizowane są świadczenia lub rzeczywista łączna liczba godzin pracy wszystkich akceleratorów/PET/innych kosztochłonnych aparatów funkcjonujących w ramach OPK
(suma za okres sprawozdawczy)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "spec_lek_opk",
    fieldLabel: (
      <>
        E. Specjalności lekarzy zatrudnionych w OPK
      </>
    ),
    fieldType: "text-sub-title",
  },
  {
    fieldId: "lek_poza_opk",
    fieldLabel: (
      <>
        e1. Rzeczywista liczba godzin pracy personelu lekarskiego realizowana poza OPK
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "lek_poza_opk_inne",
    fieldLabel: (
      <>
        e2. Wymienić po przecinku inne OPKi (nr kont OPK), w których realizowane są  godziny pracy lekarzy poza OPKiem zatrudnienia wskazane w pozycji e1
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num2",
    fieldLabel: (
      <>
        e3. Rzeczywista liczba godzin pracy personelu pielęgniarskiego realizowana poza OPK
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num3",
    fieldLabel: (
      <>
        e4. wymienić po przecinku inne OPKi (nr kont OPK), w których realizowane są  godziny pracy pielęgniarek poza OPKiem zatrudnienia wskazane w pozycji e3
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_up_all",
    fieldLabel: (
      <>
        F. Suma etatów zatrudnionego na umowy o pracę personelu (suma za okres sprawozdawczy; NIE średnia za miesiąc; suma pozycji f1 do f15)
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "etat_up_lek_nor_ord",
    fieldLabel: (
      <>
        f1. w tym: lekarzy niebędących rezydentami za pracę w normalnej
        ordynacji
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_up_lek_dyz",
    fieldLabel: (
      <>
        f2. w tym: lekarzy niebędących rezydentami za dyżury w miejscu
        <sup>7</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_up_lek_got",
    fieldLabel: (
      <>
        f3. w tym: lekarzy niebędących rezydentami za dyżury w gotowości
        <sup>8</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_up_lek_proc",
    fieldLabel: (
      <>
        f4. w tym: lekarzy niebędących rezydentami za realizację procedur
        medycznych<sup>9</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_up_piel",
    fieldLabel: <>f5. w tym: pielęgniarek i położnych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num1_up",
    fieldLabel: <>f6. w tym: perfuzjonistów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num2_up",
    fieldLabel: (
      <>f7. w tym: psychologów, psychoterapeutów, terapeutów uzależnień</>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num3_up",
    fieldLabel: <>f8. w tym: dietetyków</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num4_up",
    fieldLabel: <>f9. w tym: logopedów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num5_up",
    fieldLabel: (
      <>
        f10. w tym: fizjoterapeutów, rehabilitantów, techników rehabilitacji,
        masażystów, mgr rehabilitacji
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num6_up",
    fieldLabel: <>f11. w tym: fizyków medycznych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num7_up",
    fieldLabel: <>f12. w tym: techników (radiologii, elektroradiologii itd.)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num8_up",
    fieldLabel: <>f13. w tym: terapeutów zajęciowych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num9_up",
    fieldLabel: (
      <>
        f14. w tym: pozostałego personelu medycznego<sup>10</sup> (ratowników
        medycznych itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num10_up",
    fieldLabel: (
      <>
        f15. w tym: pozostałego personelu niemedycznego<sup>11</sup> (salowych,
        rejestratorek, sekretarek medycznych, opiekunów medycznych, sanitariuszy
        itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_ucp_all",
    fieldLabel: (
      <>
        G. Suma etatów personelu wynikających z umów cywilno-prawnych, podwykonawstwa medycznego itp. (suma za okres sprawozdawczy; NIE średnia za miesiąc; suma pozycji g1 do g15 )
      </>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "etat_ucp_lek_nor_ord",
    fieldLabel: (
      <>
        g1. w tym: lekarzy niebędących rezydentami za pracę w normalnej
        ordynacji<sup>6</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_ucp_lek_dyz",
    fieldLabel: (
      <>
        g2. w tym: lekarzy niebędących rezydentami za dyżury w miejscu
        <sup>7</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_ucp_lek_got",
    fieldLabel: (
      <>
        g3. w tym: lekarzy niebędących rezydentami za dyżury w gotowości
        <sup>8</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_ucp_lek_proc",
    fieldLabel: (
      <>
        g4. w tym: lekarzy niebędących rezydentami za realizację procedur
        medycznych<sup>9</sup>
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_ucp_piel",
    fieldLabel: <>g5. w tym: pielęgniarek i położnych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num1_ucp",
    fieldLabel: <>g6. w tym: perfuzjonistów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num2_ucp",
    fieldLabel: (
      <>g7. w tym: psychologów, psychoterapeutów, terapeutów uzależnień</>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num3_ucp",
    fieldLabel: <>g8. w tym: dietetyków</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num4_ucp",
    fieldLabel: <>g9. w tym: logopedów</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num5_ucp",
    fieldLabel: (
      <>
        g10. w tym: fizjoterapeutów, rehabilitantów, techników rehabilitacji,
        masażystów, mgr rehabilitacji
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num6_ucp",
    fieldLabel: <>g11. w tym: fizyków medycznych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num7_ucp",
    fieldLabel: <>g12. w tym: techników (radiologii, elektroradiologii itd.)</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num8_ucp",
    fieldLabel: <>g13. w tym: terapeutów zajęciowych</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num9_ucp",
    fieldLabel: (
      <>
        g14. w tym: pozostałego personelu medycznego<sup>10</sup> (ratowników
        medycznych itd.)
      </>
    ),
    fieldType: "simple-title",
  },
  {
    fieldId: "tab1_num10_ucp",
    fieldLabel: (
      <>
        g15. w tym: pozostałego personelu niemedycznego<sup>11</sup> (salowych,
        rejestratorek, sekretarek medycznych, opiekunów medycznych, sanitariuszy
        itd.)
      </>
    ),
    fieldType: "simple-title",
  },

  // Sekcja H
  {
    fieldId: "etat_rez",
    fieldLabel: (
      <>H. Suma etatów rezydenckich (suma za okres sprawozdawczy; NIE średnia za miesiąc, suma pozycji h1 do h2)</>
    ),
    fieldType: "sub-title",
  },
  {
    fieldId: "etat_rez_ref",
    fieldLabel: <>h1. etaty refundowane</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "etat_rez_sr_wlasne",
    fieldLabel: <>h2. praca finansowana ze środków własnych</>,
    fieldType: "simple-title",
  },
];

export const Tab2FormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 5,
    fieldData: [
      {
        fieldId: "kod_zakresu",
        fieldLabel: "Kod zakresu",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 4,
    fieldData: [
      {
        fieldId: "kod_produktu",
        fieldLabel: "Kod Produktu",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldId: "ilosc_prod",
        fieldLabel: "Liczba produktów jednostkowych",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
];

export const Tab3FormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldId: "nazwa_sr_trw",
        fieldLabel: "Nazwa środka trwałego / WNiP",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 9,
    fieldData: [
      {
        fieldcolSize: 1,

        fieldId: "kat_sr_trw",
        fieldLabel: "Kategoria środków trwałych [c1 do c5]",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 0,

        fieldId: "zamortyzowany",
        fieldLabel: "Zamortyzowany",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 1,

        fieldId: "najem_dzierz",
        fieldLabel: "Najem/ dzierżawa/ leasing/ użyczenie",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 1,

        fieldId: "rok_nabycia",
        fieldLabel: "Rok nabycia środka trwałego i WNiP",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "wart_pocz",
        fieldLabel: "Wartość początkowa środka trwałego i WNiP",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 0,

        fieldId: "ilosc_sztuk",
        fieldLabel: "Ilość sztuk środków trwałych",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 1,

        fieldId: "miesiace_funk",
        fieldLabel:
          "Liczba miesięcy funkcjonowania danego środka trwałego i WNIP w okresie sprawozdawczym",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 0,

        fieldId: "roczny_koszt_utrz",
        fieldLabel: "Koszt utrzymania środka trwałego i WNIP w okresie sprawozdawczym",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 0,

        fieldId: "roczny_koszt_amort",
        fieldLabel: "Koszt amortyzacji środka trwałego i WNIP za okres sprawozdawczy",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 0,

        fieldId: "wysokosc_dotacji",
        fieldLabel: "Wysokość dotacji",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldcolSize: 0,

        fieldId: "bledy",
        fieldLabel: "Błędy",
        fieldType: "alert",
        dafault: "",
      },
    ],
  },
];

export const AnalyticsFormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldId: "nr_konta",
        fieldLabel: "Numer konta analitycznego",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 4,
    fieldData: [
      {
        fieldId: "nazwa_konta",
        fieldLabel: "Nazwa konta analitycznego",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldId: "koszt_roczny",
        fieldLabel: "Koszt w okresie sprawozdawczym",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldId: "bledy",
        fieldLabel: "Błędy",
        fieldType: "alert",
        dafault: "",
        disabled: true,
      },
    ],
  },
];

export const Tab4FormFields = [
  {
    fieldId: "koszt_calk",
    fieldLabel: <>Koszt całkowity:</>,
    fieldType: "title",
  },
  {
    fieldId: "uwagi",
    fieldLabel: <>Uwagi</>,
    fieldType: "comments",
  },
];

export const OpkAkceptacjaFormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 3,
        fieldId: "opk_niemedyczny",
        fieldLabel: "Uproszczone dane FK",
        fieldType: "checkbox",
        dafault: false,
      },
      {
        fieldcolSize: 9,
        fieldId: "nr_konta",
        fieldLabel: "Numer konta OPK",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "nazwa_opk",
        fieldLabel: "Nazwa OPK",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "kod_resortowy",
        fieldLabel: "Kod resortowy",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 0,
        fieldId: "rok",
        fieldLabel: "Rok",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "uwagi",
        fieldLabel: "Uwagi",
        fieldType: "textarea",
        dafault: "",
        rows: "1",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldcolSize: 0,
        fieldId: "zaakceptowany_da",
        fieldLabel: "Akceptacja DA",
        fieldType: "checkbox",
        dafault: false,
      },
      {
        fieldcolSize: 0,
        fieldId: "do_poprawy",
        fieldLabel: "Do poprawy",
        fieldType: "checkbox",
        dafault: false,
      },
      {
        fieldcolSize: 0,
        fieldId: "odrzucony",
        fieldLabel: "Odrzucony",
        fieldType: "checkbox",
        dafault: false,
      },
      {
        fieldcolSize: 0,
        fieldId: "del",
        fieldLabel: "Usuń",
        fieldType: "checkbox",
        dafault: false,
      },
    ],
  },
  {
    colSize: 0,
    fieldData: [
      {
        fieldcolSize: 0,

        fieldId: "bledy",
        fieldLabel: "Błędy",
        fieldType: "alert",
        dafault: "",
      },
    ],
  },
];

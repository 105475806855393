import React, { useState, useEffect, Fragment } from "react";

// reactstrap components
import {
  Button,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

// components
import { SmalModal } from "components/Modal/SmalModal";

const GeneratedForm = ({ opkData, formFields, dataSubmit }) => {
  const [formData, setFormData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pasteData, setPasteData] = useState([]);

  const baseFormData = (fieldsDict) => {
    const baseDict = {};
    fieldsDict.map((dict) => {
      return dict.fieldData.map(
        (element) => (baseDict[element.fieldId] = element.dafault)
      );
    });

    return baseDict;
  };

  useEffect(() => {
    setFormData(() => {
      if (opkData && opkData.length > 0) {
        return opkData
          .map((opk_object) => {
            return {
              ...baseFormData(formFields),
              ...opk_object,
            };
          })
          .concat(baseFormData(formFields));
      } else {
        return [baseFormData(formFields)];
      }
    });
  }, [opkData, formFields]);

  useEffect(() => {
    setPasteData(['nr_konta', 'nazwa_opk', 'kod_resortowy', 'rok'])
  }, [opkData, formFields]);

  const handleModalToggler = () => setShowModal((prevState) => !prevState);

  const handleChangeFieldNumber = (value) => {
    const validValue = parseInt(value) >= 1 ? parseInt(value) : "";

    const numberOfForms = [
      ...Array(parseInt(validValue === "" ? 1 : validValue)).keys(),
    ];

    setFormData((prevState) => {
      let prevFormData = [...prevState];

      if (prevFormData.length <= parseInt(value)) {
        const dataKeysToAdd = numberOfForms.filter(
          (item) => !prevFormData[item]
        );
        dataKeysToAdd.map(() =>
          prevFormData.push({ ...baseFormData(formFields) })
        );
      } else if (prevFormData.length > parseInt(value)) {
        prevFormData = numberOfForms.map((item) => prevFormData[item]);
      }

      return prevFormData;
    });
  };

  const handleCheckAll = (event) => {
    const checkAllField = event.target.id.split("-")[1];
    const isChecked = event.target.checked;

    setFormData((prevSate) => {
      const formDataNewValues = [...prevSate];
      // eslint-disable-next-line
      formDataNewValues.map((item, itemIdx) => {
        if (item["id"] !== "") {
          item[checkAllField] = isChecked;
        }
      });

      return formDataNewValues;
    });
  };

  const handleFieldChange = (e) => {
    const fieldIndex = e.target.id.split("-")[1];
    const fieldName = e.target.id.split("-")[2];
    const fieldValue = e.target.value;
    const fieldType = e.target.type;
    const isChecked = e.target.checked;

    setFormData((prevSate) => {
      const formDataNewValues = [...prevSate];
      if (fieldType !== "checkbox") {
        formDataNewValues[fieldIndex][fieldName] = fieldValue;
      } else {
        formDataNewValues[fieldIndex][fieldName] = isChecked;
      }

      return formDataNewValues;
    });
  };

  const handleDecimalChange = (e) => {
    const fieldIndex = e.target.id.split("-")[1];
    const fieldName = e.target.id.split("-")[2];
    let fieldValue = e.target.value;
    const fieldType = e.target.type;

    // this.start = e.target.selectionStart;
    // let val = e.target.value;
    fieldValue = fieldValue.replace(/([^0-9.]+)/, "");
    fieldValue = fieldValue.replace(/^(0|\.)/, "");
    const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(fieldValue);
    const value = match[1] + match[2];
    fieldValue = Number(value).toFixed(2);

    setFormData((prevSate) => {
      const formDataNewValues = [...prevSate];
      formDataNewValues[fieldIndex][fieldName] = fieldValue;
      return formDataNewValues;
    });

    // if (fieldValue.length > 0) {
    //   e.target.value = Number(value).toFixed(2);
    // }
  };

  const hendleCheckboxWidth = (fieldItem) => {
    if (
      fieldItem.fieldData.filter((field) => field.fieldType === "checkbox")
        .length > 1
    ) {
      const widthValue = `${
        100 /
        fieldItem.fieldData.filter((field) => field.fieldType === "checkbox")
          .length
      }%`;
      return { maxWidth: widthValue };
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemsToDel = formData.filter((formItem) => formItem.del === true);
    if (itemsToDel.length) {
      console.log(itemsToDel);
      handleModalToggler();
    } else {
      dataSubmit(formData);
    }
  };

  const findTextPlain = (typesArray) => {
    return typesArray.indexOf('text/plain');
  }

  const handlePaste = (e) => {
    const fieldIndex = parseInt(e.target.id.split("-")[1]);
    const paste = e.clipboardData.items[findTextPlain(e.clipboardData.types)];

    paste.getAsString((text) => {
      const cleanedRows = text.split("\n").map((el) => el.split("\t"));
      if (cleanedRows[cleanedRows.length - 1].length === 1) {
        cleanedRows.pop();
      }
      setFormData((prevState) => {
        let prevFormData = [...prevState];
        const numberOfForms = [
          ...Array(
            fieldIndex === 0
              ? cleanedRows.length
              : fieldIndex + cleanedRows.length
          ).keys(),
        ];

        if (prevFormData.length <= fieldIndex + cleanedRows.length) {
          const dataKeysToAdd = numberOfForms.filter(
            (item) => !prevFormData[item]
          );
          dataKeysToAdd.map(() =>
            prevFormData.push({ ...baseFormData(formFields) })
          );
        }

        // eslint-disable-next-line
        cleanedRows.map((element, idx) => {
          const fieldToUpdate = prevFormData[fieldIndex + idx];
          pasteData.map((fieldName, idx) => {
            fieldToUpdate[`${fieldName}`] = element[idx];
            return fieldToUpdate;
          });
        });

        return prevFormData;
      });
    }); 
  };

  // render Form Fields
  const render_from_fields = (
    dataItem,
    dataItemIdx,
    fieldcolSize,
    fieldType,
    fieldId
  ) => {
    switch (fieldType) {
      case "hidden":
        return (
          <Input
            id={`form-${dataItemIdx}-${fieldId}`}
            value={dataItem[fieldId] !== null ? dataItem[fieldId] : ""}
            onChange={(e) => handleFieldChange(e)}
          />
        );

      case "decimal":
        return (
          <Input
            id={`form-${dataItemIdx}-${fieldId}`}
            className={"font-small"}
            value={dataItem[fieldId] !== null ? dataItem[fieldId] : ""}
            type="text"
            onChange={(e) => handleDecimalChange(e)}
            onPaste={(e) => handlePaste(e)}
          />
        );

      case "checkbox":
        return (
          <>
            <Input
              id={`form-${dataItemIdx}-${fieldId}`}
              className={"generated-form-checkbox"}
              type="checkbox"
              checked={dataItem[fieldId]}
              onChange={(e) => handleFieldChange(e)}
            />
            <span className="form-check-sign" />
          </>
        );

      case "textarea":
        return (
          <Input
            id={`form-${dataItemIdx}-${fieldId}`}
            className={"font-small"}
            value={dataItem[fieldId] !== null ? dataItem[fieldId] : ""}
            onChange={(e) => handleFieldChange(e)}
          />
        );

      case "alert":
        return (
          <FormGroup>
            {dataItem[fieldId] && (
                <ul
                  dangerouslySetInnerHTML={{
                    __html: dataItem[fieldId],
                  }}
                  className={"box-error"}
                />
            )}
          </FormGroup>
        );

      default:
        return (
          <Input
            id={`form-${dataItemIdx}-${fieldId}`}
            className={"font-small"}
            value={dataItem[fieldId] !== null ? dataItem[fieldId] : ""}
            onChange={(e) => handleFieldChange(e)}
            onPaste={(e) => handlePaste(e)}
          />
        );
    }
  };

  return (
    <div className="generated-form-main-container">
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className={"unscrollable"}>
          <div className={"form-header my-2"}>
            {/* Buttons Area */}
            <Row>
              <Col xs={3}>
                <Button block outline type="submit" className="btn-aotmit">
                  Zapisz
                </Button>
              </Col>
              <Col xs={4}>
                <Input
                  id="number-of-forms"
                  type="number"
                  value={formData.length}
                  onChange={(e) => handleChangeFieldNumber(e.target.value)}
                />
              </Col>
            </Row>
          </div>
        </div>

        {/* Headers Area */}

        <div className={"form-header"}>
          <Row className={"centered-items"}>
            {formFields.map((fieldItem, fieldItemIdx) => (
              <Fragment key={fieldItemIdx}>
                {fieldItem.colSize !== null && (
                  <Col xs={fieldItem.colSize} className="p-0">
                    <Row className={"centered-items"}>
                      {fieldItem.fieldData.map((field, fieldIdx) => (
                        <Fragment key={fieldIdx}>
                          {field.fieldType === "checkbox" &&
                          fieldItem.fieldData.length > 1 ? (
                            <Col
                              xs={field.fieldcolSize}
                              className={"checkbox-label"}
                              style={hendleCheckboxWidth(fieldItem)}
                            >
                              {`${field.fieldLabel}`}
                            </Col>
                          ) : (
                            <Col
                              xs={field.fieldcolSize}
                            >{`${field.fieldLabel}`}</Col>
                          )}
                        </Fragment>
                      ))}
                    </Row>
                  </Col>
                )}
              </Fragment>
            ))}
          </Row>
          <Row className={"centered-items"}>
            {formFields.map((fieldItem, fieldItemIdx) => (
              <Fragment key={fieldItemIdx}>
                {fieldItem.colSize !== null && (
                  <Col
                    xs={fieldItem.colSize}
                    key={fieldItemIdx}
                    className="p-0"
                  >
                    <Row className={"centered-items"}>
                      {fieldItem.fieldData.map((field, fieldIdx) => (
                        <Fragment key={fieldIdx}>
                          {field.fieldType === "checkbox" ? (
                            <Col key={fieldIdx} xs={field.fieldcolSize}>
                              <Input
                                id={`checkAll-${field.fieldId}`}
                                className={"generated-form-checkbox"}
                                type={`${field.fieldType}`}
                                // checked={false}
                                onChange={(e) => handleCheckAll(e)}
                              />
                              <span className="form-check-sign" />
                            </Col>
                          ) : (
                            <Col key={fieldIdx} xs={field.fieldcolSize}></Col>
                          )}
                        </Fragment>
                      ))}
                    </Row>
                  </Col>
                )}
              </Fragment>
            ))}
          </Row>
        </div>

        {/* Form Area */}
        <div className="scrollable">
          <div className="generated-form-container">
            {formData.map((dataItem, dataItemIdx) => {
              return (
                <Row key={dataItemIdx} className="align-items-center">
                  {formFields.map((fieldItem, fieldItemIdx) => {
                    return (
                      <Fragment key={fieldItemIdx}>
                        {fieldItem.colSize !== null && (
                          <Col xs={fieldItem.colSize} className="p-0">
                            <Row
                              key={fieldItemIdx}
                              className={"centered-items"}
                            >
                              {fieldItem.fieldData.map((field, fieldIdx) => (
                                <Col
                                  xs={field.fieldcolSize}
                                  key={fieldIdx}
                                  className="p-1"
                                >
                                  {render_from_fields(
                                    dataItem,
                                    dataItemIdx,
                                    field.fieldcolSize,
                                    field.fieldType,
                                    field.fieldId
                                  )}
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        )}
                      </Fragment>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        </div>
      </Form>

      <SmalModal
        size="md"
        show={showModal}
        handleClose={handleModalToggler}
        modalBodyText={
          <>
            <p className={"text-center"}>Zamierzasz usunąć OPK:</p>
            <div className={"text-center"}>
              {formData
                .filter((formItem) => formItem.del === true)
                .map((dataItem, dataItemIdx) => {
                  return (
                    <p style={{ fontSize: "80%" }} key={dataItemIdx}>
                      {formFields.map((fieldItem, fieldItemIdx) => (
                        <Fragment key={fieldItemIdx}>
                          {fieldItem.colSize !== null && (
                            <>
                              {fieldItem.fieldData.map((field, fieldIdx) => (
                                <Fragment key={fieldIdx}>
                                  {`${dataItem[field.fieldId]}`}
                                </Fragment>
                              ))}
                            </>
                          )}
                        </Fragment>
                      ))}
                    </p>
                  );
                })}
            </div>
          </>
        }
        handleAccept={() => dataSubmit(formData)}
      />
    </div>
  );
};

export default GeneratedForm;

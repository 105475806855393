import {
  GET_ANALYTICS_DATA,
  SAVE_ANALYTICS_DATA,
  ANALYTICS_DATA_SET_LOADING,
} from "actions/types.js";

const initialState = {
  AnalyticsData: [],
  AnalyticsDataLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        AnalyticsDataLoading: false,
      };
    case SAVE_ANALYTICS_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        AnalyticsDataLoading: false,
      };
    case ANALYTICS_DATA_SET_LOADING:
      return {
        ...state,
        AnalyticsDataLoading: true,
      };
    default:
      return state;
  }
};

import React, { useState } from "react";
import PropTypes from "prop-types";

// Bootstrap
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";

// reactstrap components
import { Form, Input, Button } from "reactstrap";

const OpkColumnForm = ({ opk_object, formFields, dataSubmit }) => {
  const [formData, setFormData] = useState(opk_object);

  const opkErrors = (opk_object) => {
    if (opk_object.bledy) {
      return (
        <ul
          dangerouslySetInnerHTML={{ __html: opk_object.bledy }}
          className={"box-error"}
        />
      );
    }
  };

  const handleFieldChange = (e) => {
    const fieldName = e.target.id.split("-")[1];
    const fieldValue = e.target.value;
    // const fieldType = e.target.type;

    setFormData((prevSate) => {
      const formDataNewValues = { ...prevSate };
      formDataNewValues[fieldName] = fieldValue;
      return formDataNewValues;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dataSubmit(formData);
  };

  return (
    <div className={"form-container"}>
      {opkErrors(opk_object)}
      <Form onSubmit={(e) => handleSubmit(e)}>
        <div className={"list-group-container"}>
          <div className="btn-unscrollable">
            <Button type="submit" color="secondary" size="lg" block>
              Zapisz
            </Button>
          </div>

          <div className={"list-scrollable"}>
            {formFields.map((fieldItem, idx) => {
              switch (fieldItem.fieldType) {
                case "header":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <h6 className={"mt-2"}>{fieldItem.fieldLabel}</h6>
                    </div>
                  );
                case "text-title":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-main-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="text"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "title":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-main-title"}>
                        {fieldItem.fieldLabel}
                      </div>

                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type={"number"}
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : 0
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "text-sub-title":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="text"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                  
                case "sub-title":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type={"number"}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : 0
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "simple-title":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-simple-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="number"
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : 0
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "comments":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-simple-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        as={"textarea"}
                        type={"textarea"}
                        rows={"4"}
                        id={`id-${fieldItem.fieldId}`}
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "text-sub-title-not-changeable":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="text"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  );
                case "phone-number-input":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="text"
                        pattern="[\d wew;,.()+-:]+"
                        minLength="9"
                        maxLength="60"
                        title="Przykład: (22)123-11-22 wew. 56; 321 321 321"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "email-input":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="email"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      />
                    </div>
                  );
                case "city-size-select":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="select"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      >
                        <option></option>
                        <option value={1}>&lt; 40 tys.</option>
                        <option value={2}>40-100 tys.</option>
                        <option value={3}>&gt; 100 tys.</option>
                      </Input>
                    </div>
                  );
                case "activity-form-select":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="select"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      >
                        <option></option>
                        <option value={1}>Podmiot Publiczny</option>
                        <option value={2}>Podmiot Niepubliczny, którego organem założycielskim jest organ władzy centralnej, samorządowej lub wyższa uczelnia </option>
                        <option value={3}>Podmiot Niepubliczny, którego organem założycielskim jest osoba prawna lub osoba fizyczna</option>
                      </Input>
                    </div>
                  );
                case "didactic-activity-select":
                  return (
                    <div className={"list-item-container"} key={idx}>
                      <div className={"list-sub-title"}>
                        {fieldItem.fieldLabel}
                      </div>
                      <Input
                        className={"list-form"}
                        id={`id-${fieldItem.fieldId}`}
                        type="select"
                        value={
                          formData[`${fieldItem.fieldId}`]
                            ? formData[`${fieldItem.fieldId}`]
                            : ""
                        }
                        onChange={(e) => handleFieldChange(e)}
                      >
                        <option></option>
                        <option value={true}>Tak</option>
                        <option value={false}>Nie</option>
                      </Input>
                    </div>
                  );
                default:
                  return <>Coś poszło nie tak</>;
              }
            })}
          </div>
        </div>
      </Form>
    </div>
  );
};

OpkColumnForm.propTypes = {
  opk_object: PropTypes.object,
  formFields: PropTypes.array.isRequired,
  dataSubmit: PropTypes.func.isRequired,
};

export default OpkColumnForm;

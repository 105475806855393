import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { loginUser } from "../../actions/auth";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import logo from "../../assets/img/aotm.png";

const Login = ({ isAuthenticated, loginUser }) => {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const location = useLocation();

  const handleFieldChange = (e) => {
    const fieldValue = e.target.value;
    const fieldIndex = e.target.id;

    // console.log(value, id)
    setFormData((prevState) => {
      const prevLoginValues = { ...prevState };
      prevLoginValues[fieldIndex] = fieldValue;
      return prevLoginValues;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser(formData);
  };

  if (isAuthenticated) {
    const { from } = location.state || { from: "/" };
    return <Redirect to={from} />;
  }

  return (
    <>
      <div
        className={"text-center"}
        style={{ "fontWeight": "bold", "fontSize": "1.5rem" }}
      >
        <br />
        Dane kosztowe wersja 2.0
      </div>
      <div className={"flex-center"}>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Card className={"login-card"}>
            <Card.Body className={"p-2"}>
              <Container>
                <img src={logo} className="login-img" alt="AOTMiT logo" />
                <div className={"mt-5 text-center"}>
                  {"Zaloguj się w Aplikacji Dane kosztowe"}
                </div>
                <Form.Control
                  className={"my-2"}
                  placeholder="Login"
                  aria-label="Login"
                  id={`username`}
                  type={`text`}
                  value={formData["username"]}
                  onChange={(e) => handleFieldChange(e)}
                />
                <Form.Control
                  placeholder="Hasło"
                  aria-label="Hasło"
                  id={`password`}
                  type={`password`}
                  value={formData.password}
                  onChange={(e) => handleFieldChange(e)}
                />
                {/* <Row>
                        <Col xs={3}>
                            <Button block type="submit">
                                Zapisz
                            </Button>
                        </Col>
                    </Row> */}
              </Container>
            </Card.Body>
            <Card.Footer>
              <Button block type="submit">
                Zaloguj
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  loading: state.authReducer.loading,
});

export default connect(mapStateToProps, { loginUser })(Login);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  // Route,
  // Switch,
  useLocation,
} from "react-router-dom";

// import { NavLink } from "react-router-dom";

import {
  getAnalyticsData,
  saveAnalyticsFormData,
} from "actions/AnalyticsActions/AnalyticsDataActions";

import { postExcelFilesAnalytics } from "actions/AnalyticsActions/AnalyticsUplaoadExcel";

// reactstrap components
import { Row, Col, Button, Form, Input } from "reactstrap";

import GeneratedForm from "../../components/GeneratedForm/GeneratedForm";
import { SmalModal } from "components/Modal/SmalModal";

export const AnalyticsFormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 6,

        fieldId: "billing_period_year",
        fieldLabel: "Okres rozliczeniowy - rok",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 6,

        fieldId: "billing_period_month",
        fieldLabel: "Okres rozliczeniowy - miesiąc",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 9,
    fieldData: [
      {
        fieldcolSize: 3,

        fieldId: "account_number_5",
        fieldLabel: "Numer konta zespołu 5",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "account_name_5",
        fieldLabel: "Nazwa konta zespołu 5",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "account_number_4",
        fieldLabel: "Numer konta zespołu 4",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "account_name_4",
        fieldLabel: "Nazwa konta zespołu 4",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldId: "cost_value",
        fieldLabel: "Wartość kosztu w zł",
        fieldType: "decimal",
        dafault: "",
      },
    ],
  },
];

const AnalyticsPrecedureForm = ({
  analytics_objects,
  match,
  postExcelFilesAnalytics,
  getAnalyticsData,
  saveAnalyticsFormData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const { procedureID } = match.params;

  const location = useLocation();

  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    getAnalyticsData(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  // const checkLocationState = (url) => {
  //   return location.pathname.split("/").pop() === url;
  // };

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
  };

  const sendExcelWithAnalytics = (file, procedureID) => {
    postExcelFilesAnalytics(file, procedureID);
    // setSelectedFile("");
  };

  const saveData = async (submitedFormData) => {
    saveAnalyticsFormData(procedureID, submitedFormData);
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper px-4 mx-auto" ref={sidebar}>
          <Button
            // disabled={
            //   switchedUserSDInfo.verifications &&
            //   switchedUserSDInfo.verifications.czy_otwarte === true &&
            //   true
            // }
            size="sm"
            outline
            color="success"
            block
            type="button"
            className={"mt-2"}
            onClick={() => handleModalShow()}
          >
            Prześlij do AOTMiT
          </Button>
          <SmalModal
            show={showModal}
            handleClose={handleModalShow}
            modalBodyText={
              <p className={"text-center"}>
                Czy na pewno chcesz przesłać dane do AOTMiT?
              </p>
            }
            handleAccept={handleAccept}
          />
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <>
          <div className="head-xl-container">
            <h3>Dane analityczne</h3>
            <p>Wprowadzanie danych analitycznych</p>
          </div>

          <main className="main-xl-container">
            <Form className="ml-2">
              <Row>
                <Col>
                  <Input
                    id="analyticsFile"
                    className={"mx-auto"}
                    name="file"
                    type="file"
                    label={
                      typeof selectedFile == "string" ||
                      typeof selectedFile == "undefined"
                        ? ""
                        : selectedFile.hasOwnProperty("name.txt")
                        ? selectedFile.name
                        : ""
                    }
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                </Col>
                <Col>
                  <Button
                    outline
                    className={"btn-aotmit"}
                    type="button"
                    onClick={() => {
                      sendExcelWithAnalytics(selectedFile, procedureID);
                    }}
                  >
                    <i className="fa upload" /> Prześlij plik
                  </Button>
                </Col>
              </Row>
            </Form>

            <GeneratedForm
              opkData={analytics_objects}
              formFields={AnalyticsFormFields}
              dataSubmit={saveData}
            />
          </main>
        </>
      </div>
    </div>
  );
};

AnalyticsPrecedureForm.propTypes = {
  match: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  analytics_objects: state.analyticsDataReducer.AnalyticsData.analytics_objects,
  loading: state.analyticsDataReducer.AnalyticsDataLoading,
});

export default connect(mapStateToProps, {
  postExcelFilesAnalytics,
  getAnalyticsData,
  saveAnalyticsFormData,
})(AnalyticsPrecedureForm);

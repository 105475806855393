import axios from "axios";

import {
  GET_OPK_LIST_DATA,
  GET_FK_FORM_FIELDS,
  FK_FORM_FIELDS_LOADING,
  OPK_LIST_DATA_SET_LOADING,
  GET_ERRORS,
} from "./types";

import { configTokenSwitcher } from "./utilActions";

// get fields
export const getFKFormFields = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: FK_FORM_FIELDS_LOADING });

    const response = await axios.get(
      `/fk/api/${procedureID}/get_fields`,
      configTokenSwitcher(getState)
    );

    dispatch({
      type: GET_FK_FORM_FIELDS,
      payload: response.data,
    });
  } catch (err) {
    if (err.response) {
      console.log("SD_Error", err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ err });
    }
  }
};

//  GET data
export const getOpkData = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPK_LIST_DATA_SET_LOADING });

    const response = await axios.get(
      `/fk/api/${procedureID}/opk_list`,
      configTokenSwitcher(getState)
    );
    dispatch({
      type: GET_OPK_LIST_DATA,
      payload: response.data,
    });
  } catch (err) {
    if (err.response) {
      console.log(err.response.data);
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };

      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ err });
    }
  }
};

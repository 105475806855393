import axios from "axios";

import {
  CONFIRM_SD_DATA,
  CONFIRM_SD_DATA_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { configTokenSwitcher } from "../utilActions";

// const apiURL = process.env.REACT_APP_API_URL;

//  CONFIRM dszcz SWD
export const confirmSDData = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONFIRM_SD_DATA_SET_LOADING });

    const response = await axios.get(
      `/daneszcz/api/${procedureID}/confirm_specific_data`,
      configTokenSwitcher(getState)
    );
    dispatch({
      type: CONFIRM_SD_DATA,
      payload: response.status,
    });

    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: "Dane zostały przesłane do AOTMiT",
        status: response.status,
      },
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

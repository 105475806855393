import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux'

import { switchUser } from '../../actions/switchUserActions'


import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import logo from "../../assets/img/aotm.png";

const SwitchUser = ({ isSwitchedUser, loading, switchUser }) => {
    const [formData, setFormData] = useState({ username: "" });
    const location = useLocation()

    const handleFieldChange = (e) => {
        const fieldValue = e.target.value;
        const fieldIndex = e.target.id

        setFormData((prevState) => {
            const prevLoginValues = { ...prevState };
            prevLoginValues[fieldIndex] = fieldValue;
            return prevLoginValues;
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        switchUser(formData)
    }

    if (isSwitchedUser) {
        const { from } = location.state || { from: "/" }
        return <Redirect to={from} />
    }

    return (
        <div className={"flex-center"}>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Card className={"login-card"}>
                    <Card.Body className={"p-2"}>

                        <Container>
                            <img
                                src={logo}
                                className="login-img"
                                alt="AOTMiT logo"
                            />
                            <div className={"mt-5 text-center"}>
                                {"Przełącz Użytkownika"}
                            </div>
                            <Form.Control
                                className={
                                    "my-2"
                                }
                                placeholder="Login"
                                aria-label="Login"
                                id={`username`}
                                type={`text`}
                                value={formData['username']}
                                onChange={(e) => handleFieldChange(e)}
                            />
                        </Container>
                    </Card.Body>
                    <Card.Footer>
                        <Button block type="submit">
                            Przełącz
                    </Button>
                    </Card.Footer>
                </Card>
            </Form>
        </div>
    )
}

SwitchUser.prototype = {
    isSwitchedUser: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    switchUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isSwitchedUser: state.switchUserReducer.isSwitchedUser,
    loading: state.switchUserReducer.loading
})

export default connect(mapStateToProps, { switchUser })(SwitchUser)

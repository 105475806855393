import axios from "axios";
import { saveAs } from "file-saver";

import {
  // GET_PERSONALIZED_EXCEL,
  // GET_PERSONALIZED_EXCEL_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { configFileTokenSwitcher } from "../utilActions";

// get personalized excel
export const getpersonalizedexcel =
  (procedureID) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `/fk/api/${procedureID}/get_personalized_excel`,
        configFileTokenSwitcher(getState)
      );
      let blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "Wzor_FK.xlsx");

      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Plik FK został poprawnie pobrany.",
          status: response.status,
        },
      });
    } catch (err) {
      console.log(err.response, err.response.data);

      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    }
  };

import axios from "axios";

import {
  CHECK_DATA,
  CHECK_DATA_SET_LOADING,
  CHECK_FAILED,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { configTokenSwitcher } from "../utilActions";

//  opkCheckData
export const opkCheckData = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECK_DATA_SET_LOADING });
    const response = await axios.get(
      `/fk/api/${procedureID}/check_data`,
      configTokenSwitcher(getState)
    );

    dispatch({
      type: CHECK_DATA,
      payload: response.data,
    });

    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: response.data.message,
        status: response.status,
      },
    });
    // dispatch(clearLoading())
  } catch (err) {
    dispatch({ type: CHECK_FAILED });
    if (!err.response.data) {
      console.error({
        err: err,
        resp: err.response,
      });
    } else {
      const errors = {
        msg: err.response.statusText,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    }
  }
};

import { GET_PROCEEDINGS, LANDING_SET_LOADING } from '../../actions/types.js'

const initialState = {
  proceedings: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROCEEDINGS:
      return {
        ...state,
        proceedings: action.payload,
        loading: false
      };
    case LANDING_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
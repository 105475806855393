import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { getOpkData, getFKFormFields } from "../../../actions/opkListActions";

import { Switch, Route, Link, useLocation } from "react-router-dom";

// React Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faCog } from "@fortawesome/free-solid-svg-icons";

// components
import OpkListComponent from "./OpkListComponent";
import OpkForm from "./OpkForm";
import Tab1Form from "./Tab1Form";
import Tab2Form from "./Tab2Form";
import Tab3Form from "./Tab3Form";
import Tab4Form from "./Tab4Form";
import AnalyticsForm from "./AnalyticsForm";
import { Loading } from "../../../components/Loading/loading";
import SideBarButtons from "./SideBarButtons";
import SwitchUserMenu from "../StaffUserViews/switchUserMenu";
import NotesAndFilesComponent from "../StaffUserViews/NotesAndFilesComponent";
import CardInfo from "../../../components/Cards/CardInfo";

const OpkListPage = ({
  proceeding,
  opk_list,
  form_fields,
  form_fields_loading,
  loading,
  CheckDataloading,
  isSwitchedUser,
  match,
  getOpkData,
  getFKFormFields,
}) => {
  const [showSidebar, setShowSidebar] = useState(true);

  const { procedureID, opkID } = match.params;

  const location = useLocation();
  const tabName = location.pathname.split("/").pop();

  useEffect(() => {
    getOpkData(procedureID);
    getFKFormFields(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const tabsControler = (currentOpk) => {
    if (form_fields_loading) {
      return <>...</>;
    } else {
      if (currentOpk.opk_niemedyczny === false) {
        return (
          <>
            {form_fields.opk_table && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/opkform`}
                  className={`nav-link ${
                    tabName === "opkform" ? "active" : "text-muted"
                  }`}
                >
                  <span>Opk</span>
                </Link>
              </Nav.Item>
            )}
            {form_fields.tab1 && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/tab1form`}
                  className={`nav-link ${
                    tabName === "tab1form" ? "active" : "text-muted"
                  }`}
                >
                  <span>Tab1</span>
                </Link>
              </Nav.Item>
            )}
            {form_fields.tab2 && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/tab2form`}
                  className={`nav-link ${
                    tabName === "tab2form" ? "active" : "text-muted"
                  }`}
                >
                  <span>Tab2</span>
                </Link>
              </Nav.Item>
            )}
            {form_fields.tab3 && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/tab3form`}
                  className={`nav-link ${
                    tabName === "tab3form" ? "active" : "text-muted"
                  }`}
                >
                  <span>Tab3</span>
                </Link>
              </Nav.Item>
            )}
            {form_fields.analytics && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/analyticsform`}
                  className={`nav-link ${
                    tabName === "analyticsform" ? "active" : "text-muted"
                  }`}
                >
                  <span>Analityka</span>
                </Link>
              </Nav.Item>
            )}
          </>
        );
      } else if (currentOpk.opk_niemedyczny === true) {
        return (
          <>
            {form_fields.tab4 && (
              <Nav.Item>
                <Link
                  to={`/fk/${procedureID}/opk/${opkID}/tab4form`}
                  className={`nav-link ${
                    tabName === "tab4form" ? "active" : "text-muted"
                  }`}
                >
                  <span>Tab4</span>
                </Link>
              </Nav.Item>
            )}
          </>
        );
      }
    }
  };

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar && (
          <Col className={"p-0"} xs={2} xl={1}>
            <SideBarButtons procedureID={procedureID} />
            {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
          </Col>
        )}
        <Col>
          <>
            {isSwitchedUser && (
              <NotesAndFilesComponent procedureID={procedureID} />
            )}

            {CheckDataloading ? (
              <div className="head-container">
                <h3>
                  {proceeding &&
                    `Postepowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
                </h3>
                <>{form_fields.announcement}</>
                <>
                  {opk_list && opk_list.length !== 0 ? (
                    <>
                      <p>Liczba kont OPK: {opk_list.length}</p>
                      <>
                        {!form_fields_loading && form_fields.announcement && (
                          <div className={"border-box"}>
                            <div className={"border-box-title text-aotmit"}>
                              <FontAwesomeIcon icon={faInfo} size="1x" />
                            </div>
                            <div className={"border-box-content"}>
                              {form_fields.announcement}
                            </div>
                          </div>
                        )}
                      </>
                    </>
                  ) : (
                    <div className={"border-box"}>
                      <div className={"border-box-title text-warning"}>
                        <FontAwesomeIcon icon={faInfo} size="1x" />
                      </div>
                      <div className={"border-box-content"}>
                        <h6>Nie dodano jeszcze kont OPK w tym Postępowaniu</h6>
                      </div>
                    </div>
                  )}
                </>

                <Loading variant={"danger"}>
                  <span className="m-2">
                    Dane przesłane w tym postępowaniu są weryfikowane. Może to
                    potrwać kilka minut
                  </span>
                </Loading>
              </div>
            ) : (
              <>
                <div className="head-container">
                  <h3>
                    {proceeding &&
                      `Postepowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
                  </h3>
                  <>
                    {opk_list && opk_list.length !== 0 ? (
                      <p>Liczba kont OPK: {opk_list.length}</p>
                    ) : (
                      <div className={"border-box"}>
                        <div className={"border-box-title text-warning"}>
                          <FontAwesomeIcon icon={faInfo} size="1x" />
                        </div>
                        <div className={"border-box-content"}>
                          <h6>
                            Nie dodano jeszcze kont OPK w tym Postępowaniu
                          </h6>
                        </div>
                      </div>
                    )}
                  </>
                  {!form_fields_loading && form_fields.announcement && (
                    <div className={"border-box"}>
                      <div className={"border-box-title text-aotmit"}>
                        <FontAwesomeIcon icon={faInfo} size="1x" />
                      </div>
                      <div className={"border-box-content"}>
                        {form_fields.announcement}
                      </div>
                    </div>
                  )}
                  {loading && !opk_list ? (
                    <Loading variant={"danger"}>
                      <span className="m-2">Pobieranie listy OPK...</span>
                    </Loading>
                  ) : (
                    <OpkListComponent
                      opklist={opk_list}
                      procedureID={procedureID}
                      opkID={opkID}
                    />
                  )}
                </div>
                <main className="main-container">
                  {opkID ? (
                    <Card className={"mt-4"}>
                      <Card.Header>
                        <div className={"box-info"}>
                          <Row>
                            <Col xs={3}>
                              <h6>Nr opk:</h6>
                              <h6>Nazwa opk: </h6>
                              <h6>Kod resortowy: </h6>
                            </Col>
                            <Col xs={9}>
                              <div className={"mb-1"}>
                                {opk_list &&
                                  opk_list.find(
                                    (opk) => opk.id === parseInt(opkID)
                                  ).nr_konta}
                              </div>
                              <div className={"mb-1"}>
                                {opk_list &&
                                  opk_list.find(
                                    (opk) => opk.id === parseInt(opkID)
                                  ).nazwa_opk}
                              </div>
                              <div className={"mb-1"}>
                                {opk_list &&
                                  opk_list.find(
                                    (opk) => opk.id === parseInt(opkID)
                                  ).kod_resortowy}
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <Nav variant="tabs">
                          {opk_list &&
                            tabsControler(
                              opk_list.find((opk) => opk.id === parseInt(opkID))
                            )}
                        </Nav>
                      </Card.Header>
                      <Card.Body>
                        <Switch>
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/opkform"
                            component={OpkForm}
                          />
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/tab1form"
                            component={Tab1Form}
                          />
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/tab2form"
                            component={Tab2Form}
                          />
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/tab3form"
                            component={Tab3Form}
                          />
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/tab4form"
                            component={Tab4Form}
                          />
                          <Route
                            exact
                            path="/fk/:procedureID/opk/:opkID/analyticsform"
                            component={AnalyticsForm}
                          />
                        </Switch>
                      </Card.Body>
                    </Card>
                  ) : (
                    <CardInfo
                      headerClass={`text-warning`}
                      header={<FontAwesomeIcon icon={faInfo} size="2x" />}
                      bodyTitle={`Zapraszamy do wypełnienia formularzy.`}
                    />
                  )}
                </main>
              </>
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

OpkListPage.propTypes = {
  proceeding: PropTypes.object,
  opk_list: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getOpkData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  proceeding: state.opkListReducer.data.proceeding,
  opk_list: state.opkListReducer.data.opk_list,
  form_fields: state.opkFormFieldsReducer.form_fields,
  form_fields_loading: state.opkFormFieldsReducer.loading,
  loading: state.opkListReducer.loading,
  CheckDataloading: state.opkCheckDataReducer.loading,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
});

export default connect(mapStateToProps, { getOpkData, getFKFormFields })(
  OpkListPage
);

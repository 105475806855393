import axios from "axios";

import {
  GET_OPK_USER_PROFILE,
  OPK_USER_PROFILE_SET_LOADING,
  GET_OPK_USER_INFO,
  OPK_USER_INFO_SET_LOADING,
  // GET_SUCCESS,
  GET_ERRORS,
} from "./types";

import { configTokenSwitcher, authTokenConfig } from "./utilActions";

//  GET_OPK_USER_PROFILE
export const getOpkUserProfile = () => async (dispatch, getState) => {
  try {
    dispatch({ type: OPK_USER_PROFILE_SET_LOADING });

    const response = await axios.get(
      `/fk/api/user_profile`,
      configTokenSwitcher(getState)
    );

    dispatch({
      type: GET_OPK_USER_PROFILE,
      payload: response.data,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

//  GET_OPK_USER_INFO
export const getUserInfo = (userID) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPK_USER_INFO_SET_LOADING });

    const response = await axios.get(
      `/fk/api/user_info/${userID}`,
      authTokenConfig(getState)
    );

    dispatch({
      type: GET_OPK_USER_INFO,
      payload: response.data,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

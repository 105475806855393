import React, { useEffect } from "react";
import { connect } from "react-redux";

// reactstrap components
import { Button, Spinner } from "reactstrap";
// import Spinner from "react-bootstrap/Spinner";

import { getexcelstatistics } from "../../../actions/StaffUserViewsActions/getExcelStatisticsActions";
import { getSwitchedUserInfo } from "../../../actions/StaffUserViewsActions/switchedUserInfoActions";
// import { Loading } from '../../../components/Loading/loading';

const SwitchUserMenu = ({
  procedureID,
  switchedUserInfo,
  loading,
  getexcelstatistics,
  getSwitchedUserInfo,
}) => {
  useEffect(() => {
    getSwitchedUserInfo(procedureID);
    // eslint-disable-next-line
  }, []);

  const render_veryfication = (verification) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (verification.czy_otwarte === true) {
      return (
        <>
          <h6 className={"text-aotmit mb-0"}>Przesłane</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small mb-0"}>
            {verification.zatwierdzil}
          </p>
        </>
      );
    } else if (verification.czy_otwarte === false) {
      return (
        <>
          <h6 className={"text-success mb-0"}>Zatwierdzone</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small"}>{verification.zatwierdzil}</p>
        </>
      );
    } else if (verification.czy_otwarte === null) {
      return (
        <>
          <h6 className={"text-warning mb-0"}>Odblokowane</h6>
          <p className={"text-muted font-small mb-0"}>{`${new Date(
            verification.data_wpisu
          ).toLocaleDateString("pl-PL", options)} `}</p>
          <p className={"text-muted font-small mb-0"}>
            {verification.zatwierdzil}
          </p>
        </>
      );
    }
  };

  return (
    <div className={"switchUserMenu"}>
      <Button
        size="sm"
        outline
        block
        type="button"
        className={"btn-aotmit"}
        onClick={() => getexcelstatistics(procedureID)}
      >
        Generuj Excel ze statystykami
      </Button>
      <div className={"swd-info"}>
        {!switchedUserInfo && loading ? (
          <Spinner
            animation="border"
            role="status"
            aria-hidden="true"
            variant="info"
          />
        ) : (
          <>
            {switchedUserInfo.verifications && (
              <>
                <span>Startus weryfikacji:</span>
                {render_veryfication(switchedUserInfo.verifications)}
              </>
            )}
            <span>Numer ankiety:</span>

            {switchedUserInfo.survey_number ? (
              <p className={"text-muted font-small mb-0"}>
                {switchedUserInfo.survey_number}{" "}
              </p>
            ) : (
              <p className={"text-muted font-small mb-0"}>{"Nie dodano"}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  switchedUserInfo: state.switchedUserInfoReducer.switchedUserInfo,
  loading: state.switchedUserInfoReducer.switchedUserInfoLoading,
});

export default connect(mapStateToProps, {
  getexcelstatistics,
  getSwitchedUserInfo,
})(SwitchUserMenu);

import { GET_TAB3_FORM_DATA, SAVE_TAB3_FORM_DATA, TAB3_FORM_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  Tab3Data: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TAB3_FORM_DATA:
      return {
        ...state,
        Tab3Data: action.payload,
        loading: false
      };
    case SAVE_TAB3_FORM_DATA:
      return {
        ...state,
        Tab3Data: action.payload,
        loading: false
      };
    case TAB3_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { getTab1FormData, saveTab1FormData } from '../../../actions/OpkFormsActions/tab1FormActions'
import OpkColumnForm from '../OpkForms/OpkColumnForm';
import { Loading } from '../../../components/Loading/loading';

import { Tab1FormFields } from '../OpkForms/FormSettings'


const Tab1Form = ({ tab1_object, loading, getTab1FormData, saveTab1FormData, match }) => {
  const { procedureID, opkID } = match.params;

  useEffect(() => {
    getTab1FormData(procedureID, opkID)
    // eslint-disable-next-line
  }, [procedureID, opkID])

  const saveData = async (submitedFormData) => {
    saveTab1FormData(procedureID, opkID, submitedFormData)
  };

  return (
    <>
      {loading ?
        (<Loading variant={"danger"} >
          <span className="m-2">
            Dane są zapisywane na serwerze...
                </span>
        </Loading>) :
        (<OpkColumnForm
          opk_object={tab1_object}
          formFields={Tab1FormFields}
          dataSubmit={saveData} />
        )}
    </>
  )
}

Tab1Form.propTypes = {
  tab1_object: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  getTab1FormData: PropTypes.func.isRequired,
  saveTab1FormData: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  tab1_object: state.tab1FormReducer.Tab1Data.tab1_object,
  loading: state.tab1FormReducer.loading
})


export default connect(mapStateToProps, { getTab1FormData, saveTab1FormData })(Tab1Form)

import {
  GET_OPK_USER_INFO,
  OPK_USER_INFO_SET_LOADING,
} from '../../actions/types.js';

const initialState = {
  user_info: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_USER_INFO:
      return {
        ...state,
        user_info: action.payload,
        loading: false,
      };
    case OPK_USER_INFO_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

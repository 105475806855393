import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  getSpecificDataFormFields,
  uploadSecyficDataFiles,
} from "../../actions/SpecificDataActions/SD_FormActions";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";

import SDSidebarButtons from "./SDSidebarButtons";
import { Loading } from "../../components/Loading/loading";
import SDFilesTable from "./SDFilesTable";
import { Progress } from "reactstrap";
import { postSpecificDataRequest } from "../../actions/types";

const SpecificDataForm = ({
  getSpecificDataFormFields,
  specyficDataFormFields,
  uploadSecyficDataFiles,
  specyficDataloading,
  match,
  alreadyLoaded,
  totalSize,
  requestType,
}) => {
  const [uploadedFilesLabels, setUploadedFilesLabels] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState(new FormData());

  const [showSidebar, setShowSidebar] = useState(true);

  const { procedureID } = match.params;

  useEffect(() => {
    getSpecificDataFormFields(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const handleFileInput = (event) => {
    const newID = event.target.id;
    const newFile = event.target.files[0];

    setUploadedFiles((prevState) => {
      const newState = prevState;
      if (newState.get(newID)) {
        newState.set(newID, newFile);
      } else {
        newState.append(newID, newFile);
      }

      return newState;
    });

    setUploadedFilesLabels((prevState) => {
      const newState = {
        ...prevState,
      };

      newState[`${newID}`] = newFile.name;
      return newState;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    uploadSecyficDataFiles(procedureID, uploadedFiles);
    setUploadedFiles(new FormData());
    setUploadedFilesLabels({});
  };

  const validateFileType = (element) => {
    if (element !== "OM" && element !== "CP") {
      return ".csv"
    }
  }

  const generateSpecyficDataForm = () => {
    const FieldsNames = Object.keys(
      Object.fromEntries(
        Object.entries(specyficDataFormFields).filter(([k, v]) => v === true)
      )
    );
    return (
      <>
        {FieldsNames.map((element, el_idx) => {
          return (
            <Form.Group
              as={Row}
              controlId={`formGrid${element}`}
              className="pb-2 border-bottom"
              key={el_idx}
            >
              <Form.Label column sm={2}>{`Plik ${element}`}</Form.Label>
              <Col sm={10}>
                <Form.File
                  type="file"
                  id={`${element}`}
                  label={
                    uploadedFilesLabels[element]
                      ? uploadedFilesLabels[element]
                      : "nie wybrano pliku"
                  }
                  data-browse="Wybierz plik"
                  onChange={(event) => handleFileInput(event)}
                  custom
                  accept={validateFileType(element)}
                />
              </Col>
            </Form.Group>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar && (
          <Col className={"p-0"} xs={2} xl={1}>
            <div className={"dropdownMenu"}>
              <SDSidebarButtons procedureID={procedureID} />
            </div>
          </Col>
        )}
        <Col>
          <div className="head-container">
            <h2>Dane szczegółowe</h2>
            <p>Wgraj pliki do weryfikacji</p>
          </div>

          <main className="main-container">
            <Row>
              <Col sm={12} lg={6}>
                <ListGroup
                  className="list-item font-small mb-4"
                  variant="light"
                >
                  <Form onSubmit={(e) => handleSubmit(e)}>
                    <>
                      {specyficDataloading ? (
                        <>
                          <Loading variant={"info"}>
                            <span className="m-2">
                              Informacja o postępowaniach jest pobierana z
                              serwera...
                            </span>
                          </Loading>
                          <>
                            {requestType === postSpecificDataRequest &&
                              totalSize && <Progress value={alreadyLoaded} />}
                          </>
                        </>
                      ) : (
                        <>
                          <>{generateSpecyficDataForm()}</>
                          <Button
                            type="submit"
                            variant={"outline-success"}
                            className={"mx-5"}
                          >
                            Zapisz
                          </Button>
                        </>
                      )}
                    </>
                  </Form>
                </ListGroup>
              </Col>
              <Col sm={12} lg={6}>
                <SDFilesTable procedureID={procedureID} />
              </Col>
            </Row>
          </main>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  specyficDataFormFields: state.sd_FormReducer.specyficDataFormFields,
  specyficDataUploadedFiles: state.sd_FormReducer.specyficDataUploadedFiles,
  specyficDataloading: state.sd_FormReducer.specyficDataloading,
  alreadyLoaded: state.utilityReducer.alreadyLoaded,
  totalSize: state.utilityReducer.totalSize,
  requestType: state.utilityReducer.requestType,
});

export default connect(mapStateToProps, {
  getSpecificDataFormFields,
  uploadSecyficDataFiles,
})(SpecificDataForm);

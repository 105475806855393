import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types";

import { logoutSwichedUser } from '../../actions/switchUserActions'
import { Loading } from '../../components/Loading/loading';
import { Redirect } from 'react-router-dom';


const Logout = ({ user, isSwitchedUser, switchedUser, logoutSwichedUser }) => {

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      logoutSwichedUser()
    }, 3000);
    return () => {
      clearTimeout(redirectTimer)
    }
    // eslint-disable-next-line
  }, [])

  if (isSwitchedUser) {
    return (
      <>
        <Loading variant={"info"} >
          <span className="mx-auto my-2">
            <p>{`Konczysz pracę jako ${switchedUser.username}. `}</p>
            <p>{`Za chwilę zostniesz przekierowany(a)`}</p>
            <p>{`na stronę główną...`}</p>
          </span>
        </Loading>
      </>
    )
  } else {
    return (<Redirect to='/' />)
  }
}

Logout.prototype = {
  user: PropTypes.object,
  isSwitchedUser: PropTypes.bool.isRequired,
  switchedUser: PropTypes.object.isRequired,
  logoutSwichedUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  switchedUser: state.switchUserReducer.switchedUser
})

export default connect(mapStateToProps, { logoutSwichedUser })(Logout)

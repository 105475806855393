import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getSecyficDataFiles,
  getReport,
  updateSpecyficDataFiles,
} from "../../actions/SpecificDataActions/SD_FormActions";

// reactstrap components
import {
  Button,
  Table,
  Progress,
  // Container
} from "reactstrap";

const SDFilesTable = ({
  procedureID,
  specyficDataFiles,
  specyficDataloading,
  getSecyficDataFiles,
  updateSpecyficDataFiles,
  getReport,
  alreadyLoaded,
  totalSize,
}) => {
  useEffect(() => {
    getSecyficDataFiles(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  const [reportKey, setReportKey] = useState(null);

  const get_raport = (id, file, key) => {
    setReportKey(key);
    getReport(id, file, procedureID);
  };

  useEffect(() => {
    if (totalSize === null) {
      setReportKey(null);
    }
  }, [totalSize]);

  useEffect(() => {
    const pendingDataFiles = specyficDataFiles.results?.some((file) => file.czy_raport === "Oczekuje");

    if (pendingDataFiles) {
      let interval = setInterval(() => {
        updateSpecyficDataFiles(procedureID)
      }, 20000)
  
      return () => {
        clearInterval(interval);
      };
    }

  }, [specyficDataFiles])

  return (
    <>
      {!specyficDataloading && (
        <Table responsive striped>
          <thead>
            <tr>
              <th className="text-center">Nazwa Pliku</th>
              <th className="text-center">Data przeslania</th>
              <th className="text-center">Typ pliku</th>
              <th className="text-right">Sprawdzony plik</th>
            </tr>
          </thead>
          <tbody className="font-small">
            {!specyficDataloading &&
              specyficDataFiles.results &&
              specyficDataFiles.results.map((file, file_idx) => {
                return (
                  <tr key={file_idx}>
                    <td className="text-center">
                      <a href={file.plik2}>{file.nazwa}</a>
                    </td>
                    <td className="text-center">
                      {new Date(file.data_wgrania).toLocaleString()}
                    </td>
                    <td className="text-center">{file.typ_pliku}</td>
                    <td className="text-right">
                      <Button
                        className={"btn-aotmit mb-0"}
                        disabled={
                          file.czy_raport &&
                          (file.czy_raport !== "Raport gotowy" ||
                            (file.czy_raport === "Raport gotowy" &&
                              reportKey === file_idx &&
                              totalSize)) &&
                          true
                        }
                        outline
                        size="sm"
                        onClick={(e) =>
                          get_raport(
                            file.raport_id,
                            file.wynik_raport,
                            file_idx
                          )
                        }
                      >
                        {reportKey === file_idx && totalSize
                          ? "Trwa pobieranie raportu"
                          : file.czy_raport}
                        {reportKey === file_idx && totalSize && (
                          <Progress value={alreadyLoaded} />
                        )}
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  specyficDataFiles: state.sd_FormReducer.specyficDataFiles,
  specyficDataFormFields: state.sd_FormReducer.specyficDataFormFields,
  specyficDataloading: state.sd_FormReducer.specyficDataloading,
  alreadyLoaded: state.utilityReducer.alreadyLoaded,
  totalSize: state.utilityReducer.totalSize,
});

export default connect(mapStateToProps, { getSecyficDataFiles, getReport, updateSpecyficDataFiles })(
  SDFilesTable
);

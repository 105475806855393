import {
  GET_MATERIALS,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIALS_SET_LOADING,
} from "actions/types.js";

const initialState = {
  material_list: [],
  material_categories: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MATERIALS:
      return {
        ...state,
        material_list: action.payload,
        loading: false,
      };
    case GET_MATERIAL_CATEGORIES:
      return {
        ...state,
        material_categories: action.payload,
        loading: false,
      };
    case GET_MATERIALS_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

import {
  SET_LOADING_REQUEST_STATUS,
} from "../actions/types";

const initialState = {
  alreadyLoaded: null,
  totalSize: null,
  requestType: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_REQUEST_STATUS:
      return {
        alreadyLoaded: action.payload.alreadyLoaded,
        totalSize: action.payload.totalSize,
        requestType: action.payload.requestType,
      };
    default:
      return state;
  }

};

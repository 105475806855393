import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../../assets/img/aotm_50px.png";
import LinksControler from "./LinksControler";
import UserInfo from "./userInfo";


const Header = () => {

  return (
    <>
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="AOTMiT logo"
          />{''}
        </Navbar.Brand>
        <UserInfo />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <LinksControler />
          </Nav>
        </Navbar.Collapse >
      </Navbar>
    </>
  );
};

export default Header;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";

import { getListForVerification } from "../../../actions/StaffUserViewsActions/OpkListForVerificationActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import { Loading } from "../../../components/Loading/loading";

const ListForVerificationContent = ({ proceedings, loading }) => {
  if (loading === true) {
    return (
      <>
        <Loading variant={"info"}>
          <span className="m-2">
            Informacja o postępowaniach jest pobierana z serwera...
          </span>
        </Loading>
      </>
    );
  } else if (proceedings.length === 0) {
    return (
      <div className={"container-items-centered"}>
        <Card style={{ width: "30rem" }} className="text-center box-shadow">
          <Card.Header className={"text-warning"}>
            <FontAwesomeIcon icon={faInfo} size="2x" />
          </Card.Header>
          <Card.Body>
            <Card.Title>
              Nie ma w tej chwili listy Kont OPK do akceptacji.
            </Card.Title>
          </Card.Body>
        </Card>
      </div>
    );
  } else {
    return (
      <>
        <ListGroup variant="flush">
          <ListGroup.Item className="list-item" variant="light" disabled>
            <span className="participation-item">Postępowanie nr:</span>
            <span className="year-item">Rok:</span>
            <span className="swd-id">Regon:</span>
            <span className="swd-name">Świadczeniodawca:</span>
          </ListGroup.Item>

          {proceedings.map((procceding) => {
            return (
              <ListGroup.Item
                className="list-item font-small"
                key={procceding.id}
                action
                variant="light"
                href={`${procceding.postepowanie.id}/${procceding.user.id}/opk_akceptacja`}
              >
                <span className="participation-item">
                  {procceding.postepowanie.postepowanie_nr}
                </span>
                <span className="year-item">
                  {procceding.postepowanie.postepowanie_rok}
                </span>
                <span className="swd-id">{procceding.user.username}</span>
                <span className="swd-name">
                  {procceding.user.profile.swd_nazwa !== null
                    ? procceding.user.profile.swd_nazwa
                    : "Nie podano nazwy SWD"}
                </span>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  }
};

const OpkListForVerification = ({
  proceedings_for_veryfication,
  loading,
  getListForVerification,
}) => {
  useEffect(() => {
    getListForVerification();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Card className="head-container">
        <Card.Body>
          <Card.Title>
            Akceptacja Przesłanych przez świadczeniodawcę kont OPK
          </Card.Title>

          <div>
            <ul>
              <li>Znak akceptacji - rekord został przejrzany.</li>
              <li>Przekreślenie - lista OPK zaakceptowana</li>
              <li>
                Do poprawy - po zaakceptowaniu świadczeniodawca będzie mógł
                edytować TYLKO ten OPK
              </li>
            </ul>
          </div>
        </Card.Body>
      </Card>

      <main className="main-container">
        <ListForVerificationContent
          proceedings={proceedings_for_veryfication.objects_to_accept}
          loading={loading}
        />
      </main>
    </>
  );
};

OpkListForVerification.propTypes = {
  proceedings_for_veryfication: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  proceedings_for_veryfication:
    state.opkListForVerification.proceedings_for_veryfication,
  loading: state.opkListForVerification.loading,
});

export default connect(mapStateToProps, { getListForVerification })(
  OpkListForVerification
);

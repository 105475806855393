import { GET_OPK_LIST_DATA, GET_FK_FORM_FIELDS, OPK_LIST_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  data: [],
  form_fields: {},
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_LIST_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
      case GET_FK_FORM_FIELDS:
      return {
        ...state,
        form_fields: action.payload,
        // loading: false
      };
    case OPK_LIST_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
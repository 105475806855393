import { GET_SUCCESS, GET_INFO, GET_ERRORS } from '../actions/types'
const initialState = {
  msg: {},
  status: null,
  alertType: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUCCESS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        alertType: 'success'
      }
    case GET_INFO:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        alertType: 'info'
      }
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
        alertType: 'error'
      }
    default:
      return state;
  }
}
import React from "react";
import { Switch, Route } from "react-router-dom";
import SpecificDataForm from "./SpecificDataForm";
import SpecificDataViewController from "./SpecificDataViewController";

const SpecificDataPage = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/daneszcz/:procedureID"
          component={SpecificDataViewController}
        />
        <Route
          exact
          path="/daneszcz/:procedureID/specific_data_form"
          component={SpecificDataForm}
        />
      </Switch>
    </>
  );
};

export default SpecificDataPage;

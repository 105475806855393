import axios from "axios";

import {
  GET_MATERIALS,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIALS_SET_LOADING,
  GET_ERRORS,
} from "actions/types.js";

import { authTokenConfig } from "../utilActions";

export const getMaterialCategory = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MATERIALS_SET_LOADING,
    });

    const response = await axios.get(
      `/instrukcja/api/materials`,
      authTokenConfig(getState)
    );
    dispatch({
      type: GET_MATERIAL_CATEGORIES,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

// get materials
export const getMaterials = (categorySlug) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_MATERIALS_SET_LOADING,
    });

    const response = await axios.get(
      `/instrukcja/api/materials/${categorySlug}`,
      authTokenConfig(getState)
    );

    dispatch({
      type: GET_MATERIALS,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

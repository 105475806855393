import axios from "axios";

import {
  GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING,
  GET_ALL_EXCEL_FILES_ANALYTICS,
  POST_EXCEL_FILES_ANALYTICS_SET_LOADING,
  POST_EXCEL_FILES_ANALYTICS,
  GET_ERRORS,
} from "actions/types";

import {
  authTokenConfig,
  configTokenSwitcher,
  setRequestStatus,
} from "../utilActions";

export const getAllExcelFilesAnalytics =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING,
      });

      const response = await axios.get(
        `/analytics/api/analytics_xlsx_files/?postepowanie_id=${procedureID}`,
        authTokenConfig(getState)
      );

      dispatch({
        type: GET_ALL_EXCEL_FILES_ANALYTICS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

export const postExcelFilesAnalytics =
  (xlsxFile, procedureID) => async (dispatch, getState) => {
    console.log("ExcelFilesAnalytics");
    dispatch({ type: POST_EXCEL_FILES_ANALYTICS_SET_LOADING });
    // const json = JSON.stringify(xlsxFile);
    // const fileBlob = new Blob([json], {
    //   type: "application/json",
    // });
    const data = new FormData();
    data.append("file", xlsxFile);
    data.append("postepowanie_id", procedureID);
    try {
      const response = await axios.post(
        `/analytics/api/analytics_xlsx_files/`,
        data,
        configTokenSwitcher(getState)
      );
      // return data
      dispatch({
        type: POST_EXCEL_FILES_ANALYTICS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = {
          msg: err.response.data,
          status: "",
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
        // dispatch({ type: UPLOAD_XLSX_FAIL });
      } else {
        console.log({ err });
      }
    }
  };

import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

import axios from "axios";

import { getOpkUserProfile } from '../../actions/OpkUserProfileActions'

// React FontAwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faCog } from '@fortawesome/free-solid-svg-icons'

// React Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";


import { Loading } from '../../components/Loading/loading'
import OpkColumnForm from './OpkForms/OpkColumnForm'
import { connect } from 'react-redux';

const UserProfileFormFields = [
  {
    fieldId: "swd_kod",
    fieldLabel: <>Kod świadczeniodawcy:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    fieldId: "swd_nazwa",
    fieldLabel: <>Nazwa świadczeniodawcy:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    fieldId: "swd_ulica",
    fieldLabel: <>Ulica:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    fieldId: "swd_miejscowosc",
    fieldLabel: <>Miejscowość:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    fieldId: "swd_kodpocztowy",
    fieldLabel: <>Kod pocztowy:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    // fieldId: "id", 
    fieldLabel: <> Dane osoby do kontaktu:</>,
    fieldType: "header",
  },
  {
    fieldId: "kontakt_imie",
    fieldLabel: <>Imię:</>,
    fieldType: "text-sub-title",
  },
  {
    fieldId: "kontakt_nazwisko",
    fieldLabel: <>Nazwisko:</>,
    fieldType: "text-sub-title",
  },
  {
    fieldId: "kontakt_email",
    fieldLabel: <>Adres e-mail:</>,
    fieldType: "email-input",
  },
  {
    fieldId: "kontakt_telefon",
    fieldLabel: <>Telefon:</>,
    fieldType: "phone-number-input",
  },
  {
    // fieldId: "id", 
    fieldLabel: <>Informacje dodatkowe:</>,
    fieldType: "header",
  },
  {
    fieldId: "ow_nfz",
    fieldLabel: <>Numer oddziału wojewódzkiego NFZ:</>,
    fieldType: "text-sub-title-not-changeable",
  },
  {
    fieldId: "wielkosc",
    fieldLabel: <>Wielkość miejscowości:</>,
    fieldType: "city-size-select",
  },
  {
    fieldId: "forma_dzialalnosci",
    fieldLabel: <>Forma działalności:</>,
    fieldType: "activity-form-select",
  },
  {
    fieldId: "dzialalnosc_dydakt",
    fieldLabel: <>Czy szpital prowadzi działalność dydaktyczną w zakresie kształcenia pielęgniarek, położnych lub lekarzy?</>,
    fieldType: "didactic-activity-select",
  },
  {
    fieldId: "ilosc_lozek",
    fieldLabel: <>Ilość łóżek w podmiocie:</>,
    fieldType: "simple-title",
  },
  {
    fieldId: "uwagi",
    fieldLabel: <>Uwagi:</>,
    fieldType: "comments",
  },
]

const UserProfileInfo = ({opk_User, saveData})=> {
  if (opk_User.profile.length > 0) {
    return (
    <OpkColumnForm
      opk_object={opk_User.profile[0]}
      formFields={UserProfileFormFields}
      dataSubmit={saveData} />
      )
  } else {
    return (
      <>
      <Card className="text-center mb-5">
      <Card.Header className={"text-warning"}><FontAwesomeIcon icon={faInfo} size="2x" /></Card.Header>
      <Card.Body>
        {`Profil tego użytkownika nie został utworzony`}
      </Card.Body>
    </Card></>
    )
  }}

const OpkUserProfile = ({ opk_User, loading, getOpkUserProfile }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showToast, setShowToast] = useState({toggleShow: "", message: ""});

  const history = useHistory()

  const handleSideBarToggler = () => {
    setShowSidebar(prevState => !prevState)
  }

  useEffect(() => {
    getOpkUserProfile()
    // eslint-disable-next-line
  }, [])

  const saveData = async (submitedFormData) => {
    try {
      const ogID = opk_User.profile[0].id;
      const response = await axios.put(`/api/og/${ogID}/`,
      submitedFormData);
      setShowToast({toggleShow: "show bg-success", message: "Dane zostały poprawnie zapisane"});
    } catch {
      setShowToast({toggleShow: "show bg-warning", message: "Dane nie zostały zapisane, spróbuj ponownie później."});
      }
    };

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar &&
          <Col className={'p-0'} xs={2} xl={1}>
            <div className={"dropdownMenu"}>
              <Button size="sm" variant="outline-success" block type="button" className={'btn-aotmit'} onClick={() => history.goBack()}>
                Powrót
              </Button>
            </div>
          </Col>
        }
        <Col className="position-relative">
          <Card className="head-container">
            <Card.Title>
              Profil Użytkownika
            </Card.Title>
            <ul>
              <li>Użytkownik: <p>{opk_User.username}</p></li>
              <li>e-mail: <p>{opk_User.email}</p></li>
            </ul>

          </Card>
          <main className='main-container'>

            {loading ?
              (<Loading variant={"danger"} >
                <span className="m-2">
                  Dane są zapisywane na serwerze...
                </span>
              </Loading>) :
              (opk_User.profile &&   <UserProfileInfo opk_User={opk_User} saveData={saveData}/>)}
          </main>

          <div className="toast-container position-absolute top-50 end-0 p-5">
            <div className={`toast ${showToast.toggleShow}`} role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header m-1">
                <strong className="me-auto p-2">{showToast.message}</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast({toggleShow: "", message: ""})}></button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = state => ({
  opk_User: state.opkUserProfileReducer.opk_User,
  loading: state.opkUserProfileReducer.loading

})

export default connect(mapStateToProps, { getOpkUserProfile })(OpkUserProfile)

import {
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
} from "../actions/types";

const initialState = {
  auth_token: localStorage.getItem("auth_token"),
  isAuthenticated: null,
  loading: null,
  user: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("auth_token", action.payload.auth_token);
      return {
        ...state,
        auth_token: action.payload.auth_token,
        isAuthenticated: true,
        loading: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case AUTH_ERROR:
    // eslint-disable-next-line
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("auth_token");
      return {
        ...state,
        auth_token: null,
        isAuthenticated: null,
        loading: false,
        user: null,
        error: null,
      };

    default:
      return state;
  }
};

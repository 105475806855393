// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// Switch User
export const SWITCH_USER_SUCCESS = "SWITCH_USER_SUCCESS";
export const SWITCH_USER_FAIL = "SWITCH_USER_FAIL";
export const SWITCH_USER_LOADED = "SWITCH_USER_LOADED";
export const SWITCH_USER_LOGOUT_SUCCESS = "SWITCH_USER_LOGOUT_SUCCESS";
// Alerts
export const GET_SUCCESS = "GET_SUCCESS";
export const GET_INFO = "GET_INFO";
export const GET_ERRORS = "GET_ERRORS";
// Loading
export const SET_LOADING = "SET_LOADING";
// OpkViewController
export const GET_CONTROLLER_DATA = "GET_CONTROLLER_DATA";
export const VIEW_CONTROLLER_SET_LOADING = "VIEW_CONTROLLER_SET_LOADING";
// LandingPage
export const GET_PROCEEDINGS = "GET_PROCEEDINGS";
export const LANDING_SET_LOADING = "LANDING_SET_LOADING";
// OPK NewList
export const GET_OPKS_NEW_LIST = "GET_OPKS_NEW_LIST";
export const ADD_OPKS_NEW_LIST = "ADD_OPKS_NEW_LIST";
export const CONFIRM_OPKS_SWD = "CONFIRM_OPKS_SWD";
export const OPKS_NEW_LIST_SET_LOADING = "OPKS_NEW_LIST_SET_LOADING";
// OpkListForVerification
export const GET_LIST_FOR_VERIFICATION = "GET_LIST_FOR_VERIFICATION";
export const LIST_FOR_VERIFICATION_SET_LOADING =
  "LIST_FOR_VERIFICATION_SET_LOADING";
// OPK Acceeptance
export const GET_OPKS_ACCEPTANCE = "GET_OPKS_ACCEPTANCE";
export const ADD_OPKS_ACCEPTANCE = "ADD_OPKS_ACCEPTANCE";
export const CONFIRM_OPKS = "CONFIRM_OPKS";
export const OPKS_ACCEPTANCE_SET_LOADING = "OPKS_ACCEPTANCE_SET_LOADING";
// OpkList

export const GET_OPK_LIST_DATA = "GET_OPK_LIST_DATA";
export const OPK_LIST_DATA_SET_LOADING = "OPK_LIST_DATA_SET_LOADING";
// Opk Form
export const GET_OPK_FORM_DATA = "GET_OPK_FORM_DATA";
export const SAVE_OPK_FORM_DATA = "SAVE_OPK_FORM_DATA";
export const OPK_FORM_DATA_SET_LOADING = "OPK_FORM_DATA_SET_LOADING";
// Tab1Form
export const GET_TAB1_FORM_DATA = "GET_TAB1_FORM_DATA";
export const SAVE_TAB1_FORM_DATA = "SAVE_TAB1_FORM_DATA";
export const TAB1_FORM_DATA_SET_LOADING = "TAB1_FORM_DATA_SET_LOADING";
// Tab2Form
export const GET_TAB2_FORM_DATA = "GET_TAB2_FORM_DATA";
export const SAVE_TAB2_FORM_DATA = "SAVE_TAB2_FORM_DATA";
export const TAB2_FORM_DATA_SET_LOADING = "TAB2_FORM_DATA_SET_LOADING";
// Tab3Form
export const GET_TAB3_FORM_DATA = "GET_TAB3_FORM_DATA";
export const SAVE_TAB3_FORM_DATA = "SAVE_TAB3_FORM_DATA";
export const TAB3_FORM_DATA_SET_LOADING = "TAB3_FORM_DATA_SET_LOADING";
// Tab4Form
export const GET_TAB4_FORM_DATA = "GET_TAB4_FORM_DATA";
export const SAVE_TAB4_FORM_DATA = "SAVE_TAB4_FORM_DATA";
export const TAB4_FORM_DATA_SET_LOADING = "TAB4_FORM_DATA_SET_LOADING";
// AnalyticsForm
export const GET_ANALYTICS_FORM_DATA = "GET_ANALYTICS_FORM_DATA";
export const SAVE_ANALYTICS_FORM_DATA = "SAVE_ANALYTICS_FORM_DATA";
export const ANALYTICS_FORM_DATA_SET_LOADING =
  "ANALYTICS_FORM_DATA_SET_LOADING";
// Confirm Data
export const CONFIRM_OPK_DATA = "CONFIRM_OPK_DATA";
export const CONFIRM_DATA_SET_LOADING = "CONFIRM_DATA_SET_LOADING";
// OpkUserProfile
export const GET_OPK_USER_PROFILE = "GET_OPK_USER_PROFILE";
export const OPK_USER_PROFILE_SET_LOADING = "OPK_USER_PROFILE_SET_LOADING";
// OpkUserProfile
export const GET_OPK_USER_INFO = "GET_OPK_USER_INFO";
export const OPK_USER_INFO_SET_LOADING = "OPK_USER_INFO_SET_LOADING";
// UPLOAD_XLSX
export const UPLOAD_XLSX = "UPLOAD_XLSX";
export const UPLOAD_XLSX_FAIL = "UPLOAD_XLSX_FAIL";
export const UPLOAD_XLSX_SET_LOADING = "UPLOAD_XLSX_SET_LOADING";
// Check Data
export const CHECK_DATA = "CHECK_DATA";
export const CHECK_DATA_SET_LOADING = "CHECK_DATA_SET_LOADING";
export const CHECK_FAILED = "CHECK_FAILED";
// get Notes
export const GET_NOTES = "GET_NOTES";
export const GET_NOTES_SET_LOADING = "GET_NOTES_SET_LOADING";
export const SAVE_NOTES = "SAVE_NOTES";
// get Files
export const GET_FILES = "GET_FILES";
export const GET_FILES_SET_LOADING = "GET_FILES_SET_LOADING";
// get switch user info
export const GET_SWITCHED_USER_INFO = "GET_SWITCHED_USER_INFO";
export const SWITCHED_USER_INFO_SET_LOADING = "SWITCHED_USER_INFO_SET_LOADING";

// SpecyficData
// sd view controller
export const GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA =
  "GET_SPECIFIC_DATA_VIEW_CONTROLLER_DATA";
export const SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING =
  "SPECIFIC_DATA_VIEW_CONTROLLER_SET_LOADING";
// get Files
export const GET_SD_FORM_FIELDS = "GET_SD_FORM_FIELDS";
export const FK_FORM_FIELDS_LOADING = "FK_FORM_FIELDS_LOADING";
export const GET_FK_FORM_FIELDS = "GET_FK_FORM_FIELDS";
export const GET_SD_FILES = "GET_SD_FILES";
// get report
export const GET_SD_RAPORT_SET_LOADING = "GET_SD_RAPORT_SET_LOADING";
export const GET_SD_RAPORT = "GET_SD_RAPORT";

// sd upload Files
export const UPLOAD_SD_FILES = "UPLOAD_SD_FILES";
export const UPLOAD_SD_FIELDS_SET_LOADING = "UPLOAD_SD_FIELDS_SET_LOADING";
export const UPLOAD_SD_FILES_SET_LOADING = "UPLOAD_SD_FILES_SET_LOADING";
export const UPLOAD_SD_FILES_FAIL = "UPLOAD_SD_FILES_FAIL";

// Confirm sd Data
export const CONFIRM_SD_DATA = "CONFIRM_SD_DATA";
export const CONFIRM_SD_DATA_SET_LOADING = "CONFIRM_SD_DATA_SET_LOADING";

// Panel Dashboard
// participation register
export const GET_ALL_PROCEEDINGS = "GET_ALL_PROCEEDINGS";
export const GET_ALL_PROCEEDINGS_SET_LOADING =
  "GET_ALL_PROCEEDINGS_SET_LOADING";
export const GET_ALL_EXCEL_FILES_WITH_NEW_USERS =
  "GET_ALL_EXCEL_FILES_WITH_NEW_USERS";
export const POST_EXCEL_FILE_WITH_NEW_USERS = "POST_EXCEL_FILE_WITH_NEW_USERS";
export const POST_EXCEL_SET_LOADING = "POST_EXCEL_SET_LOADING";
export const GET_NEW_USERS_SUMMARY = "GET_NEW_USERS_SUMMARY";

//  RestoreForImprovment
export const GET_PARTICIPATION_USERS = "GET_PARTICIPATION_USERS";
export const GET_PARTICIPATION_USERS_SET_LOADING =
  "GET_PARTICIPATION_USERS_SET_LOADING";
//  Instructions
export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SET_LOADING = "GET_FAQ_SET_LOADING";

export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIAL_CATEGORIES = "GET_MATERIAL_CATEGORIES";
export const GET_MATERIALS_SET_LOADING = "GET_FAQ_SET_LOADING";

export const SET_LOADING_REQUEST_STATUS = "SET_LOADING_REQUEST_STATUS";

//Enums for request progress bar
export const getReportRequest = "GET_REPORT_REQUEST";
export const getNewUsersSummaryRequest = "GET_NEW_USERS_SUMMARY_REQUEST";
export const postSpecificDataRequest = "POST_SPECIFIC_DATA_REQUEST";

// AnalyticsAppDAta
export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const SAVE_ANALYTICS_DATA = "SAVE_ANALYTICS_DATA";
export const ANALYTICS_DATA_SET_LOADING = "ANALYTICS_DATA_SET_LOADING";
export const GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING =
  "GET_ALL_EXCEL_FILES_ANALYTICS_SET_LOADING";
export const GET_ALL_EXCEL_FILES_ANALYTICS = "GET_ALL_EXCEL_FILES_ANALYTICS";
export const POST_EXCEL_FILES_ANALYTICS_SET_LOADING =
  "POST_EXCEL_FILES_ANALYTICS_SET_LOADING";
export const POST_EXCEL_FILES_ANALYTICS = "POST_EXCEL_FILES_ANALYTICS";

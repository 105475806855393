import axios from "axios";

import {
  GET_CONTROLLER_DATA,
  VIEW_CONTROLLER_SET_LOADING,
  GET_ERRORS,
} from "./types";

import { configTokenSwitcher } from "./utilActions";

//  GET data
export const getControllerData =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({ type: VIEW_CONTROLLER_SET_LOADING });

      const response = await axios.get(
        `/fk/api/${procedureID}/procedure_controller_api_view/`,
        configTokenSwitcher(getState)
      );
      dispatch({
        type: GET_CONTROLLER_DATA,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ err });
      }
    }
  };

import React, { useState } from "react";
import {
  // Route,
  // Switch,
  useLocation,
} from "react-router-dom";

// import { NavLink } from "react-router-dom";

// reactstrap components
import { Row, Col, Button, Form, Input } from "reactstrap";

import GeneratedForm from "../../components/GeneratedForm/GeneratedForm";
import { SmalModal } from "components/Modal/SmalModal";

export const CostCalculationFormFields = [
  {
    colSize: null,
    fieldData: [
      { fieldId: "id", fieldLabel: "id", fieldType: "hidden", dafault: null },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldcolSize: 6,

        fieldId: "billing_period_year",
        fieldLabel: "Typ Świadczenia",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 6,

        fieldId: "billing_period_month",
        fieldLabel: "ID świadczenia",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 3,
    fieldData: [
      {
        fieldcolSize: 3,

        fieldId: "ilosc_prod",
        fieldLabel: "ID pozycji rozliczeniowej",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "ilosc_prod",
        fieldLabel: "ID kontaktu",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "ilosc_prod",
        fieldLabel: "ID instalacji świadczeniodawcy",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 3,

        fieldId: "ilosc_prod",
        fieldLabel: "ID epizodu",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 5,
    fieldData: [
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Poczatek okresu",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Koniec okresu",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Kod zakresu",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Kod produktu sprawozdawanego",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Kod procedury",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 2,

        fieldId: "ilosc_prod",
        fieldLabel: "Kod rozpoznania głównego",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 2,
    fieldData: [
      {
        fieldcolSize: 4,

        fieldId: "ilosc_prod",
        fieldLabel: "ID pacjenta sprawozdany do NFZ ",
        fieldType: "number",
        dafault: "",
      },
      {
        fieldcolSize: 4,

        fieldId: "ilosc_prod",
        fieldLabel: "Data rozpoczęcia realizacji danego produktu / świadczenia",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 4,

        fieldId: "ilosc_prod",
        fieldLabel: "Data zakończenia realizacji danego produktu / świadczenia",
        fieldType: "number",
        dafault: "",
      },
    ],
  },
  {
    colSize: 1,
    fieldData: [
      {
        fieldcolSize: 6,

        fieldId: "billing_period_year",
        fieldLabel: "Numer rekordu ",
        fieldType: "text",
        dafault: "",
      },
      {
        fieldcolSize: 6,

        fieldId: "billing_period_year",
        fieldLabel: "uwagi",
        fieldType: "text",
        dafault: "",
      },
    ],
  },
];

const CostCalculationForm = () => {
  const location = useLocation();

  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  const analyitics_objects = {};

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  // const checkLocationState = (url) => {
  //   return location.pathname.split("/").pop() === url;
  // };
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
  };

  const saveData = () => {
    console.log();
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper px-4 mx-auto" ref={sidebar}>
          <Button
            // disabled={
            //   switchedUserSDInfo.verifications &&
            //   switchedUserSDInfo.verifications.czy_otwarte === true &&
            //   true
            // }
            size="sm"
            outline
            color="success"
            block
            type="button"
            className={"mt-2"}
            onClick={() => handleModalShow()}
          >
            Prześlij do AOTMiT
          </Button>
          <SmalModal
            show={showModal}
            handleClose={handleModalShow}
            modalBodyText={
              <p className={"text-center"}>
                Czy na pewno chcesz przesłać dane do AOTMiT?
              </p>
            }
            handleAccept={handleAccept}
          />
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <>
          <div className="head-xl-container">
            <h3>Własna kalkulacja kosztów</h3>
            <p>Wprowadzanie danych kliniczno – kosztowych</p>
          </div>

          <main className="main-xl-container">
            <Form className="ml-2">
              <Row>
                <Col>
                  <Input
                    id="exampleFile"
                    className={"mx-auto"}
                    name="file"
                    type="file"
                    label={
                      typeof selectedFile == "string" ||
                      typeof selectedFile == "undefined"
                        ? ""
                        : selectedFile.hasOwnProperty("name.txt")
                        ? selectedFile.name
                        : ""
                    }
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                </Col>
                <Col>
                  <Button
                    outline
                    className={"btn-aotmit"}
                    type="button"
                    // onClick={() => {
                    //   sendExcelWithUsers(
                    //     selectedFile,
                    //     selectedProceeding.value
                    //   );
                    // }}
                  >
                    <i className="fa upload" /> Prześlij plik
                  </Button>
                </Col>
              </Row>
            </Form>

            <GeneratedForm
              opkData={analyitics_objects}
              formFields={CostCalculationFormFields}
              dataSubmit={saveData}
            />
          </main>
        </>
      </div>
    </div>
  );
};

export default CostCalculationForm;

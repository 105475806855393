import React from "react";
import { Switch, Route } from "react-router-dom";
import AnalyticsPrecedureForm from "./AnalyticsPrecedureForm";

const AnalyticsMainPage = () => {
  return (
    <Switch>
      <Route
        exact
        path="/analytics/:procedureID/"
        component={AnalyticsPrecedureForm}
      />
    </Switch>
  );
};

export default AnalyticsMainPage;

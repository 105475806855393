import axios from "axios";

import {
  GET_PARTICIPATION_USERS,
  GET_PARTICIPATION_USERS_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "actions/types.js";

import { authTokenConfig } from "../utilActions";

// get Proceedings
export const getProceedingUsers =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PARTICIPATION_USERS_SET_LOADING,
      });

      const response = await axios.get(
        `/api/restore_for_improvement/${procedureID}`,
        authTokenConfig(getState)
      );

      dispatch({
        type: GET_PARTICIPATION_USERS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

// get Proceedings
export const reatoreForImprovment =
  (procedureID, userID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_PARTICIPATION_USERS_SET_LOADING,
      });

      const response = await axios.post(
        `/api/restore_for_improvement/${procedureID}`,
        { userID },
        authTokenConfig(getState)
      );

      const info = {
        msg: response.data,
        status: response.status,
      };

      dispatch({
        type: GET_SUCCESS,
        payload: info,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

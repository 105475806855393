import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getOpks,
  addOpks,
  confirmOpk,
} from "../../../actions/StaffUserViewsActions/opkAcceptanceActions";

import { getUserInfo } from "../../../actions/OpkUserProfileActions";

// import axios from "axios";
// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

// Components
import { Loading } from "../../../components/Loading/loading";
import { SmalModal } from "../../../components/Modal/SmalModal";
import { Redirect } from "react-router-dom";

import { OpkAkceptacjaFormFields } from "../OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";

// const apiURL = process.env.REACT_APP_API_URL;

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"

const OpkAkceptacja = ({
  dataOPK,
  getOpks,
  addOpks,
  confirmOpk,
  getUserInfo,
  user_info,
  user_info_loading,
  opk_accepted,
  loading,
  match,
}) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const { procedureID, userID } = match.params;

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };
  const handleModalToggler = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    confirmOpk(procedureID, userID);
  };

  // GET_OPEKS
  useEffect(() => {
    getOpks(procedureID, userID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserInfo(userID);
    // eslint-disable-next-line
  }, [userID]);

  // ADD_OPEKS
  const saveData = (submitedFormData) => {
    addOpks(procedureID, userID, submitedFormData);
  };

  if (opk_accepted) return <Redirect to="/fk/lista_do_weryfikacji" />;

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar && (
          <Col className={"p-0"} xs={2} xl={1}>
            <div className={"dropdownMenu"}>
              <Button
                variant="outline-success"
                block
                type="button"
                onClick={handleModalToggler}
              >
                Zatwierdź zmiany
              </Button>
            </div>

            <div className={"switchUserMenu"}>
              <div className={"swd-info"}>
                {!user_info.profile && user_info_loading ? (
                  <Spinner
                    // as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                ) : (
                  user_info.profile &&
                  user_info.profile[0] && (
                    <>
                      <span>Nazwa: </span>
                      <p>{user_info.profile[0].swd_nazwa}</p>
                      <span>Regon: </span>
                      <p>{user_info.username}</p>
                    </>
                  )
                )}
              </div>
            </div>
          </Col>
        )}
        <Col>
          <div className="head-container">
            <h2>Dane finansowo-księgowe</h2>
            <p>
              Żeby zaakceptować świadczeniodawcę, te same OPK powinny być
              zaznaczone/odznaczone przez DA/DPD jako zaakceptowane
            </p>
          </div>
          <main className="main-container">
            {loading ? (
              <Loading variant={"danger"}>
                <span className="m-2">Dane są zapisywane na serwerze...</span>
              </Loading>
            ) : (
              <GeneratedForm
                opkData={dataOPK}
                formFields={OpkAkceptacjaFormFields}
                dataSubmit={saveData}
              />
            )}
          </main>
        </Col>
      </Row>
      <SmalModal
        show={showModal}
        handleClose={handleModalToggler}
        modalBodyText={
          <p className={"text-center"}>Czy na pewno chcesz zatwierdzić dane?</p>
        }
        handleAccept={handleAccept}
      />
    </>
  );
};

OpkAkceptacja.propTypes = {
  dataOPK: PropTypes.array.isRequired,
  getOpks: PropTypes.func.isRequired,
  addOpks: PropTypes.func.isRequired,
  confirmOpk: PropTypes.func.isRequired,
  opk_accepted: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  dataOPK: state.opkAcceptance.opks,
  loading: state.opkAcceptance.loading,
  opk_accepted: state.opkAcceptance.opk_accepted,
  user_info: state.userInfoReducer.user_info,
  user_info_loading: state.userInfoReducer.loading,
});

export default connect(mapStateToProps, {
  getOpks,
  addOpks,
  confirmOpk,
  getUserInfo,
})(OpkAkceptacja);

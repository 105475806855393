import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// actions
import { getSpecificDataViewControllerData } from "actions/SpecificDataActions/SpecificDataViewsControllerActions";
import { getSpecificDataFormFields } from "actions/SpecificDataActions/SD_FormActions";

// reactstrap components
import { Button } from "reactstrap";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import { Loading } from "../../components/Loading/loading";
import CardInfo from "../../components/Cards/CardInfo";

const SpecificDataViewController = ({
  specificDataControllerData,
  specificDataControllerLoading,
  isAuthenticated,
  isSwitchedUser,
  match,
  getSpecificDataViewControllerData,
  getSpecificDataFormFields,
}) => {
  const { procedureID } = match.params;

  useEffect(() => {
    getSpecificDataViewControllerData(procedureID);
    getSpecificDataFormFields(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  if (specificDataControllerLoading) {
    return (
      <Loading variant={"success"}>
        <span className="m-2">Dane są pobierane z serwera...</span>
      </Loading>
    );
  } else {
    if (isSwitchedUser === true) {
      return <Redirect to={`/daneszcz/${procedureID}/specific_data_form`} />;
    } else if (isSwitchedUser === false) {
      if (specificDataControllerData.verification_status === true) {
        return (
          <>
            <CardInfo
              imageHeader={require("../../assets/img/zdjecie_05.jpg")}
              aotmitLogo
              bodyTitle={`
                Dane postępowania ${specificDataControllerData.proceeding.postepowanie_nr} 
                zostały przesłane do weryfikacji
                `}
              description={`
                Czasowo możliwość przeglądania strony została wyłączona, zostaną Poństwo poinformowani 
                drogą mailową o zmianie statusu weryfikacji.
                `}
              footerActions={
                <>
                  <Button
                    className="btn-round"
                    color="success"
                    outline
                    href="/"
                    size="sm"
                  >
                    Strona główna
                  </Button>
                </>
              }
            />
          </>
        );
      } else if (specificDataControllerData.verification_status === false) {
        return (
          <CardInfo
            imageHeader={require("../../assets/img/zdjecie_05.jpg")}
            aotmitLogo
            bodyTitle={`
              Dane postępowania ${specificDataControllerData.proceeding.postepowanie_nr} zostały zatwierdzone
            `}
            description={`
              Nie mają Państwo uprawnień do przeglądania tej strony, możliwość edycji została wyłączona.
            `}
            footerInfo={
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className={"text-success"}
              />
            }
            footerActions={
              <>
                <Button
                  className="btn-round"
                  color="success"
                  outline
                  href="/"
                  size="sm"
                >
                  Strona główna
                </Button>
              </>
            }
          />
        );
      } else if (specificDataControllerData.verification_status === null) {
        return <Redirect to={`/daneszcz/${procedureID}/specific_data_form`} />;
      }
    }
  }
};

const mapStateToProps = (state) => ({
  specificDataControllerData:
    state.specificDataViewControllerReducer.specificDataControllerData,
  specificDataControllerLoading:
    state.specificDataViewControllerReducer.specificDataControllerLoading,
  isAuthenticated: state.authReducer.isAuthenticated,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  form_fields: state.opkFormFieldsReducer.form_fields,
  form_fields_loading: state.opkFormFieldsReducer.loading,
});

export default connect(mapStateToProps, {
  getSpecificDataViewControllerData,
  getSpecificDataFormFields,
})(SpecificDataViewController);

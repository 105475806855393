import {
  GET_OPK_FORM_DATA,
  SAVE_OPK_FORM_DATA,
  CONFIRM_OPK_DATA,
  CONFIRM_DATA_SET_LOADING,
} from "../../../actions/types.js";

const initialState = {
  confirmOpkDataStatus: null,
  opkData: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_FORM_DATA:
      return {
        ...state,
        opkData: action.payload,
        loading: false,
      };
    case SAVE_OPK_FORM_DATA:
      return {
        ...state,
        opkData: action.payload,
        loading: false,
      };
    case CONFIRM_OPK_DATA:
      return {
        ...state,
        confirmOpkDataStatus: action.payload,
        loading: true,
      };
    case CONFIRM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

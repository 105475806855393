import axios from "axios";

import { GET_PROCEEDINGS, LANDING_SET_LOADING, GET_ERRORS } from "./types";

import { configTokenSwitcher } from "./utilActions";

//  GET OPK AKCEPTACJA OPKS
export const getProceedings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LANDING_SET_LOADING,
    });

    const response = await axios.get(
      `/api/user_proceedings/`,
      configTokenSwitcher(getState)
    );
    dispatch({
      type: GET_PROCEEDINGS,
      payload: response.data,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

import React from "react";
import { Switch, Route } from "react-router-dom";

// import PropTypes from "prop-types";

import OpkAkceptacja from "./StaffUserViews/OpkAkceptacja";
import OpkListPage from "./OpkList/OpkListPage";
import OpkNewList from "./OpkNewList";
import OpkViewController from "./OpkViewController";
import OpkListForVerification from "./StaffUserViews/OpkListForVerification";
import OpkUserProfile from "./OpkUserProfile";
import OpkImportXLSX from "./OpkList/OpkImportXLSX";

const OpkMainPage = () => {
  return (
    <>
      <Switch>
        <Route exact path="/fk/user_profile" component={OpkUserProfile} />
        <Route
          exact
          path="/fk/lista_do_weryfikacji"
          component={OpkListForVerification}
        />
        <Route exact path="/fk/:procedureID" component={OpkViewController} />
        <Route
          exact
          path="/fk/:procedureID/upload_xlsx"
          component={OpkImportXLSX}
        />
        <Route exact path="/fk/:procedureID/opk_swd" component={OpkNewList} />
        <Route
          exact
          path="/fk/:procedureID/:userID/opk_akceptacja"
          component={OpkAkceptacja}
        />
        <Route
          exact
          path="/fk/:procedureID/opk/:opkID/*"
          component={OpkListPage}
        />
        <Route path="/fk/:procedureID/opk" component={OpkListPage} />
      </Switch>
    </>
  );
};

OpkMainPage.propTypes = {};

export { OpkMainPage };

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Actions
import {
  getOpksNewList,
  addOpksNewList,
  confirmOpkSwd,
} from "../../actions/opkNewListActions";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// reactstrap components
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Collapse,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

// Components
import SwitchUserMenu from "./StaffUserViews/switchUserMenu";
import { Loading } from "../../components/Loading/loading";
import { SmalModal } from "../../components/Modal/SmalModal";

import { OpkNewListFormFields } from "./OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";

const OpkNewList = ({
  opk_sent_for_improvement,
  opk_accepted,
  opk_rejected,
  opk_confirmed_swd,
  loading,
  isSwitchedUser,
  getOpksNewList,
  addOpksNewList,
  confirmOpkSwd,
  match,
}) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [sideBarBtn, setSideBarBtn] = useState(true);

  // collapse states and functions
  const [collapses, setCollapses] = useState([]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const { procedureID } = match.params;

  useEffect(() => {
    getOpksNewList(procedureID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleDisabeledSideBarButton();
    // eslint-disable-next-line
  }, [opk_sent_for_improvement]);

  const handleSideBarToggler = () => setShowSidebar((prevState) => !prevState);

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
    confirmOpkSwd(procedureID);
  };

  const handleDisabeledSideBarButton = () =>
    setSideBarBtn(() => (opk_sent_for_improvement.length ? false : true));

  const saveData = async (submitedFormData) => {
    addOpksNewList(procedureID, submitedFormData);
  };
  const show_list_opk = (opk_list_to_show, tabName) => {
    if (opk_list_to_show.length) {
      return (
        <>
          <ListGroup flush>
            {opk_list_to_show.map((opk_item, item_idx) => (
              <ListGroupItem
                className={"padding-small font-small"}
                key={item_idx}
              >
                <Row>
                  <Col xs={2}>{opk_item.nr_konta}</Col>
                  <Col xs={4}>{opk_item.nazwa_opk}</Col>
                  <Col>{opk_item.kod_resortowy}</Col>
                  <Col>{opk_item.rok}</Col>
                  <Col className={"text-danger text-center"}>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id={`opk-comments-${opk_item.id}`}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={`opk-comments-${opk_item.id}`}
                    >
                      {opk_item.uwagi}
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
          </ListGroup>
        </>
      );
    }
  };

  if (opk_confirmed_swd) return <Redirect to={`/fk/${procedureID}`} />;

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>
      <Row>
        {showSidebar && (
          <Col className={"p-0"} xs={2} xl={1}>
            <div className={"dropdownMenu"}>
              <Button
                color="success"
                outline
                block
                onClick={handleModalShow}
                disabled={sideBarBtn}
              >
                Prześlij do AOTMiT
              </Button>
            </div>
            {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
          </Col>
        )}
        <Col>
          <div className="head-container">
            <h3>Instrukcja</h3>
            <ul>
              <li>Prosimy o wypełnienie listy OPK</li>
              <li>
                Jeśli liczba pól jest zbyt mała dla Państwa placówki, prosimy o
                skorzystanie z przycisku "Dodaj pola"
              </li>
              <li>Formularz pozwala na wklejanie listy OPK z Excel'a</li>
              <li>
                Po wpisaniu/wklejeniu danych należy kliknąć przycisk ZAPISZ,
                który najduje się na dole strony
              </li>
              <li>
                Po zapisaniu listy OPK prosimy o upewnienie się, czy wszystkie
                dane zostały wpisane poprawnie
              </li>
              <li>
                Jeśli dane zostały wpisane poprawnie, prosimy o kliknięcie
                przycisku PRZEŚLIJ DO AOTMIT w celu weryfikacji listy OPK przez
                pracowników AOTMiT
              </li>
            </ul>
            {/* Accepted OPK */}
            {!loading && opk_accepted.length > 0 && (
              <>
                <Card className="no-transition mb-1">
                  <CardHeader
                    className="card-collapse padding-small"
                    id="rejected-opk"
                    role="tab"
                    aria-expanded={collapses.includes(1)}
                    onClick={(e) => {
                      e.preventDefault();
                      changeCollapse(1);
                    }}
                  >
                    <h6 className="mb-0 panel-title text-aotmit">
                      {"Zaakceptowane konta OPK"}
                    </h6>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(1)}>
                    <CardBody>{show_list_opk(opk_accepted)}</CardBody>
                  </Collapse>
                </Card>
              </>
            )}
            {/* Rejected OPK */}
            {!loading && opk_rejected.length > 0 && (
              <>
                <Card className="no-transition mb-1">
                  <CardHeader
                    className="card-collapse padding-small"
                    id="rejected-opk"
                    role="tab"
                    aria-expanded={collapses.includes(2)}
                    onClick={(e) => {
                      e.preventDefault();
                      changeCollapse(2);
                    }}
                  >
                    <h6 className="mb-0 panel-title text-aotmit">
                      {"Odrzucone konta OPK"}
                    </h6>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(2)}>
                    <CardBody>
                      {show_list_opk(opk_rejected, "Odrzucone konta OPK")}
                    </CardBody>
                  </Collapse>
                </Card>
              </>
            )}
          </div>
          <main className="main-container">
            {loading ? (
              <Loading variant={"danger"}>
                <span className="m-2">Dane są zapisywane na serwerze...</span>
              </Loading>
            ) : (
              <GeneratedForm
                opkData={opk_sent_for_improvement}
                formFields={OpkNewListFormFields}
                dataSubmit={saveData}
              />
            )}
          </main>
        </Col>
      </Row>
      <SmalModal
        show={showModal}
        handleClose={handleModalShow}
        modalBodyText={
          <p className={"text-center"}>
            Czy na pewno chcesz przesłać dane do AOTMiT?
          </p>
        }
        handleAccept={handleAccept}
      />
    </>
  );
};

OpkNewList.propTypes = {
  match: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  opk_sent_for_improvement: state.opkNewList.opk_sent_for_improvement,
  opk_rejected: state.opkNewList.opk_rejected,
  opk_accepted: state.opkNewList.opk_accepted,
  opk_confirmed_swd: state.opkNewList.opk_confirmed_swd,
  loading: state.opkNewList.loading,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
});

export default connect(mapStateToProps, {
  getOpksNewList,
  addOpksNewList,
  confirmOpkSwd,
})(OpkNewList);

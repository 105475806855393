import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOpkData } from "../../../actions/opkListActions";
import { useHistory } from "react-router-dom";

import { uploadXlsx } from "../../../actions/OpkFormsActions/opkUploadXLSXActions";

// React Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faCog,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";

// components
import { Loading } from "../../../components/Loading/loading";
import SwitchUserMenu from "../StaffUserViews/switchUserMenu";

const OpkImportXLSX = ({
  proceeding,
  opk_list,
  isSwitchedUser,
  match,
  getOpkData,
  uploadXlsx,
  loading_xlsx,
}) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [fileName, setFileName] = useState("Wgraj plik");
  const [uploadedFile, setUploadedFile] = useState(null);

  const { procedureID } = match.params;
  const history = useHistory();

  const handleSideBarToggler = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const handleFileInput = (file) => {
    setUploadedFile(file);
    setFileName(file.name);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("xls_upload", uploadedFile);

    uploadXlsx(procedureID, data);
    // dataSubmit(formData);
  };

  useEffect(() => {
    getOpkData(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  return (
    <>
      <div className={"fixedPlugin"} onClick={handleSideBarToggler}>
        <FontAwesomeIcon icon={faCog} size="2x" />
      </div>

      <Row>
        {showSidebar && (
          <Col className={"p-0"} xs={2} xl={1}>
            <div className={"dropdownMenu"}>
              <Button
                size="sm"
                variant="outline-success"
                block
                type="button"
                className={"btn-aotmit"}
                onClick={() => history.goBack()}
              >
                Powrót
              </Button>
            </div>
            {isSwitchedUser && <SwitchUserMenu procedureID={procedureID} />}
          </Col>
        )}
        <Col>
          <div className="head-container">
            <h3>
              {proceeding &&
                `Postepowanie nr: ${proceeding.postepowanie_nr}, ${proceeding.postepowanie_rok}`}
            </h3>
            <>
              {opk_list && opk_list.length !== 0 ? (
                <p>Liczba kont OPK: {opk_list.length}</p>
              ) : (
                <div className={"border-box"}>
                  <div className={"border-box-title text-warning"}>
                    <FontAwesomeIcon icon={faInfo} size="1x" />
                  </div>
                  <div className={"border-box-content"}>
                    <h6>Nie dodano jeszcze kont OPK w tym Postępowaniu</h6>
                  </div>
                </div>
              )}
            </>
          </div>
          <main className="main-container">
            <div className={"border-box"}>
              <div className={"border-box-title text-warning"}>
                UWAGA <FontAwesomeIcon icon={faExclamation} size="1x" />
              </div>
              <div className={"border-box-content"}>
                <ul>
                  <li>
                    Podczas wgrywania danych z pliku EXCEL wszytkie dotychczas
                    wgrane dane zostaną usunięte!
                  </li>
                  <li>
                    Wgrywany arkusz powinien byc stworzony na podstawie arkusza
                    bazowego, ktory znajduje sie pod przyciskiem POBIERZ WZÓR FK
                  </li>
                  {/* Opcja sluzy do wgrania wypelnionego pliku Excel. Wzór powinni Państwo pobrać kilkając w przycisk POBIERZ WZÓR FK.  */}
                  <li>
                    Przed wgraniem pliku prosimy o upewnienie się czy w nazwie
                    pliku Excel nie ma polskich znaków, ponieważ może to
                    powodować błędy.
                  </li>
                </ul>
              </div>
            </div>
            {loading_xlsx ? (
              <Loading variant={"success"}>
                <span className="m-2">Dane są zapisywane na serwerze...</span>
              </Loading>
            ) : (
              <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.File
                  type="file"
                  className={"w-50 m-2"}
                  id="inputGroupFile01"
                  label={fileName}
                  data-browse="Wybierz plik"
                  onChange={(e) => handleFileInput(e.target.files[0])}
                  custom
                />
                <Button
                  type="submit"
                  variant={"outline-success"}
                  className={"mx-5"}
                >
                  Zapisz
                </Button>
              </Form>
            )}
          </main>
        </Col>
      </Row>
    </>
  );
};

OpkImportXLSX.propTypes = {
  proceeding: PropTypes.object,
  opk_list: PropTypes.array,
  loading_xlsx: PropTypes.bool.isRequired,
  getOpkData: PropTypes.func.isRequired,
  uploadXlsx: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  proceeding: state.opkListReducer.data.proceeding,
  opk_list: state.opkListReducer.data.opk_list,
  loading_xlsx: state.opkUploadXLSXReducer.loading,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
});

export default connect(mapStateToProps, { getOpkData, uploadXlsx })(
  OpkImportXLSX
);

import axios from "axios";

import {
  GET_SWITCHED_USER_INFO,
  SWITCHED_USER_INFO_SET_LOADING,
  // GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { switchUserTokenConfig } from "../utilActions";

//  GET_OPK_USER_PROFILE
export const getSwitchedUserInfo =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({ type: SWITCHED_USER_INFO_SET_LOADING });

      const response = await axios.get(
        `/fk/api/switched_user_info/${procedureID}`,
        switchUserTokenConfig(getState)
      );

      dispatch({
        type: GET_SWITCHED_USER_INFO,
        payload: response.data,
      });
    } catch (err) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    }
  };

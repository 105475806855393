import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Form,
  FormText,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip, Progress,
} from "reactstrap";

import {
  getAllProceedings,
  getAllExcelFilesWithNewUsers,
  postExeclFileWithNewUsers,
  getExcelsTemplate,
  getExcelsSummary,
} from "actions/PanelDashboardActions/ParticipationRegisterActions";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { saveAs } from "file-saver";
import Spinner from "react-bootstrap/Spinner";
import {getNewUsersSummaryRequest} from "../../actions/types";

// ];

const ParticipationRegister = ({
  all_proceedings,
  loading,
  loading_post_excel,
  getAllProceedings,
  postExeclFileWithNewUsers,
  getAllExcelFilesWithNewUsers,
  excel_file_list,
  getExcelsTemplate,
  getExcelsSummary,
    alreadyLoaded,
    totalSize,
    requestType,  
}) => {
  const [selectedProceeding, setSelectedProceeding] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    getAllProceedings();
    // eslint-disable-next-line
  }, []);

  const setOptions = (list) => {
    const newlist = list.map((object) => ({
      value: object.id,
      label: `${object.postepowanie_nr} ${object.typ_danych} - ${object.postepowanie_rok}`,
    }));
    return newlist;
  };

  const sendExcelWithUsers = (file, procedureID) => {
    postExeclFileWithNewUsers(file, procedureID);
    setSelectedFile("");
  };
  const downloadFile = (file, filename) => {
    const byte_chars = [...atob(file)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    console.log(byte_array);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename);
  };

  return (
    <>
      <div className="head-xl-container">
        <h3>Dodawanie nowych kont oraz uczestnictwa</h3>
        <p>
          Poniżej znajduje się lista postępowań do których otrzymali Państwo
          dostęp.
        </p>
      </div>

      <main className="main-xl-container">
        <Row className={"mb-4"}>
          <Col sm={4}>
            {!loading && (
              <>
                <Select
                  className="react-select primary mt-2"
                  classNamePrefix="react-select"
                  name="proceeding_select"
                  value={selectedProceeding}
                  onChange={(value) => {
                    setSelectedProceeding(value);
                    getAllExcelFilesWithNewUsers(value.value);
                  }}
                  options={setOptions(all_proceedings)}
                  placeholder="Wybierz Postępowanie"
                />
                <FormText>
                  {
                    "Wybierz postępowanie aby wgrać plik z danymi świadczeniodawców w nim uczestniczących"
                  }
                </FormText>
                {selectedProceeding && (
                  <>
                    <Form className={"mt-3"}>
                      <Input
                        id="exampleFile"
                        name="file"
                        type="file"
                        label={
                          typeof selectedFile == "string" ||
                          typeof selectedFile == "undefined"
                            ? ""
                            : selectedFile.hasOwnProperty("name.txt")
                            ? selectedFile.name
                            : ""
                        }
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                      />
                    </Form>
                    <div className="mt-2  justify-content-end">
                      <Button
                        className={"mt-3"}
                        onClick={() => {
                          getExcelsSummary(selectedProceeding.value);
                        }}
                      >
                        {requestType===getNewUsersSummaryRequest && totalSize ? "Trwa pobieranie Podsumowanie" :"Pobierz Podsumowanie" }
                        {requestType===getNewUsersSummaryRequest && totalSize && <Progress value={alreadyLoaded}/>}
                      </Button>
                      <Button
                        className={"mt-3"}
                        onClick={() => {
                          getExcelsTemplate();
                        }}
                      >
                        Szablon pliku
                      </Button>
                    </div>
                  </>
                )}
                {selectedFile && (
                  <div className="mt-2 align-items-end justify-content-end">
                    <Button
                      className="px-2 ml-auto"
                      //   block
                      color="info"
                      data-toggle="tooltip"
                      id="tooltip542628903"
                      size="sm"
                      type="button"
                      onClick={() => {
                        sendExcelWithUsers(
                          selectedFile,
                          selectedProceeding.value
                        );
                      }}
                    >
                      <i className="fa upload" /> Prześlij plik
                    </Button>
                  </div>
                )}
              </>
            )}
          </Col>
          <Col>
            {loading_post_excel ? (
              <Spinner
                // as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                variant="info"
              />
            ) : (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Nazwa pliku</th>
                    <th>Czy import się powiódł?</th>
                    <th>Liczba dodanych użytkowników</th>
                    <th>Użytkownicy z ponownie nadanym dostępem</th>
                    <th>
                      Liczba użytkowników których nie udało się zaimportować
                    </th>
                    <th>Błąd importu</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {excel_file_list.map((excelFile, row_nr) => (
                    <tr key={row_nr}>
                      <td className="text-center">{row_nr + 1}</td>
                      <td>{excelFile.filename}</td>
                      <td>
                        {excelFile.bledy === "" ? (
                          <i className="fas fa-check-circle" />
                        ) : (
                          <i className="fas fa-times-circle" />
                        )}
                      </td>
                      <td>{excelFile.liczba_nowych_userow}</td>
                      <td>{excelFile.liczba_ponownie_ndanych_dostepow}</td>
                      <td className="text-danger">{excelFile.liczba_bledow}</td>
                      <td>{excelFile.bledy}</td>
                      <td className=" td-actions text-right">
                        <Button
                          className="px-2"
                          //   block
                          color="info"
                          data-toggle="tooltip"
                          id="tooltip542628903"
                          size="sm"
                          type="button"
                          onClick={() => {
                            downloadFile(excelFile.excel, excelFile.filename);
                          }}
                        >
                          <i className="fa fa-user" /> Pobierz plik
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target="tooltip542628903"
                        >
                          Plik przesłany : data
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  all_proceedings: state.participationRegisterReducer.all_proceedings,
  loading: state.participationRegisterReducer.loading,
  excel_file_list: state.participationRegisterReducer.excel_file_list,
  loading_post_excel: state.participationRegisterReducer.loading_post_excel,
  alreadyLoaded: state.utilityReducer.alreadyLoaded,
  totalSize: state.utilityReducer.totalSize,
  requestType: state.utilityReducer.requestType,
});

export default connect(mapStateToProps, {
  getAllProceedings,
  postExeclFileWithNewUsers,
  getAllExcelFilesWithNewUsers,
  getExcelsTemplate,
  getExcelsSummary,
})(ParticipationRegister);

import React, { useEffect } from "react";
import { connect } from "react-redux";

// reactstrap components
import { ListGroup, ListGroupItem } from "reactstrap";

import { getMaterials } from "actions/PanelInstructionsActions/MaterialsActions";

const SlagSelectedFiles = ({ material_list, match, getMaterials }) => {
  const { slug } = match.params;

  useEffect(() => {
    getMaterials(slug);
    // eslint-disable-next-line
  }, [slug]);

  return (
    <>
      <ListGroup flush>
        {material_list.map((file_item, file_idx) => {
          return (
            <ListGroupItem>
              <a
                className={"text-muted"}
                href={file_item.plik}
              >{`${file_item.nazwa} - ${file_item.opis}`}</a>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  material_list: state.materialsReducer.material_list,
  loading: state.materialsReducer.loading,
});

export default connect(mapStateToProps, { getMaterials })(SlagSelectedFiles);

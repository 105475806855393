import React from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

const Loading = ({ children, variant }) => {
  return (
    <div className={"flex-center"}>
      <Card
        border="secondary"
        className="text-center w-25 shadow-sm"
      >
        <Card.Header>
          <Spinner
            // as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant={variant}
          />
        </Card.Header>
        {children}
      </Card>
    </div>
  );
};

export { Loading };

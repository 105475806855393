import axios from "axios";

import {
  GET_OPKS_NEW_LIST,
  ADD_OPKS_NEW_LIST,
  CONFIRM_OPKS_SWD,
  OPKS_NEW_LIST_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "./types";

import { configTokenSwitcher } from "./utilActions";

//  GET OPK AKCEPTACJA OPKS
export const getOpksNewList = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPKS_NEW_LIST_SET_LOADING });

    const response = await axios.get(
      `/fk/api/${procedureID}/opk_swd`,
      configTokenSwitcher(getState)
    );

    dispatch({
      type: GET_OPKS_NEW_LIST,
      payload: response.data,
    });
  } catch (err) {
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};
// ADD OPK AKCEPTACJA OPKS
export const addOpksNewList =
  (procedureID, submitedFormData) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPKS_NEW_LIST_SET_LOADING });
      const response = await axios.post(
        `/fk/api/${procedureID}/opk_swd`,
        submitedFormData,
        configTokenSwitcher(getState)
      );
      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });

      // return data
      dispatch({
        type: ADD_OPKS_NEW_LIST,
        payload: response.data,
      });
    } catch (err) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    }
  };
//  CONFIRM OPKS SWD
export const confirmOpkSwd = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPKS_NEW_LIST_SET_LOADING });
    const response = await axios.get(
      `/fk/api/${procedureID}/confirm_opks_swd`,
      configTokenSwitcher(getState)
    );
    dispatch({
      type: CONFIRM_OPKS_SWD,
      payload: response.status,
    });

    dispatch({
      type: GET_SUCCESS,
      payload: {
        msg: "Numery kont OPK zostały przesłane do AOTMiT",
        status: response.status,
      },
    });
  } catch (err) {
    console.log(err.response, err.response.data);

    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};

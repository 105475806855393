import { combineReducers } from "redux";
import authReducer from "./authReducer";
import switchUserReducer from "./switchUserReducer";
import alertsReducer from "./alertsReducer";
import utilityReducer from "./utilityReducer";

// containres Reducers
import LandingPageReducer from "./MainReducers/LandingPageReducer";
import OpkViewControllerReducer from "./OpkReducers/OpkViewControllerReducer";
import OpkNewListReducer from "./OpkReducers/OpkNewListReducer";

// OPK Reducers
import OpkListForVerification from "./OpkReducers/StaffUserViewsReducers/OpkListForVerificationReducer";
import OpkAcceptanceReducer from "./OpkReducers/StaffUserViewsReducers/OpkAcceptanceReducer";

import OpkListReducer from "./OpkReducers/OpkListReducers/OpkListReducer";
import OpkFormFieldsReducer from "./OpkReducers/OpkListReducers/OpkFormFieldsReducer";
import OpkFormReducer from "./OpkReducers/OpkListReducers/OpkFormReducer";
import Tab1FormReducer from "./OpkReducers/OpkListReducers/Tab1FormReducer";
import Tab2FormReducer from "./OpkReducers/OpkListReducers/Tab2FormReducer";
import Tab3FormReducer from "./OpkReducers/OpkListReducers/Tab3FormReducer";
import Tab4FormReducer from "./OpkReducers/OpkListReducers/Tab4FormReducer";
import AnalyticsFormReducer from "./OpkReducers/OpkListReducers/AnalyticsFormReducer";
import OpkConfirmDataReducer from "./OpkReducers/OpkListReducers/OpkConfirmDataReducer";

import UserInfoReducer from "./OpkReducers/UserInfoReducer";
import SwitchedUserInfoReducer from "./OpkReducers/StaffUserViewsReducers/SwitchedUserInfoReducer";
import OpkUserProfileReducer from "./OpkReducers/OpkUserProfileReducer";
import OpkUploadXLSXReducer from "./OpkReducers/OpkListReducers/OpkUploadXLSXReducer";
import OpkCheckDataReducer from "./OpkReducers/OpkListReducers/OpkCheckDataReducer";
import NotesReducer from "./OpkReducers/StaffUserViewsReducers/NotesReducer";
import FilesReducer from "./OpkReducers/StaffUserViewsReducers/FilesReducer";

// Specyfic Data Reducers
import SpecificDataConfirmDataReducer from "./SpecificDataReducers/SpecificDataConfirmDataReducer";
import SD_FormReducer from "./SpecificDataReducers/SD_FormReducer";
import SpecificDataViewControllerReducer from "./SpecificDataReducers/SpecificDataViewControllerReducer";

// Panel Reducers
import ParticipationRegisterReducer from "./PanelDashboardReducers/ParticipationRegisterReducer";
import RestoreForImprovmentReducer from "./PanelDashboardReducers/RestoreForImprovmentReducer";

// Instructions Reducers
import FAQReducer from "./InstructionsReducer/FAQReducer";
import MaterialsReducer from "./InstructionsReducer/MaterialsReducer";
import AnalyticsDataReducer from "./AnalyticsReducers/AnalyticsDataReducer";

export default combineReducers({
  utilityReducer: utilityReducer,
  authReducer: authReducer,
  switchUserReducer: switchUserReducer,
  alertsReducer: alertsReducer,

  landingPageReducer: LandingPageReducer,
  opkViewControllerReducer: OpkViewControllerReducer,
  opkNewList: OpkNewListReducer,

  opkListForVerification: OpkListForVerification,
  opkAcceptance: OpkAcceptanceReducer,

  opkListReducer: OpkListReducer,
  opkFormFieldsReducer: OpkFormFieldsReducer,
  opkFormReducer: OpkFormReducer,
  tab1FormReducer: Tab1FormReducer,
  tab2FormReducer: Tab2FormReducer,
  tab3FormReducer: Tab3FormReducer,
  tab4FormReducer: Tab4FormReducer,
  analyticsFormReducer: AnalyticsFormReducer,
  opkConfirmDataReducer: OpkConfirmDataReducer,

  userInfoReducer: UserInfoReducer,
  switchedUserInfoReducer: SwitchedUserInfoReducer,
  opkUserProfileReducer: OpkUserProfileReducer,
  opkUploadXLSXReducer: OpkUploadXLSXReducer,
  opkCheckDataReducer: OpkCheckDataReducer,
  notesReducer: NotesReducer,
  filesReducer: FilesReducer,

  // Specific Data
  specificDataConfirmDataReducer: SpecificDataConfirmDataReducer,
  sd_FormReducer: SD_FormReducer,
  specificDataViewControllerReducer: SpecificDataViewControllerReducer,

  // panel Dashboard
  participationRegisterReducer: ParticipationRegisterReducer,
  restoreForImprovmentReducer: RestoreForImprovmentReducer,
  // instructions
  faqReducer: FAQReducer,
  materialsReducer: MaterialsReducer,
  // Analytics app reducers
  analyticsDataReducer: AnalyticsDataReducer,
});

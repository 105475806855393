import { GET_ANALYTICS_FORM_DATA, SAVE_ANALYTICS_FORM_DATA, ANALYTICS_FORM_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  AnalyticsData: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS_FORM_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        loading: false
      };
    case SAVE_ANALYTICS_FORM_DATA:
      return {
        ...state,
        AnalyticsData: action.payload,
        loading: false
      };
    case ANALYTICS_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
import { GET_OPK_LIST_DATA, OPK_LIST_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  data: [],
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_LIST_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false
      };

    case OPK_LIST_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
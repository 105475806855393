import axios from "axios";

import {
  GET_TAB4_FORM_DATA,
  SAVE_TAB4_FORM_DATA,
  TAB4_FORM_DATA_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types";

import { configTokenSwitcher } from "../utilActions";

//  GET Tab1 data
export const getTab4FormData =
  (procedureID, opkID) => async (dispatch, getState) => {
    try {
      dispatch({ type: TAB4_FORM_DATA_SET_LOADING });

      const response = await axios.get(
        `/fk/api/${procedureID}/opk/${opkID}/tab4edit/`,
        configTokenSwitcher(getState)
      );
      dispatch({
        type: GET_TAB4_FORM_DATA,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        console.log("tab4", err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ err });
      }
    }
  };
// ADD tab1 OPKS
export const saveTab4FormData =
  (procedureID, opkID, submitedFormData) => async (dispatch, getState) => {
    dispatch({ type: TAB4_FORM_DATA_SET_LOADING });
    try {
      const response = await axios.post(
        `/fk/api/${procedureID}/opk/${opkID}/tab4edit/`,
        submitedFormData,
        configTokenSwitcher(getState)
      );
      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });

      // return data
      dispatch({
        type: SAVE_TAB4_FORM_DATA,
        payload: response.data,
      });
    } catch (err) {
      console.log(err.response, err.response.data);
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    }
  };

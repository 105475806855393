import {
  GET_OPKS_NEW_LIST,
  ADD_OPKS_NEW_LIST,
  CONFIRM_OPKS_SWD,
  OPKS_NEW_LIST_SET_LOADING,
} from "../../actions/types.js";

const initialState = {
  opks: [],
  opk_accepted: [],
  opk_rejected: [],
  opk_sent_for_improvement: [],
  error: null,
  opk_confirmed_swd: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPKS_NEW_LIST:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case ADD_OPKS_NEW_LIST:
      console.log("add_opeks", action.payload);
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case CONFIRM_OPKS_SWD:
      let opk_confirmed_status = false;
      if (action.payload === 200) {
        opk_confirmed_status = true;
      }
      return {
        ...state,
        opk_confirmed_swd: opk_confirmed_status,
        loading: false,
      };
    case OPKS_NEW_LIST_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

import React from "react";
// import PropTypes from "prop-types";
import { Route, Switch, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ParticipationRaport from "./ParticipationRaport";
import ParticipationRegister from "./ParticipationRegister";

import RestoreForImprovment from "./RestoreForImprovment";

const DashboardMainPage = (props) => {
  // const [sidebarMini, setSidebarMini] = React.useState(false);
  const location = useLocation();

  const mainPanel = React.useRef();
  const sidebar = React.useRef();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  const checkLocationState = (url) => {
    return location.pathname.split("/").pop() === url;
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper" ref={sidebar}>
          <ul className="nav">
            <li
              className={
                checkLocationState("przywracanie_do_poprawy") ? "active" : ""
              }
            >
              <NavLink to={"/panel/przywracanie_do_poprawy"}>
                <>
                  {/* <span className="sidebar-mini-icon">{"B"}</span> */}
                  <span className="sidebar-normal">
                    Przywracanie do poprawy
                  </span>
                </>
              </NavLink>
            </li>
            <li className={checkLocationState("nowe_konta") ? "active" : ""}>
              <NavLink to={"/panel/nowe_konta"}>
                <>
                  {/* <span className="sidebar-mini-icon">{"B"}</span> */}
                  <span className="sidebar-normal">Nowe konta</span>
                </>
              </NavLink>
            </li>
            <li
              className={
                checkLocationState("raport_postepowania") ? "active" : ""
              }
            >
              <NavLink to={"/panel/raport_postepowania"}>
                <>
                  {/* <span className="sidebar-mini-icon">{"B"}</span> */}
                  <span className="sidebar-normal">Raport postepowania</span>
                </>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <Switch>
          <Route
            exact
            path="/panel/przywracanie_do_poprawy"
            component={RestoreForImprovment}
          />
          <Route
            exact
            path="/panel/nowe_konta"
            component={ParticipationRegister}
          />
          <Route
            exact
            path="/panel/raport_postepowania"
            component={ParticipationRaport}
          />
        </Switch>
      </div>
    </div>
  );
};

DashboardMainPage.propTypes = {};

export default DashboardMainPage;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getTab3FormData,
  saveTab3FormData,
} from "../../../actions/OpkFormsActions/tab3FormActions";

import { Loading } from "../../../components/Loading/loading";
import { Tab3FormFields } from "../OpkForms/FormSettings";
import GeneratedForm from "components/GeneratedForm/GeneratedForm";

const Tab3Form = ({
  tab3_objects,
  loading,
  getTab3FormData,
  saveTab3FormData,
  match,
}) => {
  const { procedureID, opkID } = match.params;

  useEffect(() => {
    getTab3FormData(procedureID, opkID);
    // eslint-disable-next-line
  }, [procedureID, opkID]);

  const saveData = async (submitedFormData) => {
    saveTab3FormData(procedureID, opkID, submitedFormData);
  };

  return (
    <>
      {loading ? (
        <Loading variant={"danger"}>
          <span className="m-2">Dane są zapisywane na serwerze...</span>
        </Loading>
      ) : (
        <GeneratedForm
          opkData={tab3_objects}
          formFields={Tab3FormFields}
          dataSubmit={saveData}
        />
      )}
    </>
  );
};

Tab3Form.propTypes = {
  tab3_objects: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  getTab3FormData: PropTypes.func.isRequired,
  saveTab3FormData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  tab3_objects: state.tab3FormReducer.Tab3Data.tab3_objects,
  loading: state.tab3FormReducer.loading,
});

export default connect(mapStateToProps, { getTab3FormData, saveTab3FormData })(
  Tab3Form
);

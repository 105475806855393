import { CHECK_DATA, CHECK_DATA_SET_LOADING, CHECK_FAILED } from '../../../actions/types.js'

const initialState = {
  data_checked: [],
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DATA:
      return {
        ...state,
        data_checked: action.payload,
        loading: false
      };
    case CHECK_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case CHECK_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
import {
  UPLOAD_XLSX,
  UPLOAD_XLSX_FAIL,
  UPLOAD_XLSX_SET_LOADING,
} from "../../../actions/types.js";

const initialState = {
  uploadedFile: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_XLSX:
      return {
        ...state,
        uploadedFile: action.payload,
        loading: false,
      };
    case UPLOAD_XLSX_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_XLSX_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

import React from "react";

// react components for routing our app without refres
import { Switch, Route } from "react-router-dom";

import Login from "../Registration/Login";
import Logout from "./Logout";
import SwitchUser from "./SwitchUser";

const RegistrationPage = () => {

    return (
        <>
            <Switch>
                <Route path='/registration/login' component={Login} />
                <Route path='/registration/switchUser' component={SwitchUser} />
                <Route path='/registration/logout' component={Logout} />
            </Switch>
        </>
    );
};

export { RegistrationPage };

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Link } from "react-router-dom";

import { getMaterialCategory } from "actions/PanelInstructionsActions/MaterialsActions";

// reactstrap components
import { Row, Col, Button, Card, CardBody } from "reactstrap";

import SlagSelectedFiles from "./SlagSelectedFiles";

const ProceedingMaterials = ({
  material_categories,
  loading,
  getMaterialCategory,
}) => {
  useEffect(() => {
    getMaterialCategory();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="head-xl-container">
        {!loading && (
          <Card>
            <CardBody className={"m-1 p-2"}>
              <Row>
                {!loading &&
                  material_categories.map((category, category_idx) => {
                    return (
                      <Col sm={12} md={4} lg={2} key={category_idx}>
                        <Link
                          to={`/instrukcja/materialy/${category.slug}`}
                          className={`nav-link p-0`}
                        >
                          <Button
                            block
                            className={"mt-1"}
                            outline
                            type="button"
                          >
                            <h6>{category.nazwa}</h6>
                          </Button>
                        </Link>
                      </Col>
                    );
                  })}
              </Row>
            </CardBody>
          </Card>
        )}
      </div>

      <main className="main-xl-container">
        <Route
          path={`/instrukcja/materialy/:slug`}
          render={(props) => <SlagSelectedFiles loading={loading} {...props} />}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  material_categories: state.materialsReducer.material_categories,
  loading: state.materialsReducer.loading,
});

export default connect(mapStateToProps, { getMaterialCategory })(
  ProceedingMaterials
);

import {
  SET_LOADING,
  SET_LOADING_REQUEST_STATUS,
  // SWITCH_USER_FAIL,
  // SWITCH_USER_SUCCESS
} from "./types";
// import axios from "axios";
// import { loadSwichedUser } from "./switchUserActions";

// set Loading to true
const setLoading = () => ({ type: SET_LOADING });

export { setLoading };

// Setup config for auth user with token - helper Function
export const authTokenConfig = (getState) => {
  const token = getState().authReducer.auth_token;

  const config = {
    headers: {
      // "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

// Setup config for switch user with token - helper Function
export const switchUserTokenConfig = (getState) => {
  const token = getState().switchUserReducer.switch_user_token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

// Setup config for switch user if it exist else auth token - helper Function
export const configTokenSwitcher = (getState) => {
  let token = getState().authReducer.auth_token;

  if (getState().switchUserReducer.switch_user_token) {
    token = getState().switchUserReducer.switch_user_token;
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

// Geting File config

// Setup config for auth user with token  to get file - helper Function
export const authFileTokenConfig = (getState) => {
  const token = getState().authReducer.auth_token;

  const config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};

// Setup config for switch user if it exist else auth token to get file - helper Function
export const configFileTokenSwitcher = (getState) => {
  let token = getState().authReducer.auth_token;

  if (getState().switchUserReducer.switch_user_token) {
    token = getState().switchUserReducer.switch_user_token;
  }

  const config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};


export const setRequestStatus = (alreadyLoaded,totalSize,requestType) => {
    return {
      type: SET_LOADING_REQUEST_STATUS,
      payload: {alreadyLoaded: alreadyLoaded,
                totalSize: totalSize,
                requestType:requestType},
    };

};
import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { logoutUser } from "../../actions/auth";

import Nav from "react-bootstrap/Nav";
// import Button from "react-bootstrap/Button";

// reactstrap components
import {
  Button,
  // Nav,
  UncontrolledTooltip,
} from "reactstrap";

const LinksControler = ({
  isAuthenticated,
  isSwitchedUser,
  user,
  switchedUser,
  logoutUser,
}) => {
  let history = useHistory();
  const handleLogout = () => {
    if (isSwitchedUser === true) {
      history.push("/registration/logout");
    } else {
      logoutUser();
    }
  };

  const staffAuthUser = (loggedUser) => (
    <>
      <Link to={`/`} className={"nav-link"}>
        <p>Strona główna</p>
      </Link>
      {/* <Nav.Link href={`/fk/1/lista_do_weryfikacji`}><p>Akceptacja OPK</p></Nav.Link> */}
      <Link to="/fk/lista_do_weryfikacji" className={"nav-link"}>
        <p>Akceptacja OPK</p>
      </Link>
      <Link to={`/panel/przywracanie_do_poprawy`} className={"nav-link"}>
        <p>Panel Pomocniczy</p>
      </Link>
      <Nav.Link href={`/instrukcja/materialy`}>
        <p>Instrukcje</p>
      </Nav.Link>
      {loggedUser.can_switch_user && (
        <Link to="/registration/switchUser" className={"nav-link"}>
          <Button
            id="switch-user-btn"
            className="btn-border btn-round"
            color="info"
            outline
            size="sm"
            type="button"
          >
            <i className="fas fa-exchange-alt"></i>
          </Button>
          <UncontrolledTooltip
            delay={0}
            placement="bottom"
            target="switch-user-btn"
          >
            Przełącz użytkownika
          </UncontrolledTooltip>
        </Link>
      )}
      <Nav.Link className={"nav-link"}>
        <Button
          id="logout-btn"
          className="btn-border btn-round"
          color="danger"
          outline
          size="sm"
          type="button"
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt"></i>
        </Button>
        <UncontrolledTooltip delay={0} placement="bottom" target="logout-btn">
          Wyloguj
        </UncontrolledTooltip>
      </Nav.Link>
      <Nav.Link href={`/admin/`}>
        <Button
          id="admin-btn"
          className="btn-border btn-round"
          color="secondary"
          outline
          size="sm"
          type="button"
        >
          <i className="far fa-user"></i>
        </Button>
        <UncontrolledTooltip delay={0} placement="bottom" target="admin-btn">
          Panel Administratora
        </UncontrolledTooltip>
      </Nav.Link>
    </>
  );

  const notStaffAuthUser = (
    <>
      <Link to={`/`} className={"nav-link"}>
        <p>Strona główna</p>
      </Link>
      <Nav.Link href={`/instrukcja/pliki`}>
        <p>Instrukcje</p>
      </Nav.Link>
      <Nav.Link className={"nav-link"}>
        <Button
          id="logout-btn"
          className="btn-border btn-round"
          color="danger"
          outline
          size="sm"
          type="button"
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt"></i>
        </Button>
        <UncontrolledTooltip delay={0} placement="bottom" target="logout-btn">
          Wyloguj
        </UncontrolledTooltip>
      </Nav.Link>
    </>
  );

  const nonAuthLinks = (
    <>
      <Nav.Link href={`/instrukcja/pliki`}>
        <p>Instrukcje</p>
      </Nav.Link>
      <Link to="/registration/login" className={"nav-link"}>
        <Button
          id="login-btn"
          className="btn-border btn-round"
          color="success"
          outline
          size="sm"
          type="button"
        >
          <i className="fas fa-sign-in-alt"></i>
        </Button>
        <UncontrolledTooltip delay={0} placement="bottom" target="login-btn">
          Zaloguj
        </UncontrolledTooltip>
      </Link>
    </>
  );

  if (isAuthenticated === true) {
    let loggedUser = { ...user };

    // User is swithed
    if (isSwitchedUser === true) {
      loggedUser = { ...switchedUser };
    }

    // Logged User is staff
    if (loggedUser.is_staff === true) {
      return staffAuthUser(loggedUser);
      // Logged User is not staff member
    } else if (loggedUser.is_staff !== true) {
      return notStaffAuthUser;
      // loggeduser does not exist
    } else {
      return nonAuthLinks;
    }

    // User is not Authenticated
  } else {
    return nonAuthLinks;
  }
};

LinksControler.prototype = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  isSwitchedUser: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user: state.authReducer.user,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  switchedUser: state.switchUserReducer.switchedUser,
});

export default connect(mapStateToProps, { logoutUser })(LinksControler);

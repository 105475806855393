import axios from "axios";

import { GET_FAQ, GET_FAQ_SET_LOADING, GET_ERRORS } from "actions/types.js";

import { authTokenConfig } from "../utilActions";

// get Proceedings
export const getFAQ = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_FAQ_SET_LOADING,
    });

    const response = await axios.get(
      `/instrukcja/api/faq`,
      authTokenConfig(getState)
    );

    dispatch({
      type: GET_FAQ,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

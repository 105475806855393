import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// reactstrap components
import { Row, Col, Button, Card, CardBody, Spinner } from "reactstrap";

import Select from "react-select";

import { getAllProceedings } from "actions/PanelDashboardActions/ParticipationRegisterActions";
import {
  getProceedingUsers,
  reatoreForImprovment,
} from "actions/PanelDashboardActions/RestoreForImprovmentActions";
import { SmalModal } from "components/Modal/SmalModal";

const RestoreForImprovment = ({
  all_proceedings,
  loading,
  proceeding_users,
  getAllProceedings,
  getProceedingUsers,
  reatoreForImprovment,
  loading_users,
}) => {
  const [selectedProceeding, setSelectedProceeding] = useState(null);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleModalToggler = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    reatoreForImprovment(selectedProceeding.value, selectedUser.user.id);
    handleModalToggler();
    setTimeout(() => getProceedingUsers(selectedProceeding.value), 3000);
    clearTimeout();
  };

  useEffect(() => {
    getAllProceedings();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedProceeding !== null) {
      setSelectedUser(null);
      getProceedingUsers(selectedProceeding.value);
    }
    // eslint-disable-next-line
  }, [selectedProceeding]);

  useEffect(() => {
    if (selectedUserID !== null) {
      setSelectedUser(
        proceeding_users.filter(
          (object) => object.user.id === selectedUserID
        )[0]
      );
    }
  }, [selectedUserID, proceeding_users]);

  const setOptions = (list) => {
    const newlist = list.map((object) => ({
      value: object.id,
      label: `${object.postepowanie_nr} ${object.typ_danych} - ${object.postepowanie_rok}`,
    }));
    return newlist;
  };

  const setUserSelectOptions = (list) => {
    const newlist = list.map((object) => ({
      value: object.user.id,
      label: `${object.user.username}`,
    }));
    return newlist;
  };

  const showStatus = () => {
    if (!selectedUser.verifications_opk_list.length) {
      return "SWD nie wysłał prośby o weryfikację"
    }
    else {
      const lastElement =
      selectedUser.verifications_opk_list[
        selectedUser.verifications_opk_list.length - 1
      ];
      if (lastElement.czy_otwarte === true)
        return "SWD wysłał prośbę o weryfikację";
      else if (lastElement.czy_otwarte === false)
        return `Dane zostały zaakceptowane, zatwierdzono listę OPK (${lastElement.zatwierdzil})`;
      else if (lastElement.czy_otwarte === null) return "Otwarte do edycji";
      else return "Status nieznany";
    }
  }

  return (
    <>
      <div className="head-container">
        <h3>Panel pomocniczy</h3>
        <p>
          Widok służy do przywracania świadczeniodawcy stanu do poprawy, po tym
          jak już raz OPKI zostąły zaakceptowane. Zazwyczaj dzieje się to na
          prośbę świadczeniodawcy. Wpisz regon świadczeniodawcy, wybierz
          postepowanie i kliknij wyślij. Po wysłaniu WSZYSTKIE opki
          świadczeniodawcy zostaną usuniete z listy opk (czyli usuną się
          wszystkie dane Tab2 Tab1 Tab3 Analityka itd...), a OPKI wrócą stanu
          przed akceptacją listy, podobnie jak przy kliknięciu przycisku "do
          poprawy".
        </p>
      </div>
      <main className="main-container">
        <Row className={"mb-4"}>
          <Col sm={12} md={4}>
            {!loading && (
              <>
                <Select
                  className="react-select mt-2"
                  // classNamePrefix="react-select"
                  name="proceeding_select"
                  value={selectedProceeding}
                  onChange={(value) => setSelectedProceeding(value)}
                  options={setOptions(all_proceedings)}
                  placeholder="Wybierz Postępowanie"
                />
                {selectedProceeding && (
                  <>
                    <Select
                      className="react-select  mt-2"
                      classNamePrefix="react-select"
                      name="proceeding_select"
                      value={
                        selectedUser !== null && selectedUser !== undefined && {
                          value: selectedUser.user.id,
                          label: selectedUser.user.username,
                        }
                      }
                      onChange={(value) => setSelectedUserID(value.value)}
                      options={setUserSelectOptions(proceeding_users)}
                      placeholder={loading_users ? "Trwa ładowanie listy, proszę czekać..." : "Wybierz Świadczeniodawcę"}
                      isDisabled={loading_users}
                    />
                    {selectedUser !== null && (
                      <Button
                        color="info"
                        size="sm"
                        type="button"
                        className={"mt-4"}
                        onClick={handleModalToggler}
                      >
                        Przywróć do poprawy{" "}
                        <i className="fa fa-chevron-right" />
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </Col>
          {selectedUser !== null && selectedUser !== undefined && (
            <Col sm={12} md={8}>
              <Card>
                <CardBody>
                  {loading_users ? (
                    <div className={"container-items-centered"}>
                      <Spinner
                        // animation="border"
                        // role="status"
                        color="success"
                      >
                        Dane o Świadczeniodawcy są pobierane z serwera...
                      </Spinner>
                    </div>
                  ) : (
                    selectedUser.user.profile[0] === undefined ? (
                      <>
                        <Row className={"mb-2"}>
                          <Col sm={3}>Nazwa SWD:</Col>
                          <Col sm={9}>Dane SWD nie zostały uzupełnione</Col>
                        </Row>
                        <Row>
                          <Col sm={3}>Status weyfikacji listy opk:</Col>
                          <Col sm={9}>{showStatus()}</Col>
                        </Row>
                      </>
                    ) : (
                      <>
                      <Row className={"mb-2"}>
                        <Col sm={3}>Nazwa SWD:</Col>
                        <Col sm={9}>
                          {selectedUser.user.profile[0].swd_nazwa}
                        </Col>
                      </Row>
                      <Row className={"mb-2"}>
                        <Col sm={3}>Adres:</Col>
                        <Col
                          sm={9}
                        >{`${selectedUser.user.profile[0].swd_ulica} ${selectedUser.user.profile[0].swd_kodpocztowy} ${selectedUser.user.profile[0].swd_miejscowosc}`}</Col>
                      </Row>
                      <Row className={"mb-2"}>
                        <Col sm={3}>Kod SWD:</Col>
                        <Col sm={9}>{selectedUser.user.profile[0].swd_kod}</Col>
                      </Row>
                      <Row>
                        <Col sm={3}>Status weyfikacji listy opk:</Col>
                        <Col sm={9}>{showStatus()}</Col>
                      </Row>
                    </>
                    )     
                  )}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </main>
      <SmalModal
        show={showModal}
        handleClose={handleModalToggler}
        modalBodyText={
          <p className={"text-center"}>
            Czy na pewno chcesz przywrócić do poprawy?
          </p>
        }
        handleAccept={handleAccept}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  all_proceedings: state.participationRegisterReducer.all_proceedings,
  loading: state.participationRegisterReducer.loading,
  proceeding_users: state.restoreForImprovmentReducer.proceeding_users,
  loading_users: state.restoreForImprovmentReducer.loading,
});

export default connect(mapStateToProps, {
  getAllProceedings,
  getProceedingUsers,
  reatoreForImprovment,
})(RestoreForImprovment);

import { GET_LIST_FOR_VERIFICATION, LIST_FOR_VERIFICATION_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  proceedings_for_veryfication: {},
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_FOR_VERIFICATION:
      return {
        ...state,
        proceedings_for_veryfication: action.payload,
        loading: false
      };
    case LIST_FOR_VERIFICATION_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
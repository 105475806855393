import axios from "axios";

import {
  UPLOAD_XLSX,
  UPLOAD_XLSX_FAIL,
  UPLOAD_XLSX_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types.js";

import { configTokenSwitcher } from "../utilActions";

// upload xlsx
export const uploadXlsx =
  (procedureID, xlsxFile) => async (dispatch, getState) => {
    dispatch({ type: UPLOAD_XLSX_SET_LOADING });
    try {
      const response = await axios.post(
        `/fk/api/${procedureID}/upload_excel`,
        xlsxFile,
        configTokenSwitcher(getState)
      );
      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });

      // return data
      dispatch({
        type: UPLOAD_XLSX,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        const errors = {
          msg: err.response.data,
          status: "",
        };
        const tempError = {
          msg: `Błąd wgrywania danych - skontaktuj sie z AOTMiT opd adresem dane-kosztowe@aotm.gov.pl`,
          status: 500,
        };

        dispatch({
          type: GET_ERRORS,
          payload: tempError,
        });
        dispatch({ type: UPLOAD_XLSX_FAIL });
      } else {
        console.log({ err });
      }
    }
  };

import React, { useEffect, useRef } from "react";
// import PropTypes from "prop-types";
import { Route, Switch, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ProceedingFAQ from "./ProceedingFAQ";
import ProceedingMaterials from "./ProceedingMaterials";

const InstructionsMainPage = () => {
  // const [sidebarMini, setSidebarMini] = useState(false);
  const location = useLocation();

  const mainPanel = useRef();
  const sidebar = useRef();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);

  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  const checkLocationState = (url) => {
    return location.pathname.includes(url);
  };

  return (
    <div className="wrapper">
      <div className="sidebar">
        <div className="sidebar-wrapper" ref={sidebar}>
          <ul className="nav">
            <li className={checkLocationState("materialy") ? "active" : ""}>
              <NavLink to={"/instrukcja/materialy"}>
                <>
                  {/* <span className="sidebar-mini-icon">{"B"}</span> */}
                  <span className="sidebar-normal">Materiały</span>
                </>
              </NavLink>
            </li>
            <li className={checkLocationState("FAQ") ? "active" : ""}>
              <NavLink to={"/instrukcja/FAQ"}>
                <>
                  {/* <span className="sidebar-mini-icon">{"B"}</span> */}
                  <span className="sidebar-normal">
                    Najcześciej zadawane pytania
                  </span>
                </>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="main-panel" ref={mainPanel}>
        <Switch>
          <Route
            exact
            path="/instrukcja/materialy*"
            component={ProceedingMaterials}
          />
          <Route exact path="/instrukcja/faq*" component={ProceedingFAQ} />
        </Switch>
      </div>
    </div>
  );
};

InstructionsMainPage.propTypes = {};

export default InstructionsMainPage;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// actions
import { getControllerData } from "../../actions/opkViewsControllerActions";
import { getFKFormFields } from "actions/opkListActions";

// reactstrap components
import { Button } from "reactstrap";

// FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Components
import { Loading } from "../../components/Loading/loading";
import CardInfo from "../../components/Cards/CardInfo";

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */

const OpkViewController = ({
  controllerData,
  loading,
  isSwitchedUser,
  form_fields,
  form_fields_loading,
  match,
  getControllerData,
  getFKFormFields,
}) => {
  const { procedureID } = match.params;

  useEffect(() => {
    getControllerData(procedureID);
    getFKFormFields(procedureID);
    // eslint-disable-next-line
  }, [procedureID]);

  const declaration_require_card_info = (
    <CardInfo
      imageHeader={require("../../assets/img/zdjecie_05.jpg")}
      aotmitLogo
      bodyTitle={`Dziękujemy za wypełnienie listy OPK.`}
      description={`Uprzejmie prosimy o odesłanie oświadczenia podmiotu. Po otrzymaniu oświadczenia będą Państwo mogli przekazać dane.`}
      footerActions={
        <>
          <Button
            className="btn-round"
            color="success"
            outline
            href="/"
            size="sm"
          >
            Strona główna
          </Button>
        </>
      }
    />
  );
  const opk_list_send_for_acceptance_card_info = <CardInfo
            imageHeader={require("../../assets/img/zdjecie_05.jpg")}
            aotmitLogo
            bodyTitle={`
            Lista OPK została przesłana do Agencji z prośbą o weryfikację.
          `}
            description={`
            Po zakończeniu weryfikacji zostanie wysłana do Państwa wiadomość z 
            prośba o ponowne zalogowanie się na stronie.
          `}
            footerActions={
              <>
                <Button
                  className="btn-round"
                  color="success"
                  outline
                  href="/"
                  size="sm"
                >
                  Strona główna
                </Button>
              </>
            }
          />


  const content_controller = (controllerData, form_fields, loading) => {
    if (controllerData.opk_list_veryfication_status === true) {
      if (
        form_fields.declaration_require === true &&
        controllerData.get_statement === false
      ) {
        return declaration_require_card_info;
      } else {
        return opk_list_send_for_acceptance_card_info;
      }
    } else if (controllerData.opk_list_veryfication_status === false) {
      if (
        form_fields.declaration_require === true &&
        controllerData.get_statement === false
      ) {
        return declaration_require_card_info;
      } else {
        return <Redirect to={`/fk/${procedureID}/opk`} />;
      }
    } else if (controllerData.opk_list_veryfication_status === null) {
      return <Redirect to={`/fk/${procedureID}/opk_swd`} />;
    }
  };

  if (loading) {
    return (
      <Loading variant={"success"}>
        <span className="m-2">Dane są pobierane z serwera...</span>
      </Loading>
    );
  } else {
    if (isSwitchedUser === true) {
      return <>{content_controller(controllerData, form_fields, loading)}</>;
    } else if (isSwitchedUser === false) {
      if (controllerData.verification_status === true) {
        return (
          <>
            <CardInfo
              imageHeader={require("../../assets/img/zdjecie_05.jpg")}
              aotmitLogo
              bodyTitle={`
                Dane postępowania ${controllerData.proceeding.postepowanie_nr} 
                zostały przesłane do weryfikacji
                `}
              description={`
                Czasowo możliwość przeglądania strony została wyłączona, zostaną Poństwo poinformowani 
                drogą mailową o zmianie statusu weryfikacji.
                `}
              footerActions={
                <>
                  <Button
                    className="btn-round"
                    color="success"
                    outline
                    href="/"
                    size="sm"
                  >
                    Strona główna
                  </Button>
                </>
              }
            />
          </>
        );
      } else if (controllerData.verification_status === false) {
        return (
          <CardInfo
            imageHeader={require("../../assets/img/zdjecie_05.jpg")}
            aotmitLogo
            bodyTitle={`
              Dane postępowania ${controllerData.proceeding.postepowanie_nr} zostały zatwierdzone
            `}
            description={`
              Nie mają Państwo uprawnień do przeglądania tej strony, możliwość edycji została wyłączona.
            `}
            footerInfo={
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="2x"
                className={"text-success"}
              />
            }
            footerActions={
              <>
                <Button
                  className="btn-round"
                  color="success"
                  outline
                  href="/"
                  size="sm"
                >
                  Strona główna
                </Button>
              </>
            }
          />
        );
      } else if (controllerData.verification_status === null) {
        return <>{content_controller(controllerData, form_fields, loading)}</>;
      }
    }
  }
};

const mapStateToProps = (state) => ({
  controllerData: state.opkViewControllerReducer.controllerData,
  loading: state.opkViewControllerReducer.loading,
  isAuthenticated: state.authReducer.isAuthenticated,
  isSwitchedUser: state.switchUserReducer.isSwitchedUser,
  form_fields: state.opkFormFieldsReducer.form_fields,
  form_fields_loading: state.opkFormFieldsReducer.loading,
});

export default connect(mapStateToProps, { getControllerData, getFKFormFields })(
  OpkViewController
);

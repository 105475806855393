import { GET_OPK_FORM_DATA, SAVE_OPK_FORM_DATA, OPK_FORM_DATA_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  opkData: [],
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPK_FORM_DATA:
      return {
        ...state,
        opkData: action.payload,
        loading: false
      };
    case SAVE_OPK_FORM_DATA:
      return {
        ...state,
        opkData: action.payload,
        loading: false
      };
    case OPK_FORM_DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
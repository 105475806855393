import { GET_OPKS_ACCEPTANCE, ADD_OPKS_ACCEPTANCE, CONFIRM_OPKS, OPKS_ACCEPTANCE_SET_LOADING } from '../../../actions/types.js'

const initialState = {
  opks: [],
  opk_accepted: false,
  error: null,
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPKS_ACCEPTANCE:
      return {
        ...state,
        opks: action.payload,
        loading: false
      };
    case ADD_OPKS_ACCEPTANCE:
      return {
        ...state,
        opks: action.payload,
        loading: false
      };
    case CONFIRM_OPKS:
      let opk_accepted_status = false
      if (action.payload === 200) {
        opk_accepted_status = true
      }
      return {
        ...state,
        opk_accepted: opk_accepted_status,
        loading: false
      };
    case OPKS_ACCEPTANCE_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state
  }
}
import {
  GET_PARTICIPATION_USERS,
  GET_PARTICIPATION_USERS_SET_LOADING,
} from "actions/types.js";

const initialState = {
  proceeding_users: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTICIPATION_USERS:
      return {
        ...state,
        proceeding_users: action.payload,
        loading: false,
      };
    case GET_PARTICIPATION_USERS_SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Loading } from "../Loading/loading";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  loading,
  location,
  ...rest
}) => {
  // const pathname = location.pathname
 if (isAuthenticated === true) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else if (loading) {
    return (
      <Loading variant={"success"}>
        <span className="m-2">Dane są pobierane z serwera...</span>
      </Loading>
    );
  }
  return (
    <Redirect
      to={{
        pathname: "/registration/login",
        state: { from: location.pathname },
      }}
    />
  );

};
const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  loading: state.authReducer.loading,
});

export default connect(mapStateToProps)(PrivateRoute);

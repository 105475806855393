import axios from "axios";

import {
  GET_SD_FORM_FIELDS,
  GET_SD_FILES,
  // GET_SD_RAPORT_SET_LOADING,
  // GET_SD_RAPORT,
  UPLOAD_SD_FIELDS_SET_LOADING,
  UPLOAD_SD_FILES,
  UPLOAD_SD_FILES_FAIL,
  UPLOAD_SD_FILES_SET_LOADING,
  GET_SUCCESS,
  GET_ERRORS,
} from "../types.js";

import {configTokenSwitcher, setRequestStatus} from "../utilActions";
import {saveAs} from "file-saver";
import {getReportRequest, postSpecificDataRequest, SET_LOADING_REQUEST_STATUS, SWITCH_USER_LOADED} from "../types";

// get personalized excel
export const getSpecificDataFormFields =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPLOAD_SD_FIELDS_SET_LOADING });

      const response = await axios.get(
        `/daneszcz/api/${procedureID}/get_fields`,
        configTokenSwitcher(getState)
      );
      dispatch({
        type: GET_SD_FORM_FIELDS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        console.log("SD_Error", err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ err });
      }
    }
  };

// get specyfic data xlsx
export const getSecyficDataFiles =
  (procedureID) => async (dispatch, getState) => {
    dispatch({ type: UPLOAD_SD_FILES_SET_LOADING });
    try {
      const response = await axios.get(
        `/daneszcz/api/${procedureID}/upload_specific_data`,
        configTokenSwitcher(getState)
      );

      // return data
      dispatch({
        type: GET_SD_FILES,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        const errors = {
          msg: err.response.statusText,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
        dispatch({
          type: UPLOAD_SD_FILES_FAIL,
        });
      } else {
        console.log({ err });
      }
    }
  };

  export const updateSpecyficDataFiles =
  (procedureID) => async (dispatch, getState) => {
    try {
      const response = await axios.get(
        `/daneszcz/api/${procedureID}/upload_specific_data`,
        configTokenSwitcher(getState)
      );

      if (!response.data.results?.some((file) => file.czy_raport === "Oczekuje")) {
        dispatch({
          type: GET_SD_FILES,
          payload: response.data,
        });
      }
    } catch (err) {
      if (err.response) {
        const errors = {
          msg: err.response.statusText,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
        dispatch({
          type: UPLOAD_SD_FILES_FAIL,
        });
      } else {
        console.log({ err });
      }
    }
  };

// upload specyfic data xlsx
export const uploadSecyficDataFiles =
  (procedureID, specyficDataFiles) => async (dispatch, getState) => {
    dispatch({ type: UPLOAD_SD_FILES_SET_LOADING });
    dispatch(setRequestStatus(0, 100,postSpecificDataRequest));
   const config = configTokenSwitcher(getState);
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onUploadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.total;
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.10 * fileSize) {
        dispatch(setRequestStatus(progress, 100,postSpecificDataRequest));
        partialSize = partialSize - diffrence
      } else {
        partialSize = partialSize + diffrence
      }
    };
    try {
      const response = await axios.post(
        `/daneszcz/api/${procedureID}/upload_specific_data`,
        specyficDataFiles,
        config,
      );
      // set alert success
      dispatch({
        type: GET_SUCCESS,
        payload: {
          msg: "Dane zostały poprawnie zapisane",
          status: response.status,
        },
      });
      dispatch(setRequestStatus(null, null,postSpecificDataRequest));
      // return data
      dispatch({
        type: UPLOAD_SD_FILES,
        payload: response.data,
      });
    } catch (err) {
      dispatch(setRequestStatus(null, null,postSpecificDataRequest));
      if (err.response) {
        console.log(err.response.data);
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };

        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
        dispatch({
          type: UPLOAD_SD_FILES_FAIL,
        });
      } else {
        console.log({ err });
      }
    }
  };

export const getReport = (id,path,procedureID) => async (dispatch,getState) => {
  try {
    dispatch(setRequestStatus(0, 100,getReportRequest));
    const config = configTokenSwitcher(getState);
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onDownloadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.srcElement.getResponseHeader('file-size');
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.10 * fileSize) {
        dispatch(setRequestStatus(progress, 100,getReportRequest));
        partialSize = partialSize - diffrence
      } else {
        partialSize = partialSize + diffrence
      }
    };
    const response = await axios.get(
        `/daneszcz/api/${procedureID}/report?report_id=${id}&path=${path}`, config
    );
    // Request is finished
    dispatch(setRequestStatus(null, null,null));
    const byte_chars = [...atob(response.data)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "raport.xlsx");
  } catch {
    dispatch(setRequestStatus(null, null,null));
  }
}
//   };
//
//   const response = await axios.post(`/daneszcz/${procedureID}/wynik`, {"report_id":id,"path":path},configTokenSwitcher(getState));
//   let blob = new Blob([response.data], {
//     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   });
//   saveAs(blob, "Szablon do nowych kont.xlsx");
// // };

import React from "react";
// import PropTypes from 'prop-types';
import "./footer.scss";

const Footer = (props) => {
  return (
    <footer className="py-2 bg-secondary text-white text-center custom-footer">
      Copyright &copy;
      <span className="year"> 2020</span> Agencja Oceny Technologii Medycznych i
      Taryfikacji
    </footer>
  );
};

Footer.propTypes = {};

export { Footer };

import {
  GET_SD_FORM_FIELDS,
  GET_SD_FILES,
  GET_SD_RAPORT_SET_LOADING,
  GET_SD_RAPORT,
  UPLOAD_SD_FILES,
  UPLOAD_SD_FILES_FAIL,
  UPLOAD_SD_FILES_SET_LOADING,
} from "../../actions/types.js";

const initialState = {
  specyficDataFormFields: {},
  specyficDataFiles: [],
  specyficDataUploadedFiles: [],
  specyficDataloading: false,
  getRaportloading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SD_FORM_FIELDS:
      return {
        ...state,
        specyficDataFormFields: action.payload,
        specyficDataloading: false,
      };
    case GET_SD_FILES:
      return {
        ...state,
        specyficDataFiles: action.payload,
        specyficDataloading: false,
      };
    case GET_SD_RAPORT_SET_LOADING:
      return {
        ...state,
        getRaportloading: true,
      };
    case GET_SD_RAPORT:
      return {
        ...state,
        getRaportloading: false,
      };
    case UPLOAD_SD_FILES:
      return {
        ...state,
        specyficDataFiles: action.payload,
        specyficDataloading: false,
      };
    case UPLOAD_SD_FILES_FAIL:
      return {
        ...state,
        specyficDataloading: false,
      };
    case UPLOAD_SD_FILES_SET_LOADING:
      return {
        ...state,
        specyficDataloading: true,
      };
    default:
      return state;
  }
};

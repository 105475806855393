import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

// React Bootstrap
import Button from "react-bootstrap/Button";

const OpkListComponent = ({ opklist, opkID, procedureID }) => {

  const notMedicalOpk = (opklist) => {
    if (opklist.filter(opk => opk.opk_niemedyczny === true).length) {
      return (
        <>
          <div className={'border-box'}>
            <div className={'border-box-title'}>OPK niepełne dane FK (Tab4)</div>
            <div className={'border-box-content'}>
              {opklist.filter(opk => opk.opk_niemedyczny === true).map((opk) => (
                <Link to={`/fk/${procedureID}/opk/${opk.id}/tab4form`} key={opk.id}>
                  <Button variant={`${opk.error_in_opk ? 'outline-danger' : 'outline-info'}  ${(opk.id === parseInt(opkID)) && 'active'}`} size='sm' className="m-1 btn-bg-white">
                    {`${opk.nr_konta}`}
                  </Button>
                </Link>
              ))}
            </div>
          </div>
        </>)
    }
  }
  return (
    <>
      <div className={'border-box'}>
        <div className={'border-box-title'}>OPK medyczne (pełne dane FK)</div>
        <div className={'border-box-content'}>
          {opklist.filter(opk => opk.opk_niemedyczny === false).map((opk) => (
            <Link to={`/fk/${procedureID}/opk/${opk.id}/opkform`} key={opk.id}>
              <Button variant={`${opk.error_in_opk ? 'outline-danger' : 'outline-info'} ${(opk.id === parseInt(opkID)) && 'active'}`} size='sm' className="m-1 btn-bg-white">
                {`${opk.nr_konta}`}
              </Button>
            </Link>
          ))}
        </div>
      </div>

      {notMedicalOpk(opklist)}

    </>
  )
}

OpkListComponent.propTypes = {
  opklist: PropTypes.array.isRequired,
}

export default OpkListComponent

import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, CardTitle } from "reactstrap";

const CardInfo = ({
  imageHeader,
  aotmitLogo,
  headerClass,
  header,
  bodyTitle,
  description,
  footerInfo,
  footerActions,
}) => {
  return (
    <div>
      <>
        <Card className={"card-info"}>
          {/* Image header */}
          {imageHeader && (
            <>
              <div
                className="card-cover"
                style={{
                  backgroundImage: `url(${imageHeader})`,
                }}
              />
            </>
          )}

          {/* Logo */}
          {aotmitLogo && (
            <div className="card-avatar border-white">
              <img alt="..." src={require("../../assets/img/aotm.png")} />
            </div>
          )}

          {/* Header */}
          {header && (
            <CardHeader className={`text-center ${headerClass}`}>
              <>{header}</>
            </CardHeader>
          )}

          <CardBody>
            {/* Title */}
            {bodyTitle && (
              <CardTitle tag="h5" className={"text-center"}>
                {bodyTitle}{" "}
              </CardTitle>
            )}
            {/* Description */}
            {description && (
              <div className="card-description text-justify">{description}</div>
            )}

            {(footerInfo || footerActions) && (
              <>
                <hr />
                <CardFooter>
                  {/* footer info */}
                  {footerInfo && (
                    <div className="footer-info">{footerInfo}</div>
                  )}
                  {/* footer actions */}
                  <div className="actions">
                    {footerActions && footerActions}
                  </div>
                </CardFooter>
              </>
            )}
          </CardBody>
        </Card>
      </>
    </div>
  );
};

export default CardInfo;

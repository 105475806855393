import React from "react";
// reactstrap components
import { Card, CardHeader, CardBody } from "reactstrap";

const SlagSelectedQuestions = ({ loading, faq, match }) => {
  const { slug } = match.params;

  return (
    <>
      {!loading &&
        faq
          .filter((el) => {
            return el.kategoria.some((el) => el.slug === slug);
          })
          .map((faq_element, faq_idx) => {
            return (
              <Card key={faq_idx}>
                <CardHeader>{faq_element.pytanie}</CardHeader>
                <CardBody>{faq_element.odpowiedz}</CardBody>
              </Card>
            );
          })}
    </>
  );
};

export default SlagSelectedQuestions;

import {
    GET_ALL_PROCEEDINGS,
    GET_ALL_PROCEEDINGS_SET_LOADING,
    GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
    POST_EXCEL_FILE_WITH_NEW_USERS,
    POST_EXCEL_SET_LOADING
} from "actions/types.js";

const initialState = {
    all_proceedings: [],
    loading: false,
    excel_file_list: [],
    loading_post_excel:false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_PROCEEDINGS:
            return {
                ...state,
                all_proceedings: action.payload,
                loading: false,
            };
        case GET_ALL_PROCEEDINGS_SET_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_EXCEL_FILES_WITH_NEW_USERS:
            return {
                ...state,
                loading: false,
                excel_file_list: action.payload
            };
        case POST_EXCEL_FILE_WITH_NEW_USERS:
            return {
                ...state,
                loading_post_excel: false,
                excel_file_list: [...state.excel_file_list,action.payload]
            };
        case POST_EXCEL_SET_LOADING:
            return {
                ...state,
                loading_post_excel: true,
            };
        default:
            return state;
    }
};

import axios from "axios";

import {
  GET_ALL_PROCEEDINGS,
  GET_ALL_PROCEEDINGS_SET_LOADING,
  GET_ERRORS,
  GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
  POST_EXCEL_FILE_WITH_NEW_USERS,
  // LOADING_POST_EXCEL,
  POST_EXCEL_SET_LOADING,
  // GET_NEW_USERS_SUMMARY
} from "actions/types";

import {authTokenConfig, configTokenSwitcher, setRequestStatus} from "../utilActions";
import { saveAs } from "file-saver";
import {getNewUsersSummaryRequest, getReportRequest} from "../types";

// get Proceedings
export const getAllProceedings = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ALL_PROCEEDINGS_SET_LOADING,
    });

    const response = await axios.get(
      `/api/get_proceedings`,
      authTokenConfig(getState)
    );

    dispatch({
      type: GET_ALL_PROCEEDINGS,
      payload: response.data,
    });
  } catch (err) {
    if (err.response.data) {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
    } else {
      console.log({ authErr: err });
    }
  }
};

export const getAllExcelFilesWithNewUsers =
  (procedureID) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_PROCEEDINGS_SET_LOADING,
      });

      const response = await axios.get(
        `/api/new_accounts/?postepowanie_id=${procedureID}`,
        authTokenConfig(getState)
      );

      dispatch({
        type: GET_ALL_EXCEL_FILES_WITH_NEW_USERS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.data) {
        const errors = {
          msg: err.response.data,
          status: err.response.status,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      } else {
        console.log({ authErr: err });
      }
    }
  };

export const postExeclFileWithNewUsers =
  (xlsxFile, procedureID) => async (dispatch, getState) => {
    dispatch({ type: POST_EXCEL_SET_LOADING });
    // const json = JSON.stringify(xlsxFile);
    // const fileBlob = new Blob([json], {
    //   type: "application/json",
    // });
    const data = new FormData();
    data.append("file", xlsxFile);
    data.append("postepowanie_id", procedureID);
    try {
      const response = await axios.post(
        `/api/new_accounts/`,
        data,
        configTokenSwitcher(getState)
      );
      // return data
      dispatch({
        type: POST_EXCEL_FILE_WITH_NEW_USERS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response.hasOwnProperty("data"))
        console.log(err.response, err.response.data);
      else console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: err.response,
      });
    }
  };

export const getExcelsSummary = (procedureID) => async (dispatch, getState) => {
  try {
    dispatch(setRequestStatus(0, 100, getNewUsersSummaryRequest));
    const config = configTokenSwitcher(getState);
    let partialSize = 0;
    let diffrence = 0;
    // Funkcja przy każdym pobranym kawałku raportu sprawdza jaki jest on duży, jeżeli jest większy niż 10% aktualizuje stan.
    config["onDownloadProgress"] = (progressEvent) => {
      const fileSize = progressEvent.srcElement.getResponseHeader('file-size');
      const loaded = progressEvent.loaded;
      diffrence = loaded - partialSize;
      let progress = Math.round((loaded / fileSize) * 100);
      if (partialSize >= 0.10 * fileSize) {
        dispatch(setRequestStatus(progress, 100,getNewUsersSummaryRequest));
        partialSize = partialSize - diffrence
      } else {
        partialSize = partialSize + diffrence
      }
    };
    const response = await axios.get(
      `/api/create_summary/?postepowanie_id=${procedureID}`,
      authTokenConfig(getState)
    );
    dispatch(setRequestStatus(null, null,null));
    const byte_chars = [...atob(response.data)];
    const byte_numbers = byte_chars.map((ele, i) => ele.charCodeAt(0));
    const byte_array = new Uint8Array(byte_numbers);
    let blob = new Blob([byte_array], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "ListaNowychKont.xlsx");
  } catch (err) {
    dispatch(setRequestStatus(null, null,null));
    console.log(err);
  }
};

export const getExcelsTemplate = () => async (dispatch) => {
  const config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  };
  const response = await axios.get(`/api/new_accounts_template/`, config);
  let blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "Szablon do nowych kont.xlsx");
};

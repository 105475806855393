import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import { Button } from "reactstrap";

// actions
import { confirmSDData } from "actions/SpecificDataActions/confirmSDDataActions";

// components
import { SmalModal } from "components/Modal/SmalModal";

const SDSidebarButtons = ({
  confirmSpecyficDataStatus,
  procedureID,
  confirmSDData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (confirmSpecyficDataStatus === 200) {
      const redirect = setTimeout(() => {
        history.push(`/daneszcz/${procedureID}`);
      }, 3000);

      return () => clearTimeout(redirect);
    }
    // eslint-disable-next-line
  }, [confirmSpecyficDataStatus]);

  const handleModalShow = () => setShowModal((prevState) => !prevState);
  const handleAccept = () => {
    handleModalShow();
    confirmSDData(procedureID);
  };

  return (
    <>
      <div className={"dropdownMenu"}>
        <Link to={`/fk/user_profile`}>
          <Button
            size="sm"
            block
            outline
            type="button"
            className={"btn-aotmit"}
          >
            Dane ogólne
          </Button>
        </Link>

        <Button
          size="sm"
          block
          outline
          type="button"
          className={"btn-aotmit"}
          onClick={() => console.log("Weryfikacja techniczna")}
        >
          Weryfikacja techniczna
        </Button>

        <Button
          // disabled={
          //   switchedUserSDInfo.verifications &&
          //   switchedUserSDInfo.verifications.czy_otwarte === true &&
          //   true
          // }
          size="sm"
          outline
          color="success"
          block
          type="button"
          className={"mt-2"}
          onClick={() => handleModalShow()}
        >
          Prześlij do AOTMiT
        </Button>
      </div>
      <SmalModal
        show={showModal}
        handleClose={handleModalShow}
        modalBodyText={
          <p className={"text-center"}>
            Czy na pewno chcesz przesłać dane do AOTMiT?
          </p>
        }
        handleAccept={handleAccept}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  confirmSpecyficDataStatus:
    state.specificDataConfirmDataReducer.confirmSpecyficDataStatus,
  confirmSpecyficDataloading:
    state.specificDataConfirmDataReducer.confirmSpecyficDataloading,

  // switchedUserInfo: state.switchedUserInfoReducer.switchedUserInfo,
});

export default connect(mapStateToProps, { confirmSDData })(SDSidebarButtons);

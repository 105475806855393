import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";

// reactstrap components
import { Row, Col } from "reactstrap";

import { getUserInfo } from "../../actions/OpkUserProfileActions";

const UserInfo = ({
  user,
  switchedUser,
  user_info_loading,
  user_info,
  getUserInfo,
}) => {
  const [ShowUserinfo, setShowUserinfo] = useState(false);
  const location = useLocation();

  const options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: '2-digit',
    minute: '2-digit',

  };

  let displayUser = user;

  if (switchedUser) {
    displayUser = switchedUser;
  }

  useEffect(() => {
    if (switchedUser) {
      getUserInfo(switchedUser.id);
      setShowUserinfo(true);
    } else if (location.pathname.includes("opk_akceptacja")) {
      const match = matchPath(location.pathname, {
        path: "/fk/:procedureID/:userID/opk_akceptacja",
        exact: true,
        strict: false,
      });
      if (match) {
        getUserInfo(match.params.userID);
      }
      setShowUserinfo(true);
    } else {
      setShowUserinfo(false);
    }
    // eslint-disable-next-line
  }, [switchedUser, location.pathname]);

  const getSafeUserProfile = () => {
    try {
      return user_info.profile[0].swd_nazwa;
    } catch {
      return "nie dodano nazwy swd";
    }
  };

  return (
    <div className={"user-info"}>
      {displayUser ? (
        <>
          <div className={"user-info-main"}>
            Witaj w aplikacji {""}
            <p>
              <strong>Dane kosztowe wersja 2.0</strong>
            </p>
            ,
            <br /> jesteś zalogowany(a) jako użytkownik {displayUser.username}
          </div>
          {ShowUserinfo && (
            <>
              <Row>
                <Col lg={6} className={"pr-1"}>
                  <p className={"user-info-sub"}>{getSafeUserProfile()}</p>
                </Col>
                <Col lg={3} className={"px-1"}>
                  <p className={"user-info-sub"}>
                    {`e-mail świadczeniodawcy: ${user_info.email}`}
                  </p>
                </Col>
                <Col lg={3} className={"px-1"}>
                  <p className={"user-info-sub"}>
                    {`ostatnie logowanie: 
                    ${new Date(user_info.last_login).toLocaleString(
                      "pl-PL",
                      options
                    )} `}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  switchedUser: state.switchUserReducer.switchedUser,
  user_info_loading: state.userInfoReducer.loading,
  user_info: state.userInfoReducer.user_info,
});

export default connect(mapStateToProps, { getUserInfo })(UserInfo);
